import React, { useState, useEffect } from 'react';
import Sidebar from "../../layouts/Sidebar";
import Header from "../../layouts/Header";
import { Container, Row, Col, Card, Form, Button, Table, Pagination } from 'react-bootstrap';
import useSkinSwitcher from '../../common/skin';
import RoleService from "../../service/api/role.service";
// import SocketService from "../../service/api/socket.service";
import { toast } from 'react-toastify';
import { Link, useNavigate, useLocation } from "react-router-dom";
import { BorderSpinner } from '../../common/Spinner';

const ViewNewRole = () => {
  const roleService = RoleService();
  // const socketService = SocketService();
  const navigate = useNavigate()
  const location = useLocation();
  const { skin, setSkin } = useSkinSwitcher();
  const { id } = location.state;
  const [isLoading, setIsLoading] = useState(true);
  const  modules = [
    "Role And Permission",
    "Staffs",
    "Users",
    "Games",
    "Merchants",
    "Transactions",
    "Game Record",
    "Game Settings",
    "Dashboard",
    "Banners",
    "Sports Management",
    "Sports Bet",
    // "Referral",
    "Games Category",
    // "Static Pages",
    "Currency",
    "Lottery",
    "Platform Settings",
    "IP Whitelisting",
    "Exposure Management",
    "Theme Management",//19
    "Affiliate Management", //20
];
// const  resource = 

  const [roleForm, setRoleForm] = useState({
    name: '',
  });
  const [showError, setShowError] = useState(false);
  const [rolesd, setRole] = useState({});
  const [loading, setLoading] = useState(false);
  const [nameError, setNameError] = useState('');
  const [resource, setResource] = useState([]); // Initialize resource state with your data

  useEffect(() =>{
    getRole()
    
  }, [])

  // useEffect(() => {
  //   console.log("idddd", rolesd)
  //   if(Object.keys(rolesd).length>0) {
  //     setRoleForm({name: rolesd.name})
  //     let updatedresource = modules.map((moduleName, moduleId) => ({
  //       moduleName,
  //       moduleId: (moduleId + 1).toString(),
  //       selectedAll: false,
  //       list: false,
  //       add: false,
  //       edit: false,
  //       view: false,
  //       delete: false,
  //     }));
  //     const updatedResource = updatedresource.map((module) => {
  //       const foundModule = rolesd.resource.find(({ moduleName }) => moduleName === module.moduleName);
  //       if (foundModule) {
  //           const { moduleName, moduleId, permissions } = foundModule;
  //           return {
  //               moduleName,
  //               moduleId:module.moduleId,
  //               selectedAll: permissions.includes('selectedAll'),
  //               list: permissions.includes('list'),
  //               add: permissions.includes('add'),
  //               edit: permissions.includes('edit'),
  //               view: permissions.includes('view'),
  //               delete: permissions.includes('delete'),
  //           };
  //       } else {
  //           return {
  //               moduleName: module.moduleName,
  //               moduleId: module.moduleId, // You might want to set some default value here
  //               selectedAll: false,
  //               list: false,
  //               add: false,
  //               edit: false,
  //               view: false,
  //               delete: false,
  //           };
  //       }
  //   });
  //     setResource(updatedResource);
  //   }
  // }, [rolesd])

  useEffect(() => {
      console.log("idddd", rolesd)
      if (Object.keys(rolesd).length > 0) {
        setRoleForm({ name: rolesd.name })
        let updatedresource = modules.map((moduleName, moduleId) => {
          // Define available actions for each module
          let availableActions = {
            list: true,  // Most modules have list capability
            add: true,   // Most modules have add capability
            edit: true,  // Most modules have edit capability
            view: true,  // Most modules have view capability
            delete: true // Most modules have delete capability
          };
  
          // Configure module-specific action availability
          switch (moduleName) {
            case "Dashboard":
              // Example: Dashboard might only support view, not add/edit/delete
              availableActions = { list: false, add: false, edit: false, view: true, delete: false };
              break;
            case "Games Category":
              // Example: Settings might only support list, view and edit
              availableActions = { list: true, add: true, edit: true, view: false, delete: false };
              break;
            case "Transactions":
              // Example: Settings might only support list, view and edit
              availableActions = { list: true, add: false, edit: false, view: false, delete: false };
              break;
            case "Game Record":
              // Example: Settings might only support list, view and edit
              availableActions = { list: true, add: false, edit: false, view: false, delete: false };
              break;
            case "Banners":
              // Example: Settings might only support list, view and edit
              availableActions = { list: true, add: false, edit: true, view: true, delete: false };
              break;
            case "Sports Management":
              // Example: Settings might only support list, view and edit
              availableActions = { list: true, add: false, edit: true, view: false, delete: false };
              break;
            case "Sports Bet":
              // Example: Settings might only support list, view and edit
              availableActions = { list: true, add: false, edit: false, view: false, delete: false };
              break;
              case "Affiliate Management":
                // Example: Settings might only support list, view and edit
                availableActions = { list: true, add: false, edit: true, view: true, delete: true };
                break;
            // Add cases for other modules with specific action limitations
  
            default:
              // Keep default (all actions available)
              break;
          }
  
          return {
            moduleName,
            moduleId: (moduleId + 1).toString(),
            selectedAll: false,
            list: false,
            add: false,
            edit: false,
            view: false,
            delete: false,
            availableActions: availableActions
          };
        });
  
        const updatedResource = updatedresource.map((module) => {
          const foundModule = rolesd.resource.find(({ moduleName }) => moduleName === module.moduleName);
          console.log("updatedresource", foundModule)
          if (foundModule) {
            const { moduleName, moduleId, permissions } = foundModule;
            // Keep track of module's original available actions
            return {
              moduleName,
              moduleId: module.moduleId,
              selectedAll: permissions.includes('selectedAll'),
              list: permissions.includes('list'),
              add: permissions.includes('add'),
              edit: permissions.includes('edit'),
              view: permissions.includes('view'),
              delete: permissions.includes('delete'),
              availableActions: module.availableActions
            };
          } else {
            return {
              moduleName: module.moduleName,
              moduleId: module.moduleId,
              selectedAll: false,
              list: false,
              add: false,
              edit: false,
              view: false,
              delete: false,
              availableActions: module.availableActions
            };
          }
        });
        setResource(updatedResource);
      }
    }, [rolesd])

  const getRole = async () => {
    try {
      const res = await roleService.getRoleById(id)
      console.log("idddd", res)
      if(res?.code === 200) {
        setRole(res.data)
      
  
      }
      else {
        throw new Error(res.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }

  }


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const regex = /^[A-Za-z\s]+$/;
    if (regex.test(value) || value === '') {
      setRoleForm({ ...roleForm, [name]: value });
      setNameError('');
    } else {
      setNameError('Role must contain only alphabetic characters and spaces.');
    }
  };

  const handleCheckboxChange = (index, field) => {
    const updatedResource = [...resource];
    console.log("updatedResource", updatedResource)
    updatedResource[index][field] = !updatedResource[index][field];
    if(updatedResource[index].list && updatedResource[index].add && updatedResource[index].edit && updatedResource[index].view) {
      updatedResource[index].selectedAll = true
    }else {
      updatedResource[index].selectedAll = false
    }
    setResource(updatedResource);
  };

  const handleSelectAll = (index) => {
    const updatedResource = [...resource];
    const selectedAll = updatedResource[index].selectedAll;
    updatedResource[index].selectedAll = !selectedAll;
    updatedResource[index].list = !selectedAll;
    updatedResource[index].add = !selectedAll;
    updatedResource[index].edit = !selectedAll;
    updatedResource[index].view = !selectedAll;
    updatedResource[index].delete = !selectedAll;
    setResource(updatedResource);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Handle form submission
    const regex = /^[A-Za-z\s]+$/;
    if (regex.test(roleForm.name) || roleForm.name !== '') {
      setNameError('');
    } else if(roleForm.name === ''){
      setShowError(true)
      setNameError('Role Name is required.');
      return
    } else{
      setShowError(true)
      setNameError('Role must contain only alphabetic characters and spaces.');
      return
    }
    const updatedResource = resource.map(permission => {
      const { moduleName, moduleId, ...rest } = permission;
      const allowedPermissions = Object.entries(rest)
        .filter(([key, value]) => value)
        .map(([key]) => key);
    
      return {
        moduleName,
        moduleId,
        permissions: allowedPermissions
      };
    });
    const res = await roleService.updateRole(id ,{...roleForm, resource: [...updatedResource]});
    console.log("resss===", res)
    if(res?.code == 200) {
      toast.success(res?.message)
      // socketService.roleUpdate({roleId: id})
      navigate('/role-management')
    }else {
      toast.error(res?.message)
    }
  };

  return (
    <React.Fragment>
    <Header onSkin={setSkin} />
    <Sidebar  />
    <div className="main main-app p-3 p-lg-4">
      <div className=" align-items-center mb-4">
    <Container className="mt-4">
      <Row>
        <Col md={12}>
          <Card>
            <Card.Header>
              <h4 className="card-title w-100">View Role</h4>
            </Card.Header>
            <Card.Body>
              {isLoading? <>
                      <div className="d-flex mt-5 justify-content-center align-content-center">

        <BorderSpinner />
      </div>
                    </>:(<Form onSubmit={handleSubmit}>
                <Form.Group as={Row} controlId="name">
                  <Form.Label column sm={2}>Role</Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      name="name"
                      value={roleForm.name}
                      onChange={handleInputChange}
                      placeholder="Enter Name"
                      disabled
                    />
                     <Form.Control.Feedback type="invalid">
                        {nameError}
                      </Form.Control.Feedback>
                      <Form.Text className="text-danger small">
                        {roleForm.name === '' && showError && 'Role is required'}
                      </Form.Text>
                  </Col>
                </Form.Group>
                <div className="table-responsive border rounded mt-4">
                  <Table className={`${skin==='dark'?'customTable':''} `} bordered>
                    <thead>
                      <tr>
                        <th>Module Name</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {resource.map((item, index) => (
                        <tr key={index}>
                          <td>{item.moduleName}</td>
                          <td className='d-flex justify-content-between'>
                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id={`selectall-customCheck-${index}`}
                                checked={item.selectedAll}
                                onChange={() => handleSelectAll(index)}
                                disabled
                              />
                              {"  "}
                              <label
                                className="custom-control-label"
                                htmlFor={`selectall-customCheck-${index}`}
                              >
                                Select All
                              </label>
                            </div>
                            {item?.availableActions?.list &&<div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id={`list-customCheck-${index}`}
                                checked={item.list}
                                onChange={() => handleCheckboxChange(index, 'list')}
                                disabled
                              />
                              {"  "}
                              <label
                                className="custom-control-label"
                                htmlFor={`list-customCheck-${index}`}
                              >
                                {" "}List
                              </label>
                            </div>}
                            {item?.availableActions?.add && <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id={`add-customCheck-${index}`}
                                checked={item.add}
                                onChange={() => handleCheckboxChange(index, 'add')}
                                disabled
                              />
                              {"  "}
                              <label
                                className="custom-control-label"
                                htmlFor={`add-customCheck-${index}`}
                              >
                               {" "} Add
                              </label>
                            </div>}
                            {item?.availableActions?.edit &&<div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id={`edit-customCheck-${index}`}
                                checked={item.edit}
                                onChange={() => handleCheckboxChange(index, 'edit')}
                                disabled
                              />
                              {"  "}
                              <label
                                className="custom-control-label"
                                htmlFor={`edit-customCheck-${index}`}
                              >
                                {" "}Edit
                              </label>
                            </div>}
                            {item?.availableActions?.view &&<div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id={`view-customCheck-${index}`}
                                checked={item.view}
                                onChange={() => handleCheckboxChange(index, 'view')}
                                disabled
                              />
                              {"  "}
                              <label
                                className="custom-control-label"
                                htmlFor={`view-customCheck-${index}`}
                              >
                                
                                View
                              </label>
                            </div>}
                            {/* Render other checkboxes */}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
                <div className="text-center mt-4">
                  {/* <Button type="submit" className="btn btn-primary mx-2" onClick={handleSubmit}>
                    Submit
                  </Button> */}
                  <Button variant="primary" onClick={() => navigate('/role-management')}>Back</Button>
                </div>
              </Form>)}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
    </div>
    </div>
    </React.Fragment>
  );
};

export default ViewNewRole;
