import React, { useEffect, useState } from 'react';
import { Card, Container, Row, Col } from 'react-bootstrap';
import ApexCharts from 'react-apexcharts';
import { Users, DollarSign, Link, BarChart, Shield, CheckCircle, PieChart, Wallet } from 'lucide-react';
import { formatCurrency, getCurrencyFromTO } from '../../../helper/CurrencyFormate';

const calculateTotalEarningsInINR = async (earnings = []) => {
  let totalEarningsInINR = 0;
  for (let earning of earnings) {
    if (earning.currency === "INR") {
      totalEarningsInINR += earning.amount;
    } else {
      const convertedAmount = await getCurrencyFromTO(earning.currency, "INR", earning.amount || 0);
      totalEarningsInINR += convertedAmount;
    }
  }
  return totalEarningsInINR;
};

const AffiliateStats = ({ data }) => {
  const [totalEarningsInINR, setTotalEarningsInINR] = useState(0);

  useEffect(() => {
    const calculateStats = async () => {
      setTotalEarningsInINR(await calculateTotalEarningsInINR(data?.earnings));
    };
    calculateStats();
  }, [data]);

  return (
    <div className="py-4">
      <Row className="mb-4">
        <Col md={3}>
          <Card className="text-center p-3">
            <Card.Body>
              <Card.Text>Total Affiliates</Card.Text>
              <Card.Title>{data?.totalAffiliates || 0}</Card.Title>
              <Users className="h-8 w-8 text-blue-500" />
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card className="text-center p-3">
            <Card.Body>
              <Card.Text>Total Earnings</Card.Text>
              <Card.Title>{formatCurrency(totalEarningsInINR, "INR")}</Card.Title>
              <DollarSign className="h-8 w-8 text-green-500" />
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card className="text-center p-3">
            <Card.Body>
              <Card.Text>Referral Clicks</Card.Text>
              <Card.Title>{data?.referralClicks || 0}</Card.Title>
              <Link className="h-8 w-8 text-purple-500" />
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card className="text-center p-3">
            <Card.Body>
              <Card.Text>Conversion Rate</Card.Text>
              <Card.Title>{data?.conversionRate?.toFixed(2) || 0}%</Card.Title>
              <BarChart className="h-8 w-8 text-orange-500" />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col md={3}>
          <Card className="text-center p-3">
            <Card.Body>
              <Card.Text>Approved Payouts</Card.Text>
              <Card.Title>{formatCurrency(data?.approvedPayouts || 0, 'INR')}</Card.Title>
              <CheckCircle className="h-8 w-8 text-green-500" />
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card className="text-center p-3">
            <Card.Body>
              <Card.Text>Pending Payouts</Card.Text>
              <Card.Title>{formatCurrency(data?.pendingPayouts || 0, 'INR')}</Card.Title>
              <Wallet className="h-8 w-8 text-red-500" />
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card className="text-center p-3">
            <Card.Body>
              <Card.Text>Commission Rate</Card.Text>
              <Card.Title>{data?.commissionRate?.toFixed(2) || 0}%</Card.Title>
              <PieChart className="h-8 w-8 text-teal-500" />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default AffiliateStats;
