import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Sidebar from "../../layouts/Sidebar";
import Header from "../../layouts/Header";
import useSkinSwitcher from "../../common/skin";
import GameService from "../../service/api/game.service";
import { toast } from 'react-toastify';
import { useNavigate, useLocation } from "react-router-dom";
import ReactSelect from "react-select";
import { darkModeStyles2, lightModeStyles2 } from "../../common/reactSelectStyles";
import { useSelector } from "react-redux";
import { BorderSpinner } from "../../common/Spinner";
import InfoComponent from "../../common/Info";

const EditNewGame = () => {
  const theme = useSelector((state) => state.ui.skin);
  const { skin, setSkin } = useSkinSwitcher();
  const gameService = GameService();
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.state?.id;
  const isLoading = useSelector((state) => state.state.loading);
  const [pageLoading, setPageLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const [imagePreview, setImagePreview] = useState(null);
  const [selectedCategoryType, setSelectedCategoryType] = useState(null);
    const [bgImagePreview,setBGImagePreview] = useState(null);
  const [isSubmitted,setIsSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    gameDescription: "",
    category: "",
    image: null,
    bgImage: null,
    underMaintenance: false,
    launchId: "",
    iframeId:"",
  });

  const [categoryList, setCategoryList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedMaintenance, setSelectedMaintenance] = useState(null);

  const maintenanceOptions = [
    { value: true, label: "Yes" },
    { value: false, label: "No" }
  ];

  useEffect(() => {
    const initializeData = async () => {
      await Promise.all([
        getCategories(),
        getGameData()
      ]);
    };
    initializeData();
  }, [id]);

  const getGameData = async () => {
    if (!id) {
      navigate('/game-management');
      return;
    }

    const res = await gameService.getGameById(id);
    if (res?.code === 200) {
      const gameData = res.data;
      setFormData({
        name: gameData.name || "",
        gameDescription: gameData.gameDescription || "",
        category: gameData.category?.id || "",
        underMaintenance: gameData.underMaintenance || false,
        launchId: gameData.launchId || "",
        iframeId: gameData.iframeId || "",

        gameUnique: gameData.gameUnique || "",
        bgImage: gameData.bgImage || ""
      });

      // Set selected maintenance option
      setSelectedMaintenance({
        value: gameData.underMaintenance,
        label: gameData.underMaintenance ? "Yes" : "No"
      });

      // Set selected category
      if (gameData.category) {
        setSelectedCategory({
          value: gameData.category.id,
          label: gameData.category.name,
          type: gameData.category.categoryType || 'own'
        });
        setSelectedCategoryType(gameData.category.categoryType || 'own');
      }

      // Set image preview if exists
      if (gameData.bannerImage) {
      setImagePreview(gameData.bannerImage? `${process.env.REACT_APP_IMAGE_URL}/${gameData.bannerImage}`:null);

        // setImagePreview(gameData.image);
      }

      if (gameData.bgImage) {
      setBGImagePreview(gameData.bgImage? `${process.env.REACT_APP_IMAGE_URL}/${gameData.bgImage}`:null);
        
      }
      setPageLoading(false);
    } else {
      toast.error(res?.message || 'Failed to fetch game data');
      navigate('/game-management');
    }
  };

  const getCategories = async () => {
    const res = await gameService.getCategoryList();
    if (res?.code === 200) {
      const formattedData = res.data.map((item) => ({
        value: item.id,
        label: item.name,
        type: item.categoryType || 'own'
      }));
      setCategoryList(formattedData);
    }
  };

  const validateField = (name, value, categoryType = selectedCategoryType) => {
    switch (name) {
      case "name":
        if (!value.trim()) return "Name is required";
        if (!/^[a-zA-Z0-9\s]*$/.test(value)) return "Name should be alphanumeric";
        if (value.trim().length > 50) return "Name must not exceed 50 characters";
        return "";
      case "category":
        if (!value) return "Category is required";
        return "";
      case "launchId":
        if (categoryType === "third-party") {
          if (!value) return "Launch ID is required";
          if (!/^\d+$/.test(value)) return "Launch ID must be numeric";
          if (value.length > 6) return "Launch ID must not exceed 6 digits";
        }
        return "";
        case "iframeId":
          if (categoryType === "third-party") {
            if (!value) return "iFrame ID is required";
            if (!/^\d+$/.test(value)) return "iFrame ID must be numeric";
            if (value.length > 6) return "iFrame ID must not exceed 6 digits";
          }
          return "";
      default:
        return "";
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    setErrors(prev => ({ ...prev, [name]: validateField(name, value) }));
  };

  const handleMaintenanceChange = (selected) => {
    setSelectedMaintenance(selected);
    setFormData(prev => ({ ...prev, underMaintenance: selected.value }));
  };

  const handleCategoryChange = (selected) => {
    const categoryType = selected?.type || 'own';
    setSelectedCategoryType(categoryType);
    setSelectedCategory(selected);
    setFormData(prev => ({
      ...prev,
      category: selected.value,
      launchId: categoryType === 'third-party' ? prev.launchId : ''
    }));
    
    if (categoryType === 'third-party') {
      setErrors(prev => ({
        ...prev,
        category: "",
        launchId: validateField('launchId', formData.launchId, categoryType)
      }));
    } else {
      setErrors(prev => ({
        ...prev,
        category: "",
        launchId: ""
      }));
    }
  };

  const handleBGImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (!["image/jpeg", "image/png"].includes(file.type)) {
        setErrors(prev => ({ ...prev, bgImage: "Only JPG and PNG files are allowed" }));
        return;
      }
      if (file.size > 1 * 1024 * 1024) { // 5MB limit
        setErrors(prev => ({ ...prev, bgImage: "Image size must be less than 1MB" }));
        return;
      }

      setFormData(prev => ({ ...prev, bgImage: file }));
      setErrors(prev => ({ ...prev, bgImage: "" }));

      const reader = new FileReader();
      reader.onload = () => setBGImagePreview(reader.result);
      reader.readAsDataURL(file);
    }
  };
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (!["image/jpeg", "image/png"].includes(file.type)) {
        setErrors(prev => ({ ...prev, image: "Only JPG and PNG files are allowed" }));
        return;
      }
      if (file.size > 5 * 1024 * 1024) {
        setErrors(prev => ({ ...prev, image: "Image size must be less than 5MB" }));
        return;
      }

      setFormData(prev => ({ ...prev, image: file }));
      setErrors(prev => ({ ...prev, image: "" }));

      const reader = new FileReader();
      reader.onload = () => setImagePreview(reader.result);
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitted(true);
    const validationErrors = {
      name: validateField("name", formData.name),
      category: validateField("category", formData.category),
      launchId: validateField("launchId", formData.launchId),
      iframeId: validateField("iframeId", formData.iframeId)

    };

    const hasErrors = Object.values(validationErrors).some(error => error !== "");
    
    if (hasErrors) {
      setErrors(validationErrors);
      return;
    }
    if (Object.values(errors).some(error => error !== "")) {
      return;
    }

    try {
      const formDataToSend = new FormData();
      formDataToSend.append("name", formData.name.trim());
      formDataToSend.append("category", formData.category);
      formDataToSend.append("underMaintenance", formData.underMaintenance);
      formDataToSend.append("gameDescription", formData.gameDescription.trim());
      
      if (selectedCategoryType === 'third-party') {
        formDataToSend.append("launchId", formData.launchId);
        formDataToSend.append("iframeId", formData.iframeId);
      }
      
      if (formData.image instanceof File) {
        formDataToSend.append("image", formData.image);
      }
      if (formData.bgImage instanceof File) {
        formDataToSend.append("bgImage", formData.bgImage);
      }

      const res = await gameService.updateGame(id, formDataToSend);
      
      if (res?.code === 200) {
        toast.success(res.message);
        navigate('/game-management');
      } else {
        toast.error(res?.message || 'Something went wrong');
      }
    } catch (error) {
      toast.error('An error occurred while updating the game');
      console.error('Error updating game:', error);
    } finally {
      setIsSubmitted(false);
    }
  };

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <Sidebar />
      <div className="main main-app p-3 p-lg-4">
        <Card>
          <Card.Header>
            <h4 className="card-title w-100">Edit Game</h4>
          </Card.Header>
          <Card.Body>
           {pageLoading?<div className="d-flex mt-5 justify-content-center align-content-center">
                                        <BorderSpinner />
                                    </div>:( <>{imagePreview && (
              <div className="d-flex justify-content-center mb-4">
                <Col md={6}>
                  <img 
                    src={imagePreview} 
                    alt="Game preview" 
                    className="img-fluid"
                    style={{ maxHeight: '300px', objectFit: 'contain' }} 
                  />
                </Col>
              </div>
            )}
            
            <Form className="row" onSubmit={handleSubmit}>
              <Col md={4} className="mt-3">
                <Form.Group controlId="image">
                  <Form.Label>Banner Image</Form.Label>
                  <Form.Control
                    type="file"
                    accept="image/jpeg, image/png"
                    onChange={handleImageChange}
                    isInvalid={!!errors.image}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.image}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col md={4} className="mt-3">
                <Form.Group controlId="name">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter game name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    isInvalid={!!errors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={4} className="mt-3">
                  <Form.Group>
                     <Form.Label>Game Id</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter gameUnique"
                      name="gameUnique"
                      value={formData.gameUnique || ""}
                      disabled={true}
                    />
                  </Form.Group>
                </Col>
              <Col md={4} className="mt-3">
                <Form.Group controlId="maintenance">
                  <Form.Label>Under Maintenance</Form.Label>
                  <ReactSelect
                    value={selectedMaintenance}
                    options={maintenanceOptions}
                    styles={theme === 'dark' ? darkModeStyles2 : lightModeStyles2}
                    onChange={handleMaintenanceChange}
                  />
                </Form.Group>
              </Col>

              <Col md={4} className="mt-3">
                <Form.Group controlId="category">
                  <Form.Label>Category</Form.Label>
                  <ReactSelect
                    value={selectedCategory}
                    options={categoryList}
                    styles={theme === 'dark' ? darkModeStyles2 : lightModeStyles2}
                    onChange={handleCategoryChange}
                    isInvalid={!!errors.category}
                  />
                  {errors.category && (
                    <div className="text-danger small mt-1">{errors.category}</div>
                  )}
                </Form.Group>
              </Col>

              {selectedCategoryType === 'third-party' && (
                <Col md={4} className="mt-3">
                  <Form.Group controlId="launchId">
                    <Form.Label>Launch ID</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter launch ID"
                      name="launchId"
                      value={formData.launchId}
                      onChange={handleChange}
                      isInvalid={!!errors.launchId}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.launchId}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                  )}

{selectedCategoryType === 'third-party' && (
                <Col md={4} className="mt-3">
                  <Form.Group controlId="launchId">
                    <Form.Label>iFrame ID</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter iFrame ID"
                      name="iframeId"
                      value={formData.iframeId}
                      onChange={handleChange}
                      isInvalid={!!errors.iframeId}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.iframeId}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              )}
                  
                  {selectedCategoryType === 'third-party' && ( <Col md={4} className="mt-3">
                  <Form.Group>
                    <Form.Label>Game Background Image</Form.Label>
                    <InfoComponent message="The background image is used for enhancing the visual experience of the game. Max file size should be 1MB." />


                    <div className="image-upload-wrapper">

                    <Form.Control
                        type="file"
                        accept="image/jpeg, image/png"
                        onChange={handleBGImageChange}
                        isInvalid={!!errors.bgImage}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.bgImage}
                      </Form.Control.Feedback>
                      {bgImagePreview ? (
                        <img
                          src={bgImagePreview}
                          alt="Preview"
                          className="img-thumbnail"
                          style={{ width: "100px", height: "100px" }}
                        />
                      ) : (
                        <div className="image-placeholder">
                          Background Image Not Available
                        </div>
                      )}
                     
                    </div>
                  </Form.Group>
                </Col>)}

              <Col md={8} className="mt-3">
                <Form.Group controlId="gameDescription">
                  <Form.Label>Game Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="gameDescription"
                    placeholder="Enter game description"
                    value={formData.gameDescription}
                    onChange={handleChange}
                    rows={5}
                  />
                </Form.Group>
              </Col>

              <div className="d-flex justify-content-start mt-5">
                <Button 
                  variant="primary" 
                  type="submit" 
                  className="mx-4"
                  disabled={isSubmitted}
                >
                  {isSubmitted ? "Please wait..." : "Submit"}
                </Button>
                <Button 
                  variant="danger" 
                  type="button" 
                  onClick={() => navigate("/game-management")}
                >
                  Cancel
                </Button>
              </div>
            </Form></>)}
          </Card.Body>
        </Card>
      </div>
    </React.Fragment>
  );
};

export default EditNewGame;

// import React, { useState, useEffect } from "react";
// import Form from "react-bootstrap/Form";
// import Select from "react-select";
// import Button from "react-bootstrap/Button";
// import Col from "react-bootstrap/Col";
// import Card from "react-bootstrap/Card";
// import Sidebar from "../../layouts/Sidebar";
// import Header from "../../layouts/Header";
// import useSkinSwitcher from "../../common/skin";
// import RoleService from "../../service/api/role.service";
// import UserService from "../../service/api/user.service ";
// import GameService from "../../service/api/game.service";
// import { toast } from "react-toastify";
// import { Link, useNavigate, useLocation } from "react-router-dom";
// import MerchantService from "../../service/api/merchant.service";
// import ReactSelect from "react-select";
// import { darkModeStyles2, lightModeStyles2 } from "../../common/reactSelectStyles";
// import { useSelector } from "react-redux";

// const EditNewGame = () => {
//   const theme = useSelector((state) => state.ui.skin);
//   const { skin, setSkin } = useSkinSwitcher();
//   const roleService = RoleService();
//   const merchantService = MerchantService();
//   const gameService = GameService();
//   const location = useLocation();
//   const [bannerImage,setBannerImage] = useState(null);

//   const { id } = location.state;

//   const navigate = useNavigate();
//   const [showError, setShowError] = useState(false);
//   const [roleList, setRoleList] = useState([]);
//   const [roleOption, setRoleOption] = useState([]);
//   const [errors, setErrors] = useState({});
//   const [imagePreview, setImagePreview] = useState(null);
//   const [formData, setFormData] = useState({
//     // role: "",
//     merchant: [],
//     name: "",
//     // phone: "",
//     // email: "",
//     // password: "",
//   });
//   const [merchantList, setMerchantList] = useState([]);
//   const [merchantOption, setMerchantOption] = useState(null);
//   const [categoryList, setCategoryList] = useState([]);
//   const [selectedResult, setSelectedResult] = useState([]);
//   const [selectedCategory,setSelectedCategory]=useState([]);
//   const [underMaintainanceOptions, setunderMaintainanceOptions] = useState([{value: true, label: "Yes"}, {value: false, label: "No"}])
//   const [underMaintainance, setunderMaintainance] = useState([])
  
//   const getMerchantList = async () => {
//     const res = await merchantService.getAllMerchants();
//     if (res?.code === 200) {
//       setMerchantList(res.data);
//       let data = res.data.map((item) => ({ value: item.id, label: item.name }));
//       setMerchantOption(data);
//     }
//     console.log(merchantList, " and ", merchantOption)
//   }

//   const getCategory = async () => {
//     const res = await gameService.getCategoryList();
//     if (res?.code === 200) {
      
//       let data = res.data.map((item) => ({ value: item.id, label: item.name }));
//       setCategoryList(data);
//     }
//     // console.log(merchantList, " and ", merchantOption)
//   }

//   useEffect(() => {
//     getCategory();
//     getMerchantList();
//   }, []);

//   useEffect(() => {
//     getStaffDetail();
//   }, [merchantOption]);


//   const getStaffDetail = async () => {
//     const res = await gameService.getGameById(id);
//     if (res?.code === 200) {
//       const result = merchantOption?.filter(option => res.data?.merchant?.includes(option.value));
//       const category = categoryList?.filter(option => res.data.category?.id==option.value)

//       console.log(category,"Helloooo======================",res.data)
//       setFormData(res?.data);
//       setSelectedResult(result);
//       setSelectedCategory(category);
//       setImagePreview(res?.data?.bannerImage? `${process.env.REACT_APP_IMAGE_URL}/${res?.data?.bannerImage}`:null);
//       setBannerImage(res?.data?.bannerImage)

//       // const maintainanceObj = {
//       //   true:{ value: true, label: "True" },
//       //   false:{value: false, label: "False"}
//       // }
//       const value = underMaintainanceOptions.filter((item) => item.value === res?.data?.underMaintenance);
//       // console.log(value,"hellllllllllllllllllllllll",res?.data?.underMaintenance)
//       setunderMaintainance(value);
//       // let data = res.data.map((item) => ({ value: item.id, label: item.name }));
//       // setRoleOption(data);
//     }
//   };


  

//   const handleSelectChange = (items) => {
//     console.log(items, 'form item');
//     const valuesArray = items ? items?.map(item => item.value) : [];
//     // setSelectedValues(valuesArray);
//     setFormData((preValue) => ({
//       ...preValue,
//       merchant: valuesArray
//     }));
//     setShowError(false);
//     setSelectedResult(items);
   
//   };
//   const handleMaintainanceSelectChange = (items) => {
//     console.log(items, 'form item');
//     // const valuesArray = items ? items?.map(item => item.value) : [];
//     // setSelectedValues(valuesArray);
//     setFormData((preValue) => ({
//       ...preValue,
//       underMaintenance: items.value
//     }));
//     setShowError(false);
//     setunderMaintainance(items);
    
//   };
  


//   const handleChangeCategory=(items) => {
//     // console.log(items, 'form item');
//     // const valuesArray = items ? items?.map(item => item.value) : [];
//     // setSelectedValues(valuesArray);
//     // setFormData((preValue) => ({
//     //   ...preValue,
//     //   category: items.value
//     // }));
//     setShowError(false);
//     setSelectedCategory([items]);
//     console.log(items,"item==============")

//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     console.log("{ ...errors, [name]: errorMessage }", name, value);

//     let errorMessage = "";

//     // Perform validation based on the field name
//     switch (name) {
//       case "name":
//         if (!value.trim()) {
//           errorMessage = "Name is required.";
//         } else if (!/^[a-zA-Z0-9\s]*$/.test(value)) {
//           errorMessage = "Name is required";
//         } else {
//           errorMessage = "";
//         }
//         break;
//       case "merchant":
//         if (!value.length) {
//           errorMessage = "Merchant is required.";
//         } else {
//           errorMessage = "";
//         }
//         break;
//       // Add validation for other fields as needed
//       default:
//         break;
//     }

//     setFormData({ ...formData, [name]: value });
//     // showError(false)
//     setErrors({ ...errors, [name]: errorMessage });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     // Add your form submission logic here
//     // Perform validations
//     console.log("formData", formData);
//     const validationErrors = {};

//     if (formData.name.trim() == "") {
//       validationErrors.name = "Name is required";
//     } else if (!/^[a-zA-Z0-9\s]*$/.test(formData.name)) {
//       validationErrors.name = "Name should be alphanumeric";
//     } else {
//       validationErrors.name = "";
//     }
//     // if (formData.merchant?.length === 0) {
//     //   validationErrors.merchant = "Merchant is required";
//     // } else {
//     //   validationErrors.merchant = "";
//     // }
//     // Add more password validation logic as needed
//     console.log("validationErrors", Object.values(validationErrors));

//     if (Object.values(validationErrors).every((item) => item === "")) {
//       // No errors, proceed with form submission
//       console.log("resss===");
//       setShowError(false);
//       console.log("formData", formData);
//        // Create a new FormData object
//        const formDataToSend = new FormData();
        
//        // Append form fields to FormData
//       formDataToSend.append("name", formData.name);
//       // if (selectedCategory[0]?.value) {
//         formDataToSend.append("category", selectedCategory[0]?.value || "");
//       // }
      
//       //  formDataToSend.append("merchant", JSON.stringify(formData.merchant));
//        formDataToSend.append("underMaintenance", JSON.stringify(formData.underMaintenance));
//        formDataToSend.append("gameDescription", formData.gameDescription);
//        if (formData.merchant.length !== 0) {
//         formDataToSend.append("merchant", JSON.stringify(formData.merchant));
//        }
//        // Append file if it exists
//        if (formData.image) {
//            formDataToSend.append("image", formData.image);
//        }
//       const res = await gameService.updateUser(id, formDataToSend);
//       console.log("resss===", res);
//       if (res?.code == 200) {
//         toast.success(res?.message);
//         navigate("/game-management");
//       } else {
//         toast.error(res?.message);
//       }
//     } else {
//       // Set validation errors
//       setShowError(true);
//       setErrors(validationErrors);
//     }
//   };

//   const handleRoleChange = (selectedOption) => {
//     let validationErrors = "";
//     if (selectedOption.value == "") {
//       validationErrors = "Role is required";
//     } else {
//       validationErrors = "";
//     }
//     setShowError(true);
//     setErrors({ ...errors, role: validationErrors });
//     setFormData({ ...formData, role: selectedOption.value });
//   };

//   const customStyles = {
//     control: (provided, state) => ({
//       ...provided,
//       backgroundColor: "#192030", // Background color
//       color: "#A8A2A2", // Text color
//       borderColor: state.isFocused ? "#1F283D" : "#1F283D", // Border color
//       boxShadow: state.isFocused ? "0 0 0 1px #fff" : "#1E273A", // Box shadow
//       "&:hover": {
//         borderColor: "#1E273A", // Border color on hover
//       },
//     }),
//     option: (provided, state) => ({
//       ...provided,
//       backgroundColor: state.isSelected
//         ? "#192030"
//         : state.isFocused
//         ? "#1F283D"
//         : "#141926", // Option background color
//       color: "#A8A2A2", // Option text color
//       "&:hover": {
//         backgroundColor: "#1E273A", // Option background color on hover
//       },
//     }),
//     menu: (provided) => ({
//       ...provided,
//       backgroundColor: "#333", // Menu background color
//       color: "#A8A2A2", // Menu text color
//     }),
//     singleValue: (provided) => ({
//       ...provided,
//       color: "#A8A2A2", // Single value text color
//     }),
//   };
//   const handleImageChange = (e) => {
//     const file = e.target.files[0];
//     if (file) {
//       formData.image = file;
//       const reader = new FileReader();
//       reader.onloadend = () => {
//         setImagePreview(reader.result); // Set the preview image URL
//       };
//       reader.readAsDataURL(file);
//     }
//   };
//   return (
//     <React.Fragment>
//       <Header onSkin={setSkin} />
//       <Sidebar />
//       <div className="main main-app p-3 p-lg-4">
//         <div className=" align-items-center mb-4">
//           <Card>
//             <Card.Header>
//               <h4 className="card-title w-100">Edit Game</h4>
//             </Card.Header>
//             <Card.Body>
//               <div className="d-flex justify-content-center"> 

//               <Col md={6}>
//               <img src={imagePreview?imagePreview:'../images/card-image-placeholder.png'} style={{ maxWidth: '100%' }}  />
//               </Col>
//               </div>
//               <Form className="row" onSubmit={handleSubmit}>
//               <Col md={4} className="mt-3">
//                   <Form.Group controlId="name">
//                     <Form.Label>Banner Image</Form.Label>
//                     <Form.Control
//                       type="file"
//                       placeholder="Enter name"
//                       name="image"
//                       onChange={handleImageChange}
//                       // value={formData.name}
//                       // onChange={handleChange}
//                       // isInvalid={showError && !!errors.name}
//                     />
//                     <Form.Control.Feedback type="invalid">
//                       {errors.name}
//                     </Form.Control.Feedback>
//                   </Form.Group>
//                 </Col>
//                 <Col md={4} className="mt-3">
//                   <Form.Group controlId="name">
//                     <Form.Label>Name</Form.Label>
//                     <Form.Control
//                       type="text"
//                       placeholder="Enter name"
//                       name="name"
//                       value={formData.name}
//                       onChange={handleChange}
//                       isInvalid={showError && !!errors.name}
//                     />
//                     <Form.Control.Feedback type="invalid">
//                       {errors.name}
//                     </Form.Control.Feedback>
//                   </Form.Group>
//                 </Col>

//                 {/* <Col md={4} className="mt-3">
//                   <Form.Group controlId="name">
//                     <Form.Label>Merchant</Form.Label>
                   
//                     <ReactSelect
//                       styles={
//                         theme === "dark" ? darkModeStyles2 : lightModeStyles2
//                       }
//                       value={selectedResult}
//                       name="merchant"
//                       options={merchantOption}
//                       isMulti
//                       onChange={handleSelectChange}
//                     />
                    
//                   </Form.Group>
//                 </Col> */}

//                 <Col md={4} className="mt-3">
//                   <Form.Group>
//                     <Form.Label>Game Id</Form.Label>
//                     <Form.Control
//                       type="text"
//                       placeholder="Enter gameUnique"
//                       name="gameUnique"
//                       value={formData.gameUnique || ""}
//                       disabled={true}
//                     />
//                   </Form.Group>
//                 </Col>
//                 <Col md={4} className="mt-3">
//                   <Form.Group>
//                     <Form.Label>Under Maintenance</Form.Label>
//                     <ReactSelect
//                       styles={
//                         theme === "dark" ? darkModeStyles2 : lightModeStyles2
//                       }
//                       value={underMaintainance}
//                       name="underMaintainance"
//                       options={underMaintainanceOptions}
//                       onChange={handleMaintainanceSelectChange}
//                     />
//                   </Form.Group>
//                 </Col>
//                 <Col md={4} className="mt-3">
//                   <Form.Group controlId="category">
//                     <Form.Label>Category</Form.Label>

//                     <ReactSelect
//                        value={selectedCategory}
//                       styles={theme === 'dark' ? darkModeStyles2 : lightModeStyles2}
//                       name="category"
//                       options={categoryList}
//                       onChange={handleChangeCategory}

//                     />
//                     {/* <Form.Text className="text-danger small">
                    
//                       {errors.merchant !== "" && showError && "Merchant is required"}
//                     </Form.Text> */}

//                     {/* <Form.Control.Feedback type="invalid">
//                   {errors.merchant}
//                 </Form.Control.Feedback> */}
//                   </Form.Group>
//                 </Col>
//                 <Col md={4} className="mt-3">
//                   <Form.Group controlId="gameDescription">
//                   <Form.Label>Game Description</Form.Label>
//                   <Form.Control
//                     as="textarea"
//                     name="gameDescription"
//                     placeholder="Enter game description"
//                     value={formData.gameDescription}
//                     onChange={handleChange}
//                     rows={5} // Adjust the number of rows to control the height of the textarea
//                     isInvalid={errors.gameDescription !== "" && showError}
//                   />
//                   <Form.Control.Feedback type="invalid">
//                     {errors.gameDescription !== "" && showError && "Game description is required"}
//                   </Form.Control.Feedback>
//                 </Form.Group>
//               </Col>
//                 <div className="d-flex justify-content-center mt-5">
//                   <Button variant="primary" type="submit" className="mx-4">
//                     Submit
//                   </Button>
//                   <Button
//                     variant="danger"
//                     type="button"
//                     onClick={() => navigate("/game-management")}
//                   >
//                     Cancel
//                   </Button>
//                 </div>
//               </Form>
//             </Card.Body>
//           </Card>
//         </div>
//       </div>
//     </React.Fragment>
//   );
// };

// export default EditNewGame;
