import React, { useState } from "react";
import { Form, Button, Container, Row, Col, Card } from "react-bootstrap";
import { toast } from "react-toastify";
import { SketchPicker } from "react-color";
import ThemeService from "../../service/api/theme.service";
import Header from '../../layouts/Header';
import Sidebar from '../../layouts/Sidebar';
import GameSettingService from '../../service/api/game-setting.service';
import MerchantService from '../../service/api/merchant.service';
import { darkModeStyles, lightModeStyles } from '../../common/reactSelectStyles';
import { AsyncPaginate } from 'react-select-async-paginate';
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import InfoComponent from "../../common/Info";

const AddTheme = () => {
    const [showColorPicker, setShowColorPicker] = useState({});
    const theme = useSelector((state) => state.ui.skin);
    const gameService = GameSettingService();
    const themeService = ThemeService();
    const merchantService = MerchantService();
    const navigate = useNavigate();
    const [selectedMerchant, setSelectedMerchant] = useState([]);
//    1. Background Color 1
// 2.Background Color 2
// 3.Primary Color
// 4.Button Gradient
// 5.Shadow Color

    const [formValues, setFormValues] = useState({
        // merchantId: "",
        themeName: "",
        backgroundColor1: "#FFFFFF",
        backgroundColor2: "#FFFFFF",
        primaryColor: "#007bff",
        buttonGradient: "#FFFFFF",
        shadowColor: "#000000",
    });
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    const loadOptions = async (search, loadedOptions, { page }) => {
        try {
            const response = await merchantService.getMerchants({
                page,
                limit: 25,
                searchBy: search,
            });
            if (response.code === 200) {
                return {
                    options: response.data?.docs.map((merchant) => ({
                        value: merchant.id,
                        label: merchant.name,
                    })),
                    hasMore: response.data.hasNextPage,
                    additional: { page: page + 1 },
                };
            } else {
                throw new Error(response.message);
            }
        } catch (error) {
            console.error("Error fetching merchants:", error);
            return {
                options: [],
                hasMore: false,
            };
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prev) => ({
            ...prev,
            [name]: value,
        }));
        setErrors((prev) => ({
            ...prev,
            [name]: "",
        }));
    };

    const handleColorChange = (color, name) => {
        const rgba = color.rgb;
        const alpha = Math.round(rgba.a * 255).toString(16).padStart(2, '0');
        const hexColor = `${color.hex}${alpha}`;
        
        setFormValues((prev) => ({
            ...prev,
            [name]: hexColor,
        }));
        setErrors((prev) => ({
            ...prev,
            [name]: "",
        }));
    };

    const toggleColorPicker = (fieldName) => {
        setShowColorPicker((prev) => ({
            ...prev,
            [fieldName]: !prev[fieldName],
        }));
    };

    const validateForm = () => {
        const newErrors = {};
        // Updated regex to support 8-digit hex colors (including alpha)
        const hexColorRegex = /^#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{4}|[0-9A-Fa-f]{6}|[0-9A-Fa-f]{8})$/;

        // if (!formValues.merchantId) newErrors.merchantId = "Merchant is required.";
        if (!formValues?.themeName?.trim()) newErrors.themeName = "Theme Name is required.";

        const colorFields = [
            'backgroundColor1', 'backgroundColor2', 'primaryColor', 'shadowColor',
            'buttonGradient'
        ];

        colorFields.forEach(field => {
            if (!formValues[field] || !hexColorRegex.test(formValues[field])) {
                newErrors[field] = `Valid hex color is required (e.g., #RRGGBB or #RRGGBBAA)`;
            }
        });

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        setIsSubmitting(true);
        try {
            // const payload = { 
            //     ...formValues, 
            //     backgroundColor: formValues.backgroundColor1 
            // };
            // delete payload.backgroundColor1;
            // delete payload.backgroundColor2;
            
            const res = await themeService.addTheme(formValues);

            if (res.code === 200) {
                toast.success(res.message || "Theme added successfully!");
                navigate("/theme-management");
            } else {
                toast.error(res.message);
            }
        } catch (error) {
            console.error("Error adding theme:", error);
            toast.error("An error occurred while adding the theme.");
        } finally {
            setIsSubmitting(false);
        }
    };

    const hexToRgba = (hex) => {
        let r = 0, g = 0, b = 0, a = 1;
        
        // Remove the hash
        hex = hex.replace('#', '');
        
        if (hex.length === 8) {
            r = parseInt(hex.slice(0, 2), 16);
            g = parseInt(hex.slice(2, 4), 16);
            b = parseInt(hex.slice(4, 6), 16);
            a = parseInt(hex.slice(6, 8), 16) / 255;
        } else if (hex.length === 6) {
            r = parseInt(hex.slice(0, 2), 16);
            g = parseInt(hex.slice(2, 4), 16);
            b = parseInt(hex.slice(4, 6), 16);
        }
        
        return { r, g, b, a };
    };

    return (
        <React.Fragment>
            <Header />
            <Sidebar />
            <div className="main main-app p-3 p-lg-4">
                <div className="align-items-center mb-4">
                    <Container className="mt-4">
                        <Card>
                            <Card.Header>
                                <h4 className="card-title w-100">Add New Theme</h4>
                            </Card.Header>
                            <Card.Body>
                                <Form onSubmit={handleSubmit}>
                                    <Row>
                                        {/* <Col md={6} className='mt-3'>
                                            <Form.Group>
                                                <Form.Label>Select Merchant</Form.Label>
                                                <AsyncPaginate
                                                    value={selectedMerchant}
                                                    loadOptions={loadOptions}
                                                    onChange={(selectedOption) => {
                                                        setSelectedMerchant(selectedOption);
                                                        handleChange({ target: { name: "merchantId", value: selectedOption ? selectedOption.value : "" } });
                                                    }}
                                                    additional={{ page: 1 }}
                                                    styles={theme === "dark" ? darkModeStyles : lightModeStyles}
                                                    placeholder="Select a merchant"
                                                    isClearable
                                                />
                                                {errors.merchantId && (
                                                    <Form.Text className="text-danger">{errors.merchantId}</Form.Text>
                                                )}
                                            </Form.Group>
                                        </Col> */}
                                        <Col md={6} className="mt-3">
                                            <Form.Group controlId="themeName">
                                                <Form.Label>Theme Name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter Theme Name"
                                                    name="themeName"
                                                    value={formValues.themeName}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.themeName}
                                                />
                                                <Form.Control.Feedback type="invalid">{errors.themeName}</Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        {[
                                            { name: "backgroundColor1", label: "Background Color 1", info: "Used as a background color for side bar and header" },
                                            { name: "backgroundColor2", label: "Background Color 2", info: "Used as a background color of main container" },
    
                                            { name: "primaryColor", label: "Primary Color", info: "CTA color for website" },
                                            { name: "buttonGradient", label: "Button Gradient Color", info: "Used as a background gradient color in sign up and sign in button" },
                                            { name: "shadowColor", label: "Shadow Color", info: "Shadow color " },
                                            
                                        ].map((field) => (
                                            <Col md={6} className="mt-3" key={field.name}>
                                                <Form.Group controlId={field.name}>
                                                    <Form.Label>{field.label}</Form.Label>
                                                    <InfoComponent message={field.info} />
                                                    <div className="position-relative">
                                                        <Row>
                                                            <Col xs={4}>
                                                                <div
                                                                    style={{
                                                                        padding: '5px',
                                                                        background: '#fff',
                                                                        borderRadius: '1px',
                                                                        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                                                                        display: 'inline-block',
                                                                        cursor: 'pointer',
                                                                    }}
                                                                    onClick={() => toggleColorPicker(field.name)}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            width: '36px',
                                                                            height: '36px',
                                                                            borderRadius: '2px',
                                                                            background: formValues[field.name],
                                                                        }}
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col xs={8}>
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Hex Color (e.g., #RRGGBB or #RRGGBBAA)"
                                                                    name={field.name}
                                                                    value={formValues[field.name]}
                                                                    onChange={handleChange}
                                                                    isInvalid={!!errors[field.name]}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors[field.name]}
                                                                </Form.Control.Feedback>
                                                            </Col>
                                                        </Row>
                                                        {showColorPicker[field.name] && (
                                                            <div style={{ position: 'absolute', zIndex: 2 }}>
                                                                <div
                                                                    style={{
                                                                        position: 'fixed',
                                                                        top: '0px',
                                                                        right: '0px',
                                                                        bottom: '0px',
                                                                        left: '0px',
                                                                    }}
                                                                    onClick={() => toggleColorPicker(field.name)}
                                                                />
                                                                <SketchPicker
                                                                    color={hexToRgba(formValues[field.name])}
                                                                    onChange={(color) => handleColorChange(color, field.name)}
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                        ))}
                                    </Row>
                                    <Row className="mt-4">
                                        <div className="d-flex justify-content-start gap-4 mt-5">
                                            <Button disabled={isSubmitting} type="submit" variant="primary">
                                                {isSubmitting ? "Please wait..." : "Submit"}
                                            </Button>
                                            <Button variant="danger" type="button" onClick={() => navigate("/theme-management")}>
                                                Cancel
                                            </Button>
                                        </div>
                                    </Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Container>
                </div>
            </div>
        </React.Fragment>
    );
};

export default AddTheme;
