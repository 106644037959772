import React, { useEffect, useState } from 'react';
import { Form, Button, Container, Row, Col, Card } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import Header from '../../layouts/Header';
import Sidebar from '../../layouts/Sidebar';
import ExposureService from '../../service/api/exposure.service';
import { toast } from 'react-toastify';
import GameSettingService from '../../service/api/game-setting.service';
import MerchantService from '../../service/api/merchant.service';
import { darkModeStyles, lightModeStyles } from '../../common/reactSelectStyles';
import { AsyncPaginate } from 'react-select-async-paginate';
import { useSelector } from 'react-redux';
import InfoComponent from "../../common/Info";

const AddExposure = () => {
    const theme = useSelector((state) => state.ui.skin);
    const gameService = GameSettingService();
    const merchantService = MerchantService();
    const navigate = useNavigate();
    const exposureService = ExposureService();
    const [selectedMerchant, setSelectedMerchant] = useState([]);
    const [formValues, setFormValues] = useState({
        merchantId: '',
        gameId: '',
        individualBetLimit: '',
        // dailyLimit: '',
        // monthlyLimit: '',
    });
    const [errors, setErrors] = useState({});
    const [showError, setShowError] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [gameList, setGameList] = useState([]);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await gameService.getGameListDropdown();
                if (res.code === 200) {
                    setGameList(res.data);
                    // Set default gameId to "Cricket" if present
                    const defaultGame = res.data.find(game => game.name === 'Cricket');
                    if (defaultGame) {
                        setFormValues(prev => ({
                            ...prev,
                            gameId: defaultGame.id,
                        }));
                    }
                } else {
                    toast.error(res.message);
                }
            } catch (error) {
                console.error('Error fetching game list:', error);
                // toast.error('An error occurred while fetching the game list.');
            }
        };
        fetchData();
    }, []);

    const loadOptions = async (search, loadedOptions, { page }) => {
        // This function can be adjusted if you want to fetch merchants dynamically
        try {
            // Mock fetching merchants (replace with actual service call if needed)
            const response = await merchantService.getMerchants({
                page,
                limit: 25,
                searchBy: search,
            });
            if (response.code === 200) {
                return {
                    options: response.data?.docs.map((merchant) => ({
                        value: merchant.id, // Merchant ID
                        label: merchant.name, // Merchant Name
                    })),
                    hasMore: response.data.hasNextPage,
                    additional: { page: page + 1 },
                };
            } else {
                throw new Error(response.message);
            }


        } catch (error) {
            console.error("Error fetching merchants:", error);
            return {
                options: [],
                hasMore: false,
            };
        }
    };




    const validateForm = () => {
        const newErrors = {};
        console.log(formValues,newErrors,"Error creating")

        if (!formValues.merchantId) {
            newErrors.merchantId = 'Merchant is required.';
        }
        if (!formValues.gameId) {
            newErrors.gameId = 'Game is required.';
        }
        if(!formValues.individualBetLimit){
            newErrors.individualBetLimit = 'Individual bet odds limit is required.';
        }
        else if (formValues.individualBetLimit <= 0) {
            newErrors.individualBetLimit = 'Individual bet odds limit must be greater than 0.';
        }
        else if(formValues.individualBetLimit >=1000){
            newErrors.individualBetLimit = 'Individual bet odds limit must be less than 1000.';
        }
        // if (!formValues.dailyLimit || formValues.dailyLimit <= 0) {
        //     newErrors.dailyLimit = 'Daily Payout Limit must be greater than 0.';
        // }
        // if (!formValues.monthlyLimit || formValues.monthlyLimit <= 0) {
        //     newErrors.monthlyLimit = 'Monthly Payout Limit must be greater than 0.';
        // }
        // console.log(formValues,newErrors,"Error creating 2")

        // // Validate the hierarchy: monthly >= daily >= individual
        // if (Number(formValues.dailyLimit) < Number(formValues.individualBetLimit)) {
        //     newErrors.dailyLimit = 'Daily Payout Limit must be greater than or equal to Individual Payout Limit.';
        // }
        // if (Number(formValues.monthlyLimit) < Number(formValues.dailyLimit)) {
        //     newErrors.monthlyLimit = 'Monthly Payout Limit must be greater than or equal to Daily Limit.';
        // }

    //    console.log(formValues,newErrors,Number(formValues.dailyLimit) < Number(formValues.individualBetLimit),Number(formValues.monthlyLimit) < Number(formValues.dailyLimit),"Error creating 3")

        setErrors(newErrors);
        setShowError(true);
        return Object.keys(newErrors).length === 0;
    };

    // const handleChange = (e) => {
    //     const { name, value } = e.target;
    //     setFormValues({
    //         ...formValues,
    //         [name]: value,
    //     });
    //     setErrors((prev) => ({
    //         ...prev,
    //         [name]: '',
    //     }));
    // };

    const handleChange = (eventOrName, value) => {
        if (typeof eventOrName === 'string') {
            // For custom components like AsyncPaginate
            setFormValues((prev) => ({
                ...prev,
                [eventOrName]: value,
            }));
            setErrors((prev) => ({
                ...prev,
                [eventOrName]: '',
            }));
        } else {
            // For standard HTML inputs
            const { name, value } = eventOrName.target;
            setFormValues((prev) => ({
                ...prev,
                [name]: value,
            }));
            setErrors((prev) => ({
                ...prev,
                [name]: '',
            }));
        }
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setIsSubmitting(true);
            if (!validateForm()) {
                return;
            }
            const payload = {
                ...formValues,
            };
            // const formData = new FormData();
            // formData.append('individualBetLimit', formValues.individualBetLimit);
            // formData.append('dailyLimit', formValues.dailyLimit);
            // formData.append('monthlyLimit', formValues.monthlyLimit);
            // formData.append('gameId', formValues.gameId);

            const res = await exposureService.AddExposure(formValues);

            if (res.code === 200) {
                toast.success(res.message);
                navigate('/exposure-management');
            } else {
                toast.error(res.message);
            }
        } catch (error) {
            console.error('Error adding exposure:', error);
            toast.error('An error occurred while adding the exposure.');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <React.Fragment>
            <Header />
            <Sidebar />
            <div className="main main-app p-3 p-lg-4">
                <div className="align-items-center mb-4">
                    <Card>
                        <Card.Header>
                            <h4 className="card-title w-100">Add New Exposure</h4>
                        </Card.Header>
                        <Card.Body>
                            <Container>
                                <Form onSubmit={handleSubmit}>
                                    <Row>
                                        <Col md={6} className='mt-3'>
                                            <Form.Group>
                                                <Form.Label>Select Merchant</Form.Label>
                                                <AsyncPaginate

                                                    value={selectedMerchant}
                                                    loadOptions={loadOptions}
                                                    onChange={(selectedOption) => {
                                                        setSelectedMerchant(selectedOption);
                                                        handleChange("merchantId", selectedOption ? selectedOption.value : "");
                                                    }}
                                                    additional={{ page: 1 }}
                                                    styles={theme === "dark" ? darkModeStyles : lightModeStyles}
                                                    placeholder="Select a merchant"
                                                    isClearable
                                                />
                                                {errors.merchantId && (
                                                    <Form.Text className="text-danger">{errors.merchantId}</Form.Text>
                                                )}
                                            </Form.Group>
                                        </Col>
                                        <Col md={6} className="mt-3">
                                            <Form.Group controlId="gameId">
                                                <Form.Label>Game</Form.Label>
                                                <Form.Select
                                                    name="gameId"
                                                    value={formValues.gameId}
                                                    onChange={handleChange}
                                                    isInvalid={showError && !!errors.gameId}
                                                    disabled
                                                >
                                                    <option value="">Select a game</option>
                                                    {gameList.map(game => (
                                                        <option key={game.id} value={game.id}>
                                                            {game.name}
                                                        </option>
                                                    ))}
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.gameId}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6} className="mt-3">
                                            <Form.Group controlId="individualBetLimit">
                                                <Form.Label>Individual Bet Odds Limit
                                                    <InfoComponent message="Odds must be between 0 and 1000. Higher odds are restricted for betting." />
                                                </Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    placeholder="Enter Individual Payout Limit"
                                                    name="individualBetLimit"
                                                    value={formValues.individualBetLimit}
                                                    onChange={handleChange}
                                                    isInvalid={showError && !!errors.individualBetLimit}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.individualBetLimit}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        {/* <Col md={6} className="mt-3">
                                            <Form.Group controlId="dailyLimit">
                                                <Form.Label>Daily Payout Limit</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    placeholder="Enter Daily Payout Limit"
                                                    name="dailyLimit"
                                                    value={formValues.dailyLimit}
                                                    onChange={handleChange}
                                                    isInvalid={showError && !!errors.dailyLimit}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.dailyLimit}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6} className="mt-3">
                                            <Form.Group controlId="monthlyLimit">
                                                <Form.Label>Monthly Payout Limit</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    placeholder="Enter Monthly Payout Limit"
                                                    name="monthlyLimit"
                                                    value={formValues.monthlyLimit}
                                                    onChange={handleChange}
                                                    isInvalid={showError && !!errors.monthlyLimit}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.monthlyLimit}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col> */}
                                    </Row>
                                    <Row className="mt-4">
                                        <Col>
                                            <Button disabled={isSubmitting} type="submit" variant="primary" className="me-2">
                                                {isSubmitting ? 'Please wait...' : 'Submit'}
                                            </Button>
                                            <Link to="/exposure-management" className="btn btn-danger">
                                                Cancel
                                            </Link>
                                        </Col>
                                    </Row>
                                </Form>
                            </Container>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </React.Fragment>
    );
};

export default AddExposure;
