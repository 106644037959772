import React, { useEffect, useState } from "react";
import Header from "../../layouts/Header";
import Sidebar from "../../layouts/Sidebar";
import { Button, Card, Form, Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import CurrencyService from "../../service/api/currency.service";
import AffiliateService from "../../service/api/affiliate.service";
import { BorderSpinner } from "../../common/Spinner";
import InfoComponent from "../../common/Info";

export default function CommissionSetting() {
    const affiliateService = AffiliateService();
    const currencyService = CurrencyService();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [formData, setFormData] = useState({
        deposit: { commissionType: "percentage", commissionRate: 10, disabled: false },
        registration: { commissionType: "flat", commissionRate: 5, disabled: true },
        bet: { commissionType: "percentage", commissionRate: 2, disabled: true },
    });
    const [currencyList, setCurrencyList] = useState([]);
    const [errors, setErrors] = useState({});
    const [isEditEnabled, setIsEditEnabled] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);
    const handleChange = (e, category) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [category]: {
                ...prev[category],
                [name]: value,
            },
        }));
        setErrors((prev) => ({
            ...prev,
            [category]: "",
        }));
    };

    const validateForm = () => {
        let newErrors = {};
        Object.keys(formData).forEach((category) => {
            const { commissionType, commissionRate } = formData[category];
            if (commissionRate === "") {
                newErrors[category] = "Commission rate required";
            } else if (
                (commissionType === "percentage" && (commissionRate < 0 || commissionRate > 100)) ||
                (commissionType === "flat" && (commissionRate < 0 || commissionRate > 1000000))
            ) {
                newErrors[category] = commissionType === "percentage"
                    ? "Percentage must be between 0 and 100"
                    : "Flat amount must be between 0 and 1,000,000";
            }
        });
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        try {
            if (!validateForm()) return;
            const response = await affiliateService.updateCommissionSettings(formData);
            if (response.code === 200) {
                toast.success(response.message);
                setIsEditEnabled(false)
                getSettings();
            } else {
                throw new Error("Failed to update settings");
            }
        } catch (err) {
            console.error(err.message);
        } finally {
            setIsSubmitting(false);
        }
    };

    const getCurrencyList = async () => {
        try {
            const res = await currencyService.getCurrencyList({ page: 1, limit: 25, searchBy: "" });
            if (res?.code === 200) {
                setCurrencyList(res?.data?.docs);
            } else {
                console.error(res?.message);
            }
        } catch (error) {
            console.error("Error fetching currency list:", error);
        }
    };

    const getSettings = async () => {
        try {
            const response = await affiliateService.getCommissionSettings();
            if (response.code === 200) {
                setFormData({
                    bet: { ...response.data[0].bet, disabled: true },
                    deposit: { ...response.data[0].deposit, disabled: false },
                    registration: { ...response.data[0].registration, disabled: true },
                });
                setPageLoading(false);
            } else {
                throw new Error(response.message);
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        getCurrencyList();
        getSettings();
    }, []);

    return (
        <React.Fragment>
            <Header />
            <Sidebar />
            <div className="main main-app p-3 p-lg-4">
                <Card>
                    <Card.Header>

                        <div className="d-flex justify-content-between  text-end">
                            <h4 className="card-title ">Commission Settings</h4>

                            {!isEditEnabled && <Button
                                variant={isEditEnabled ? 'danger' : 'primary'}
                                type="button"
                                className="ms-2"
                                onClick={() => { setIsEditEnabled(!isEditEnabled); }}
                            >
                                {isEditEnabled ? "Cancel" : "Edit"}
                            </Button>}

                        </div>
                    </Card.Header>
                    <Card.Body>
                        {pageLoading ? <div className="d-flex mt-5 justify-content-center align-content-center">
                            <BorderSpinner />
                        </div> : <Form onSubmit={handleSubmit}>
                            {Object.keys(formData).map((category) => (
                                <Row key={category} className="mb-4">
                                    <Col md={6}>
                                        {console.log(category, "category list")}
                                        <Form.Group controlId={`${category}-type`}>
                                            <Form.Label>{category.charAt(0).toUpperCase() + category.slice(1)} Commission Type</Form.Label>
                                            {category==='deposit' && <InfoComponent message={`Commission is applied on the user's first deposit.`} />}
                                            <Form.Control
                                                as="select"
                                                name="commissionType"
                                                value={formData[category].commissionType}
                                                onChange={(e) => handleChange(e, category)}
                                                disabled
                                                // disabled={!isEditEnabled || formData[category].disabled}
                                            >
                                                <option value="percentage">Percentage</option>
                                                <option value="flat">Flat</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group controlId={`${category}-rate`}>
                                            <Form.Label>{category.charAt(0).toUpperCase() + category.slice(1)} Commission Rate</Form.Label>
                                            <Form.Control
                                                type="number"
                                                name="commissionRate"
                                                placeholder="Enter rate"
                                                value={formData[category].commissionRate}
                                                onChange={(e) => handleChange(e, category)}
                                                isInvalid={!!errors[category]}
                                                disabled={!isEditEnabled || formData[category].disabled}
                                                min={0}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors[category]}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            ))}
                            {isEditEnabled && <div className="d-flex justify-content-center mt-5">
                                <Button disabled={isSubmitting} variant="primary" type="submit" className="mx-4">
                                    {isSubmitting ? 'Please wait...' : 'Submit'}
                                </Button>
                                <Button
                                    variant="danger"
                                    type="button"
                                    onClick={() => { setIsEditEnabled(false) }}
                                >
                                    Cancel
                                </Button>
                            </div>}
                        </Form>}
                    </Card.Body>
                </Card>
            </div>
        </React.Fragment>
    );
}
