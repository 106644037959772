import React, { useEffect, useState } from 'react';
import { Card, Container, Row, Col } from 'react-bootstrap';
import ApexCharts from 'react-apexcharts';
import { Activity, Timer, Users, DollarSign, Award, TrendingUp, Percent, IndianRupee } from 'lucide-react';
import { formatCurrency, getCurrencyFromTO } from '../../helper/CurrencyFormate';


const calculateTotalBetInINR = async (totalBetAmount=[]) => {
  let totalBetInINR = 0;

  for (let bet of totalBetAmount) {
    if (bet.currency === "INR") {
      totalBetInINR += bet.totalBetAmount; // If currency is INR, just add the amount
    } else {
      const convertedAmount = await getCurrencyFromTO(bet?.currency, "INR", bet?.totalBetAmount ||0);
      totalBetInINR += convertedAmount;
    }
  }

  return totalBetInINR;
};

const calculateAvgTicketPriceInINR = async (avgTicketPrice=[]) => {
  let totalConvertedPrice = 0;
  let totalCurrencies = 0;

  for (let price of avgTicketPrice) {
    if (price.currency === "INR") {
      totalConvertedPrice += price.avgTicketPrice;
    } else {
      const convertedPrice = await getCurrencyFromTO(price.currency, "INR", price?.avgTicketPrice ||0);
      totalConvertedPrice += convertedPrice;
    }
    totalCurrencies += 1;
  }

  return totalConvertedPrice / totalCurrencies;
};
const LotteryStats = ({ skin, data }) => {
  const [totalBetInINR, setTotalBetInINR] = useState(0);
  const [avgTicketPriceInINR, setAvgTicketPriceInINR] = useState(0);
 
  const mockData = {
    stats: {
      activeLotteries: 5,
      expiredLotteries: 12,
      totalBets: 1458,
      totalBetAmount: 45780,
      averageTicketPrice: 25,
      winningRate: 12.5,
      participantsCount: 890,
      totalPrizePool: 38000
    },
    trendData: [
      { date: '2024-01', bets: 250, amount: 6250, participants: 150 },
      { date: '2024-02', bets: 310, amount: 750, participants: 80 },
      { date: '2024-03', bets: 80, amount: 12000, participants: 280 },
      { date: '2024-04', bets: 420, amount: 10500, participants: 40 }
    ]
  };


  

  useEffect(() => {
    const calculateStats = async () => {
      // const totalBet = await calculateTotalBetInINR(data?.totalBetAmount);
      // const avgTicketPrice = await calculateAvgTicketPriceInINR(data?.avgTicketPrice);
      
      // setTotalBetInINR(totalBet);
      // setAvgTicketPriceInINR(avgTicketPrice);
      setTotalBetInINR(data?.totalBetAmount || 0);
      setAvgTicketPriceInINR(data?.avgTicketPrice || 0)
    };

    calculateStats();
  }, [data]);
  

  return (
    <div className="py-4">
      {/* <h1 className="text-2xl font-bold mb-6">Lottery Statistics Dashboard</h1> */}

      {/* Key Metrics Grid */}
      <Row className="mb-4">
        <Col md={3} className='my-2'>
          <Card className="text-center p-3">
            <Card.Body>
              <Card.Text>Active Lotteries</Card.Text>
              <Card.Title>{data?.activeLotteriesCount || 0}</Card.Title>
              <Activity className="h-8 w-8 text-green-500" />
            </Card.Body>
          </Card>
        </Col>
        <Col md={3} className='my-2'>
          <Card className="text-center p-3">
            <Card.Body>
              <Card.Text>Expired Lotteries</Card.Text>
              <Card.Title>{data?.expiredLotteriesCount || 0}</Card.Title>
              <Timer className="h-8 w-8 text-red-500" />
            </Card.Body>
          </Card>
        </Col>
        <Col md={3} className='my-2'>
          <Card className="text-center p-3">
            <Card.Body>
              <Card.Text>Total Bets</Card.Text>
              <Card.Title>{data?.totalBets || 0}</Card.Title>
              <Users className="h-8 w-8 text-blue-500" />
            </Card.Body>
          </Card>
        </Col>
        <Col md={3} className='my-2'>
          <Card className="text-center p-3">
            <Card.Body>
              <Card.Text>Total Bets Amount</Card.Text>
              <Card.Title>{formatCurrency(totalBetInINR,"INR") }</Card.Title>
              {/* <DollarSign className="h-8 w-8 text-yellow-500" /> */}
              <IndianRupee className="h-8 w-8 text-yellow-500" />

            </Card.Body>
          </Card>
        </Col>
      {/* </Row>

      
      <Row className="mb-4"> */}
        <Col md={3} className='my-2'>
          <Card className="text-center p-3">
            <Card.Body>
              <Card.Text>Avg Ticket Price</Card.Text>
              <Card.Title>{formatCurrency(avgTicketPriceInINR,'INR') }</Card.Title>
              <Award className="h-8 w-8 text-purple-500" />
            </Card.Body>
          </Card>
        </Col>
        <Col md={3} className='my-2'>
          <Card className="text-center p-3">
            <Card.Body>
              <Card.Text>Winning Rate</Card.Text>
              <Card.Title>{Number(data?.winningRate || 0)?.toFixed(2) || 0}%</Card.Title>
              <Percent className="h-8 w-8 text-indigo-500" />
            </Card.Body>
          </Card>
        </Col>
        <Col md={3} className='my-2'>
          <Card className="text-center p-3">
            <Card.Body>
              <Card.Text>Total Participants</Card.Text>
              <Card.Title>{data?.totalParticipants || 0}</Card.Title>
              <Users className="h-8 w-8 text-teal-500" />
            </Card.Body>
          </Card>
        </Col>
        
      </Row>

      
    </div>
  );
};


export default LotteryStats