import React, { useState } from "react";
import { AsyncPaginate } from "react-select-async-paginate";
import MerchantService from "../../service/api/merchant.service";
import { darkModeStyles, lightModeStyles } from "../../common/reactSelectStyles";
import { useSelector } from "react-redux";

export default function MerchantFilter({ setMerchantId, merchantId }) {
    const merchantService = MerchantService();
    const [value, setValue] = useState(null);
    const theme = useSelector((state) => state.ui.skin);

    const loadOptions = async (search, loadedOptions, { page }) => {
        try {
            const response = await merchantService.getMerchants({ 
                page, 
                limit: 25, 
                searchBy:search 
            }); // Adjust the API endpoint as needed
            
            if (response.code === 200) {
                return {
                    options: response.data?.docs.map((merchant) => ({
                        value: merchant.id,
                        label: merchant.name+`(${merchant?.email})`,
                    })),
                    hasMore: response.data.hasNextPage ,
                    additional: {
                        page: page + 1,
                    },
                };
            } else {
                throw new Error(response.message);
            }
        } catch (error) {
            console.error("Error fetching merchant list:", error);
            return {
                options: [],
                hasMore: false,
            };
        }
    };

    const handleChange = (selectedOption) => {
        setValue(selectedOption);
        setMerchantId(selectedOption ? selectedOption.value : "");
    };

    return (
        <div className="merchant-filter z-3">
            <AsyncPaginate
                value={value}
                loadOptions={loadOptions}
                onChange={handleChange}
                additional={{
                    page: 1,
                }}
                styles={theme === 'dark' ? darkModeStyles : lightModeStyles}
                
                placeholder="Select a Merchant"
                isClearable
            />
        </div>
    );
}
