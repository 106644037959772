import React, { useState, useEffect } from 'react';
import { Modal, Form, Button, Col } from 'react-bootstrap';
import { AsyncPaginate } from 'react-select-async-paginate';
import { darkModeStyles, lightModeStyles } from '../../common/reactSelectStyles';
import { BorderSpinner } from '../../common/Spinner';
import { toast } from 'react-toastify';
import ThemeService from '../../service/api/theme.service';
import MerchantService from '../../service/api/merchant.service';
import { useSelector } from 'react-redux';

const AssignTheme = ({ show, onClose, merchantId}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTheme, setSelectedTheme] = useState(null);
  const [errors, setErrors] = useState({});
  const theme = useSelector((state) => state.ui.skin);
  const themeService = ThemeService();
  const merchantService = MerchantService();

  useEffect(() => {
    if (show) {
      fetchCurrentTheme();
    } else {
      resetForm();
    }
  }, [show, merchantId]);

  const resetForm = () => {
    setSelectedTheme(null);
    setErrors({});
    setIsLoading(false);
  };

  const fetchCurrentTheme = async () => {
    try {
      setIsLoading(true);
      const response = await merchantService.getMerchantById(merchantId);
      
      if (response?.code === 200 && response.data) {
        setSelectedTheme({
          value: response.data.themeId?.id,
          label: response.data.themeId?.themeName
        });
        }
        console.log("Selected theme", response.data);
    } catch (error) {
      console.error('Error fetching current theme:', error);
    //   toast.error('Failed to load current theme');
    } finally {
      setIsLoading(false);
    }
  };

  const validateForm = () => {
    const newErrors = {};
    
    if (!selectedTheme) {
      newErrors.themeId = 'Please select a theme';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleAssignTheme = async () => {
    if (!validateForm()) return;

    try {
      setIsLoading(true);
      
      const payload = {
        themeId: selectedTheme.value
      };

      const response = await merchantService.updateMerchantStatus(merchantId,payload);
      
      if (response?.code === 200) {
        toast.success('Theme assigned successfully!');
        onClose();
      } else {
        throw new Error(response?.message || 'Failed to assign theme');
      }
    } catch (error) {
      console.error('Error assigning theme:', error);
      toast.error(error.message || 'Failed to assign theme');
    } finally {
      setIsLoading(false);
    }
  };

  const loadThemeOptions = async (search, loadedOptions, { page }) => {
    try {
      const response = await themeService.getTheme({
        page,
        limit: 25,
        searchBy: search
      });

      if (response?.code === 200) {
        return {
          options: response.data?.docs.map(theme => ({
            value: theme.id,
            label: theme.themeName
          })),
          hasMore: response.data.hasNextPage,
          additional: { page: page + 1 }
        };
      }
      
      return {
        options: [],
        hasMore: false
      };
    } catch (error) {
      console.error('Error fetching themes:', error);
      toast.error('Failed to load themes');
      return {
        options: [],
        hasMore: false
      };
    }
  };

  return (
    <Modal show={show} onHide={onClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Assign Theme to Merchant</Modal.Title>
      </Modal.Header>
      
      <Modal.Body>
        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center py-5">
            <BorderSpinner />
          </div>
        ) : (
          <Col md={12}>
            <Form.Group>
              <Form.Label>Select Theme</Form.Label>
              <AsyncPaginate
                value={selectedTheme}
                loadOptions={loadThemeOptions}
                onChange={setSelectedTheme}
                additional={{ page: 1 }}
                styles={theme === 'dark' ? darkModeStyles : lightModeStyles}
                placeholder="Select a theme..."
                isClearable
              />
              {errors.themeId && (
                <Form.Text className="text-danger">{errors.themeId}</Form.Text>
              )}
            </Form.Group>
          </Col>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button 
          variant="secondary" 
          onClick={onClose}
          disabled={isLoading}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={handleAssignTheme}
          disabled={isLoading}
        >
          {isLoading ? 'Assigning...' : 'Assign Theme'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AssignTheme;