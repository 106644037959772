import React, { useEffect, useRef, useState } from "react";
import Header from "../../layouts/Header";
import TableWithPagination from "../../common/table";
import useSkinSwitcher from "../../common/skin";
import lotteryService from "../../service/api/merchant.service";
import AuthService from "../../service/api/auth.service";
import Dropdown from "react-bootstrap/Dropdown";
import { Link, useNavigate } from "react-router-dom";
// import "./user.css";
import { Button, Card, Row, Col, Nav, ListGroup, Table, Badge } from "react-bootstrap";
import PageBreadcrumb from "../../common/PageBreadcrum";

import "cryptofont/css/cryptofont.css";
import Sidebar from "../../layouts/Sidebar";
import { toast } from "react-toastify";
import globalSwal from "../../common/swalService";
import LotteryService from "../../service/api/lottery.service";
import formatDateTime from "../../helper/DateFormate";
import { formatCurrency } from "../../helper/CurrencyFormate";
import TableFilter from "../../common/filter";
import UserService from "../../service/api/user.service ";
import { BorderSpinner } from "../../common/Spinner";

export default function TicketList() {
  const lotteryService = LotteryService();
  const authService = AuthService();
  const userService = UserService();
  const navigate = useNavigate();
  const [isHidden, setIsHidden] = useState(false);
  const [userList, setUserList] = useState([]);
  const [showPicker, setShowPicker] = useState(false);
  const { skin, setSkin } = useSkinSwitcher();
  const [isLoading, setIsLoading] = useState(true);
  const [lotteryTicketList, setLotteryTicketList] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    searchBy: '',
    limit: 25,
    totalDocs: 0,
    status: '',
    merchantId:'',
  });
  const [state, setState] = useState([
    {
      startDate: null,
      // endDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);
  const clearRange = () => {
    setState([{ startDate: null, endDate: null, key: "selection" }]);
    setShowPicker(false);
  };


  const [columns, setColumns] = useState(["lotteryId", "user", "merchantId", "createdAt", "numbers", "jackpotNumber", "lottery", "winningAmount", "hasWon"]);

  useEffect(() => {
    getList();
    // authService.getLoginData()
  }, [pagination.page,
  pagination.toDate,
  pagination.status,
  pagination.user,
    pagination.searchBy,
    pagination.limit,
  pagination.merchantId,
    // getList,
    state,]);

  // useEffect(() => {
  //   const getUser = async () => {
  //     try {
  //       const res = await userService.getUserList();
  //       if (res?.code === 200) {
  //         const formattedUserList = res.data.map((user) => ({
  //           label: `${user.name} (${user.username})`,
  //           value: user.id,
  //         }));
  //         setUserList(formattedUserList?.reverse());
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   getUser();
  // }, []);

  const getList = async () => {
    try {
      const res = await lotteryService.getTicketList({
        page: pagination?.page,
        limit: pagination?.limit,
        searchBy: pagination?.searchBy?.trim(),
        status: pagination?.status || '',
        user: pagination?.user ? pagination?.user : '',
        fromDate: pagination?.fromDate ? pagination?.fromDate : '',
        toDate: pagination?.toDate ? pagination?.toDate : '',
        merchantId: pagination?.merchantId
      });
      if (res?.code === 200) {
        console.log(res, 'Tickets')
        setLotteryTicketList(res?.data?.docs);
        setPagination((prevPagination) => ({
          ...prevPagination,
          pages: res.data.pages,
          totalDocs: res.data.totalDocs,
          limit: res.data.limit
        }));
      } else {
        console.error(res?.message);
        // toast.error(res?.message);
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false);
    }
  };

  const handleRangeChange = (ranges) => {
    setState([ranges.selection]);
  };
  // Define HTML for status badges
  const getStatusBadge = (status, row) => {

    if (row?.lottery?.winningNumbers?.length == 0) {
      return (
        <Badge bg={"info"} className="badge badge-seconday">
          Pending
        </Badge>
      );
    }
    if (status) {
      return (
        <Badge bg={"success"} className="badge badge-success">
          Won
        </Badge>
      );
    } else {
      return (
        <Badge bg={"pink"} className="badge badge-danger">
          Lost
        </Badge>
      );
    }

  };

  // Define HTML for action column
  const getActionDropdown = (id, status) => {
    console.log("id", id);
    return (
      <Dropdown>
        <Dropdown.Toggle as="span" className="custom-dropdown-toggle">
          <i className="ri-more-2-fill"></i>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item onClick={() => handleView(id)}>View</Dropdown.Item>
          <Dropdown.Item onClick={() => handleUpdate(id)}>Edit</Dropdown.Item>
          <Dropdown.Item onClick={() => handleStatusUpdate(id)}>
            {/* Activate/Deactivate */}
            {status === 1 ? "Deactivate" : "Activate"}
          </Dropdown.Item>
          <Dropdown.Item onClick={() => handleDelete(id)}>Delete</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const handleUpdate = (id) => {
    const state = { id: id };
    navigate(`/merchant-management/edit`, { state });
  };

  const handleView = (id) => {
    const state = { id: id };
    navigate(`/merchant-management/view`, { state });
  };

  const handleStatusUpdate = async (id) => {
    const merchant = lotteryTicketList.filter((item) => item.id === id);

    globalSwal.confirm({
      title: "Are you sure?",
      text: "Do you really want to change the current status?",
      onConfirm: async () => {


        const res = await lotteryService.updateLottery(merchant[0].id, {
          status: merchant[0].status == 1 ? 0 : 1,
        });
        if (res?.code === 200) {
          globalSwal.success({
            title: "Success!",
            text: "Status has been changed successfully.",
          });
          toast.success(res?.message || "Status updated successfully.");
          getList();
        } else {
          toast.error(res?.message || "Failed to update status.");
        }

      },
    });


  };

  const handleDelete = async (id) => {
    // const state = {id: id}
    // navigate(`/role-management/edit`, {state})
    globalSwal.confirm({
      title: "Are you sure?",
      text: "Do you really want to delete?",
      onConfirm: async () => {


        const res = await lotteryService.deleteLottery(id);
        if (res?.code === 200) {
          globalSwal.success({
            title: "Success!",
            text: "Merchant has been deleted successfully.",
          });
          toast.success(res?.message);
          getList();
          setPagination({ ...pagination, page: 1 })
        } else {
          toast.error(res?.message || "Failed to delete.");
        }

      },
    });
    // const role = lotteryTicketList.filter((item) => item.id === id);
    // const res = await lotteryService.deleteStaff(id);
    // if (res?.code == 200) {
    //   toast.success(res?.message);
    //   getList();
    //   setPagination({ ...pagination, page: 1 })
    // } else {
    //   console.error(res?.message);
    //   toast.error(res?.message);
    // }
  };

  // Map the columns to include HTML for status badges
  const columnsWithHTML = columns.map((column) => {
    if (column.toLowerCase() === "haswon") {
      return {
        header: "Status",
        accessor: column,
        Cell: ({ value, row }) => getStatusBadge(value, row), // Render status badge based on the value
      };
    }
    // else if (column.toLowerCase() === "selection_id") {
    //   return {
    //     header: "Selection Id",
    //     accessor: column,
    //     Cell: ({ value }) => {
    //       return value ? value : "--"
    //     }, // Render status badge based on the value
    //   };
    // }
    else if (column.toLowerCase() === "user") {
      return {
        header: "User",
        accessor: column,
        Cell: ({ value, row }) => {
          // console.log("value", value, column);
          return (
            <p className="text-truncate m-0 mw-50">
              <a className="cursor-pointer " onClick={() => {
                const state = { id: row?.userId };
                navigate(`/user-management/view`, { state })
              }}>{(value?.username)?.toUpperCase() || "N/A"}</a>
            </p>
          );
        }, // Render action dropdown based on the value
      };
    }
    else if (column.toLowerCase() === 'merchantid') {
      return {
        header: "Merchant",
        accessor: column,
        Cell: ({ value,row }) => {
          return (
            <p className="text-truncate m-0 mw-50">
              {value ? (<a
                className="cursor-pointer "
                onClick={() => {

                  const state = { id: value };
                  navigate(`/merchant-management/view`, { state });
                }}
              >
                {(row.merchant?.name)?.toUpperCase() || "N/A"}
              </a>) : "N/A"}
            </p>
          )
        }
      }
    }

    else if (column.toLowerCase() === "createdat") {
      return {
        header: "Purchase Date",
        accessor: column,
        Cell: ({ value }) => {
          // console.log("value", value, column);
          return (
            <span className="text-nowrap">
              {formatDateTime(value, 'gameBets') || "N/A"}
            </span>
          )
        }, // Render action dropdown based on the value
      };
    } else if (column.toLowerCase() === "lotteryid") {
      return {
        header: "Lottery",
        accessor: column,
        Cell: ({ value, row }) => {
          // console.log("value-transaction", value, column);
          // return value?.name || "N/A";
          return (<p className="text-truncate m-0 mw-50">
            <a className="cursor-pointer text-nowrap " onClick={() => {
              const state = { id: value };
              navigate(`/lottery/view`, { state })
            }}>{(row?.lottery?.name) || "N/A"}</a>
          </p>)
        }, // Render action dropdown based on the value
      };
    }
    // else if (column.toLowerCase() === "betamount") {
    //   return {
    //     header: "Bet",
    //     accessor: column,
    //     Cell: ({ value, row }) => {
    //       // console.log("row",row);
    //       return (
    //         <>
    //           {<>{formatCurrency(value || '0', row?.currencyId?.currencyCodes)}</>}

    //           {/* {(<> <span className="small pe-1">{row?.currencyId?.currencySymbols}</span>{Number(value)?.toFixed(2) || "0.00"}</>)} */}
    //         </>
    //       )
    //     }, // Render action dropdown based on the value
    //   };
    // }
    // else if (column.toLowerCase() === "openingbalance") {
    //     return {
    //         header: <span className="table-header-hover " title="Opening Balance">O.Balance</span>,
    //         accessor: column,
    //         Cell: ({ value, row }) => {
    //             // console.log("value", value, column);
    //             // return value?.toFixed(2) || "--";
    //             return (
    //                 <>
    //                     { <>{formatCurrency(value || '0', row?.currencyId?.currencyCodes)}</>}

    //                    </>
    //             )
    //         }, // Render action dropdown based on the value
    //     };
    // }
    // else if (column.toLowerCase() === "closingbalance") {
    //     return {
    //         header: <span className="table-header-hover " title="Closing Balance">C.Balance</span>,
    //         accessor: column,
    //         Cell: ({ value, row }) => {
    //             // console.log("value", value, column);
    //             return (
    //                 <>
    //                     { <>{formatCurrency(value || '0', row?.currencyId?.currencyCodes)}</>}

    //                     </>
    //             )
    //         }, // Render action dropdown based on the value
    //     };
    // }
    // ticketPriceInLotteryCurrency
    else if (column.toLowerCase() === "lottery") {
      return {
        header: "Ticket Price",
        accessor: column,
        Cell: ({ value, row }) => {
          // console.log("value", value, column);
          return (
            <>
              {<>{formatCurrency(value.ticketPrice || '0', value?.currency?.currencyCodes)}</>}

            </>
          )
        }, // Render action dropdown based on the value
      };
    }
    // ticketPriceInUserCurrency
    else if (column.toLowerCase() === "ticketpriceinusercurrency") {
      return {
        // headers: ({ row }) => {
        //   console.log(row,"headers data")
        // },
        header: "Ticket Price ()",
        // header: ({ data }) => {
        //   console.log(data,"headers data")
        //   // const currencySymbol = data?.currencyId?.currencySymbols || "";
        //   // return `Ticket Price (${currencySymbol})`;
        //   return 'Ticket price'
        // },
        accessor: column,
        Cell: ({ value, row }) => {
          // console.log("value", value, column);
          return (
            <>
              {<>{formatCurrency(value || '0', row?.lotteryId?.currencyId?.currencyCodes)}</>}

            </>
          )
        }, // Render action dropdown based on the value
      };
    }
    else if (column.toLowerCase() === "winningamount") {
      return {
        header: "Payout Amount",
        accessor: column,
        Cell: ({ value, row }) => {
          // console.log("value", value, column);
          return (
            <>
              {<>{formatCurrency(value || '0', row?.currency?.currencyCodes)}</>}

            </>
          )
        }, // Render action dropdown based on the value
      };
    }
    // else if (column.toLowerCase() === "payoutmultiplier") {
    //   return {
    //     header: "Cashout Multiplier",
    //     accessor: column,
    //     Cell: ({ value }) => {
    //       // console.log("value", value, column);
    //       return Number(value)?.toFixed(2) || "0.00";
    //     }, // Render action dropdown based on the value
    //   };
    // }

    else if (column.toLowerCase() === "numbers") {
      return {
        header: "Selections",
        accessor: column,
        Cell: ({ value, row }) => {
          console.log("Selections", value, row)
          // winningNumbers
          // Check if value is an array and has elements
          if (Array.isArray(value) && value.length > 0) {
            // Render each number with custom styling
            return (
              <div style={{ display: 'flex', gap: '8px', color: '#333', fontWeight: 'bold' }}>
                {value.map((num, index) => (
                  <span
                    key={index}
                    className="text-center"
                    style={{
                      minWidth:'40px',
                      padding: '4px 8px',
                      backgroundColor: row?.lotteryId?.winningNumbers?.includes(num) ? '#5CDA86' : '#f0f0f0',
                      borderRadius: '4px',
                      color: '#333',
                    }}
                  >
                    {num}
                  </span>
                ))}
              </div>
            );
          }
          // Fallback if no numbers are present
          return 'N/A';
        }, // Render action dropdown based on the value
      };
    }


    else if (column.toLowerCase() === "jackpotnumber") {
      return {
        header: "Jackpot Selections",
        accessor: column,
        Cell: ({ value, row }) => {

          return (
            <span
              // key={index}
              className="text-center"
              style={{
                minWidth:'40px',
                padding: '4px 8px',
                backgroundColor: row?.lotteryId?.jackpotNumber === value ? '#5CDA86' : '#f0f0f0',
                borderRadius: '4px',
                color: '#333',
                fontWeight: 'bold'
              }}
            >
              {value || 'N/A'}
            </span>
          )
          // return value || 'N/A';
        }, // Render action dropdown based on the value
      };
    }
    // else if (column.toLowerCase() === "id") {
    //     return {
    //         header: "Actions",
    //         accessor: column,
    //         Cell: ({ value }) => getActionDropdown(value) // Render action dropdown based on the value
    //     };
    // }
    // else {
    //   return column;
    // }
  });


  // const handleSearchChange = (event) => {
  //   const value = event.target.value.trim();
  //   if (value.length > 3) {
  //     setPagination({ ...pagination, searchBy: value });
  //   }
  //   if (value.length == 0) {
  //     setPagination({ ...pagination, searchBy: value });
  //   }
  // };

  const useDebounce = (func, delay) => {
    const debounceRef = useRef(null);

    return (...args) => {
      if (debounceRef.current) {
        clearTimeout(debounceRef.current);
      }
      debounceRef.current = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const handleSearchChange = (event) => {
    const value = event.target.value.trim();
    // if (value.length > 3 || value.length === 0) {
    // }
    setPagination((prev) => ({ ...prev, searchBy: value }));
  };

  const debouncedHandleSearchChange = useDebounce(handleSearchChange, 1000);

  // if (isLoading) {
  //   return <React.Fragment>
  //     <Header onSkin={setSkin} />
  //     <Sidebar />
  //     <div className="main main-app p-3 p-lg-4  ">
  //       <div className="d-flex mt-5 justify-content-center align-content-center">

  //         <BorderSpinner />
  //       </div>

  //     </div>
  //   </React.Fragment>
  // }
  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <Sidebar />
      <div className="main main-app p-3 p-lg-4">
        <Card>

          <div className=" align-items-center">
            {/* <Card.Header>

              <PageBreadcrumb title="Lottery Ticket List" />
             
            </Card.Header> */}
            <Card.Header>


              <div className="d-flex justify-content-between align-items-center">

                <PageBreadcrumb title="Lottery Ticket List" />
                <div>
                  <Button className="" onClick={() => setIsHidden(!isHidden)}>
                    Filter
                  </Button>
                </div>
              </div>
              {/* <h1>Transactions</h1> */}



              {/* </div> */}
              <div className={` betlist ${!isHidden ? 'd-none' : ''} `}>
                {/* Search Input */}
                <TableFilter
                  handleRangeChange={handleRangeChange}
                  handleSearchChange={handleSearchChange}
                  setPagination={setPagination}
                  setShowPicker={setShowPicker}
                  state={state}
                  tableType={"lottery-ticket-list"}
                  // tableType={"transaction"}

                  clearRange={clearRange}
                  userList={userList}
                  showPicker={showPicker}
                />
              </div>
            </Card.Header>
            <TableWithPagination
              data={[...lotteryTicketList]}
              itemsPerPage={pagination?.limit}
              columns={columnsWithHTML}
              currentPage={pagination?.page}
              totalPages1={pagination?.totalPages}
              totalData={pagination?.totalDocs}
              pagination={pagination}
              setPagination={setPagination}
              moduleId={15}
              tableType={'ticketList'}
              haveAdd={false}
              haveTableSearch={false}
              // redirectUrl={"/merchant-management/add"}
              handleSearchChange={debouncedHandleSearchChange}
            />
          </div>
        </Card>
      </div>
    </React.Fragment>
  );
}
