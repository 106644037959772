import React, { useEffect, useRef, useState } from "react";
import Header from "../../layouts/Header";
import TableWithPagination from "../../common/table";
import useSkinSwitcher from "../../common/skin";
import AuthService from "../../service/api/auth.service";
import Dropdown from "react-bootstrap/Dropdown";
import { Link, useNavigate } from "react-router-dom";
// import "./user.css";
import { Button, Card, Row, Col, Nav, ListGroup, Table, Badge, Modal, Form } from "react-bootstrap";
import PageBreadcrumb from "../../common/PageBreadcrum";

import "cryptofont/css/cryptofont.css";
import Sidebar from "../../layouts/Sidebar";
import { toast } from "react-toastify";
import globalSwal from "../../common/swalService";
import LotteryService from "../../service/api/lottery.service";
import formatDateTime from "../../helper/DateFormate";
import { format } from "date-fns";
import { BorderSpinner } from "../../common/Spinner";
import capitalizeFirstLetter from "../../helper/Capitalise";

export default function LotteryList() {
    const lotteryService = LotteryService();
    const authService = AuthService();
    const navigate = useNavigate();
    const { skin, setSkin } = useSkinSwitcher();
    const [isLoading, setIsLoading] = useState(true);
    const [lotteryList, setLotteryList] = useState([]);
    const [pagination, setPagination] = useState({
        page: 1,
        searchBy: '',
        limit: 25,
        totalDocs: 0
    });

    //   {
    //     "name": "BC Lottery",
    //     "type": "daily",
    //     "frequency": "daily",
    //     "nextDraw": "2024-10-16T14:10:00.000Z",
    //     "cutOffTime": "2024-10-23T14:10:00.000Z",
    //     "ticketPrice": 10,
    //     "prizePool": 10000,
    //     "prizeTiers": [
    //         {
    //             "matches": 5,
    //             "prizeAmount": 10000,
    //             "_id": "670f7d157b5b8daf03def1a9"
    //         }
    //     ],
    //     "payoutMethod": "instant",
    //     "isActive": true,
    //     "isDelete": 1,
    //     "createdAt": "2024-10-16T08:45:10.002Z",
    //     "id": "670f7d157b5b8daf03def1a8"
    // }
    const [columns, setColumns] = useState(["name", 'type', 'frequency', "nextDraw", "cutOffTime", "payoutMethod", "isActive","isStopped", 'isOriginal', "id"]);

    useEffect(() => {
        getList();
        authService.getLoginData()
    }, [pagination.searchBy, pagination.page,pagination.limit,]);

    const getList = async () => {
        try {
            const res = await lotteryService.getLotteryList({
                page: pagination?.page,
                limit: pagination?.limit,
                searchBy: pagination?.searchBy
            });
            if (res?.code === 200) {
                setLotteryList(res?.data?.docs);
                setPagination((prevPagination) => ({
                    ...prevPagination,
                    pages: res.data.pages,
                    totalDocs: res.data.totalDocs,
                    limit: res.data.limit
                }));
            } else {
                console.error(res?.message);
                // toast.error(res?.message);
            }

        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    // Define HTML for status badges
    const getStatusBadge = (status) => {
        if (status == 1) {
            return <Badge bg={'success'} className="badge badge-success">Active</Badge>;
        } else if (status === 2) {
            return <Badge bg={'pink'} className="badge badge-danger">Inactive</Badge>;
        }
        else if (status === 3) {
            return <Badge bg={'secondary'} className="badge badge-danger">Completed</Badge>;
        }

    };

    const getOriginalBadge = (status) => {
        if (status === true) {
            return <Badge bg={'primary'} className="badge badge-success">Original</Badge>;
        } else if (status === false) {
            return <Badge bg={'secondary'} className="badge badge-danger">Copied</Badge>;
        }
    };

    const getStartStopBadge = (status) => {
        if (!status) {
            // const text=value?'Stopped':'OnGoing'
            return <Badge bg={'success'} className="badge badge-success">OnGoing</Badge>;
        } else {
            return <Badge bg={'secondary'} className="badge badge-danger">Stopped</Badge>;
        }
    };

    // Define HTML for action column
    const getActionDropdown = (id, status, isStopped, data,moduleId) => {
        console.log("id and status", id, status, data);
        return (
            <Dropdown>
                <Dropdown.Toggle as="span" className="custom-dropdown-toggle">
                    <i className="ri-more-2-fill"></i>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item disabled={!authService.checkAccess(moduleId,'view')} onClick={() => handleView(id)}>View</Dropdown.Item>
                    <Dropdown.Item disabled={!authService.checkAccess(moduleId,'edit')} onClick={() => handleUpdate(id)}>Edit</Dropdown.Item>
                    {/* <Dropdown.Item onClick={() => handleStatusUpdate(id, !status)}>
                        
                        {status ? "Deactivate" : "Activate"}
                    </Dropdown.Item> */}
                    <Dropdown.Item   disabled={data?.winningNumbers?.length > 0 || !authService.checkAccess(moduleId,'edit')} onClick={() => handleUpdateWinners(id,data)}>

                        Make Winners
                    </Dropdown.Item>
                    <Dropdown.Item disabled={!authService.checkAccess(moduleId,'delete')} onClick={() => handleDelete(id)}>Delete</Dropdown.Item>
                    <Dropdown.Item disabled={!authService.checkAccess(moduleId,'edit')} onClick={() => handleStartStopUpdate(id, !isStopped, data?.endDate)}>{isStopped ? 'Start' : 'Stop'}</Dropdown.Item>

                </Dropdown.Menu>
            </Dropdown>
        );
    };

    const handleUpdate = (id) => {
        const state = { id: id };
        navigate(`/lottery/edit`, { state });
    };

    const handleView = (id) => {
        const state = { id: id };
        navigate(`/lottery/view`, { state });
    };

    const handleUpdateWinners = async (id,data) => {
        // const lottery = lotteryList.filter((item) => item.id === id);


        const state = { id: id ,lotteryDetails:data};
        navigate(`/lottery/make-winner`, { state });
    };

    // const handleStatusUpdate = async (id, status) => {
    //     // const lottery = lotteryList.filter((item) => item.id === id);

    //     globalSwal.confirm({
    //         title: "Are you sure?",
    //         text: "Do you really want to change the current status?",
    //         onConfirm: async () => {


    //             const res = await lotteryService.updateLottery(id, {
    //                 isActive: status,
    //             });
    //             if (res?.code === 200) {
    //                 globalSwal.success({
    //                     title: "Success!",
    //                     text: "Status has been changed successfully.",
    //                 });
    //                 toast.success(res?.message || "Status updated successfully.");
    //                 getList();
    //             } else {
    //                 toast.error(res?.message || "Failed to update status.");
    //             }

    //         },
    //     });


    // };

    // const handleStartStopUpdate = async (id, isStopped) => {
    //     // const lottery = lotteryList.filter((item) => item.id === id);

    //     globalSwal.confirm({
    //         title: "Are you sure?",
    //         text: "Do you really want to change the current status?",
    //         onConfirm: async () => {


    //             const res = await lotteryService.updateLottery(id, {
    //                 isStopped: isStopped,
    //             });
    //             if (res?.code === 200) {
    //                 globalSwal.success({
    //                     title: "Success!",
    //                     text: "Status has been changed successfully.",
    //                 });
    //                 toast.success(res?.message || "Status updated successfully.");
    //                 getList();
    //             } else {
    //                 toast.error(res?.message || "Failed to update status.");
    //             }

    //         },
    //     });


    // };

    const handleDelete = async (id) => {
        // const state = {id: id}
        // navigate(`/role-management/edit`, {state})
        globalSwal.confirm({
            title: "Are you sure?",
            text: "Do you really want to delete?",
            onConfirm: async () => {


                const res = await lotteryService.deleteLottery(id);
                if (res?.code === 200) {
                    globalSwal.success({
                        title: "Success!",
                        text: "Lottery has been deleted successfully.",
                    });
                    toast.success(res?.message);
                    getList();
                    setPagination({ ...pagination, page: 1 })
                } else {
                    toast.error(res?.message || "Failed to delete.");
                }

            },
        });

    };

    const [showModal, setShowModal] = useState(false);
    const [newEndDate, setNewEndDate] = useState("");
    const [lotteryId, setLotteryId] = useState(null); // To track the lottery being updated
    // const [lotteryList, setLotteryList] = useState([]); // Your existing state

    const handleStartStopUpdate = async (id, isStopped, currentEndDate) => {
        console.log(currentEndDate, "end date data")
        if ((!isStopped && new Date() > new Date(currentEndDate))) {
            // If isStopped is false and the end date has expired, open the modal
            setLotteryId(id);
            setShowModal(true);
        } else {
            // If no need for new end date, just toggle start/stop
            globalSwal.confirm({
                title: "Are you sure?",
                text: "Do you really want to change the current status?",
                onConfirm: async () => {
                    await updateLotteryStatus(id, { isStopped, endDate: currentEndDate });
                },
            });
        }
    };

    const updateLotteryStatus = async (id, payload) => {
        const res = await lotteryService.updateLottery(id, payload);
        if (res?.code === 200) {
            globalSwal.success({
                title: "Success!",
                text: "Status has been changed successfully.",
            });
            toast.success(res?.message || "Status updated successfully.");
            getList(); // Refresh the list after the update
        } else {
            toast.error(res?.message || "Failed to update status.");
        }
    };

    const handleModalConfirm = async () => {
        if (!newEndDate) {
            toast.error("Please select a new end date.");
            return;
        }
        // Update the lottery status with the new end date
        await updateLotteryStatus(lotteryId, {
            isStopped: false, // Starting the lottery
            endDate: newEndDate, // Adding the new end date
        });
        setShowModal(false);
        setNewEndDate(""); // Reset end date
    };

    const handleModalCancel = () => {
        setShowModal(false);
        setNewEndDate(""); // Reset end date
    };



    // Map the columns to include HTML for status badges
    const columnsWithHTML = columns.map((column) => {
        if (column.toLowerCase() === "isactive") {
            return {
                header: "Status",
                accessor: column,
                Cell: ({ value }) => getStatusBadge(value), // Render status badge based on the value
            };
        }
        else if (column.toLowerCase() === "isoriginal") {
            return {
                header: "Created Type",
                accessor: column,
                Cell: ({ value }) => getOriginalBadge(value), // Render status badge based on the value
            };
        }
        else if (column.toLowerCase() === "id") {
            return {
                header: " ",
                accessor: column,
                Cell: ({ value, row }) => getActionDropdown(value, row?.isActive, row?.isStopped, row,15), // Render action dropdown based on the value
            };
        }
        else if (column.toLowerCase() === "name") {
            return {
                header: "Name",
                accessor: column,
                Cell: ({ value }) => {
                    // console.log("value-transaction", value, column);
                    
                    return (
                        <span className="text-nowrap">
                            {capitalizeFirstLetter(value) || "N/A"}
                        </span>
                    )
                }, // Render action dropdown based on the value
            };
        }
            
            // isStopped
            else if (column.toLowerCase() === "isstopped") {
                return {
                    header: "Stopped Status",
                    accessor: column,
                    Cell: ({ value }) => {
                        // console.log("value-transaction", value, column);
                        
                        return getStartStopBadge(value) || "N/A";
                    }, // Render action dropdown based on the value
                };
            }
        else if (column.toLowerCase() === "type") {
            return {
                header: "Type",
                accessor: column,
                Cell: ({ value }) => {
                    // console.log("value-transaction", value, column);
                    return capitalizeFirstLetter(value) || "N/A";
                }, // Render action dropdown based on the value
            };
        }
        else if (column.toLowerCase() === "frequency") {
            return {
                header: "Frequency",
                accessor: column,
                Cell: ({ value }) => {
                    // console.log("value-transaction", value, column);
                    return capitalizeFirstLetter(value) || "N/A";
                }, // Render action dropdown based on the value
            };
        }
        else if (column.toLowerCase() === "nextdraw") {
            return {
                header: "Next Draw",
                accessor: column,
                Cell: ({ value }) => {

                    return (
                        <span className="text-nowrap">
                          {formatDateTime(value, 'gameBets') || "N/A"}
                        </span>
                      )
                  
                }, // Render action dropdown based on the value
            };
        }
        else if (column.toLowerCase() === "payoutmethod") {
            return {
                header: "Payout Method",
                accessor: column,
                Cell: ({ value }) => {
                    // console.log("value-transaction", value, column);
                    return capitalizeFirstLetter(value) || "N/A";
                }, // Render action dropdown based on the value
            };
        }

        else if (column.toLowerCase() === "cutofftime") {
            return {
                header: "Cut off Time",
                accessor: column,
                Cell: ({ value }) => {
                    // console.log("value-transaction", value, column);
                    return (
                        <span className="text-nowrap">
                          {formatDateTime(value, 'gameBets') || "N/A"}
                        </span>
                      )
                }, // Render action dropdown based on the value
            };
        }
        else {
            return column;
        }
    });

    // const handleSearchChange = (event) => {
    //   const value = event.target.value.trim();
    //   if (value.length > 3) {
    //     setPagination({ ...pagination, searchBy: value });
    //   }
    //   if (value.length == 0) {
    //     setPagination({ ...pagination, searchBy: value });
    //   }
    // };

    const useDebounce = (func, delay) => {
        const debounceRef = useRef(null);

        return (...args) => {
            if (debounceRef.current) {
                clearTimeout(debounceRef.current);
            }
            debounceRef.current = setTimeout(() => {
                func(...args);
            }, delay);
        };
    };

    const handleSearchChange = (event) => {
        const value = event.target.value.trim();
        // if (value.length > 3 || value.length === 0) {
        // }
        setPagination((prev) => ({ ...prev, searchBy: value }));
    };

    const debouncedHandleSearchChange = useDebounce(handleSearchChange, 1000);

    // if (isLoading) {
    //     return <React.Fragment>
    //         <Header onSkin={setSkin} />
    //         <Sidebar />
    //         <div className="main main-app p-3 p-lg-4  ">
    //             <div className="d-flex mt-5 justify-content-center align-content-center">

    //                 <BorderSpinner />
    //             </div>

    //         </div>
    //     </React.Fragment>
    // }
    return (
        <React.Fragment>
            <Header onSkin={setSkin} />
            <Sidebar />
            <div className="main main-app p-3 p-lg-4">

                <Card>

                    <div className=" align-items-center">
                        <Card.Header>

                            <PageBreadcrumb title="Lottery List" />

                        </Card.Header>
                        <TableWithPagination
                            data={[...lotteryList]}
                            itemsPerPage={pagination?.limit}
                            columns={columnsWithHTML}
                            currentPage={pagination?.page}
                            totalPages1={pagination?.totalPages}
                            totalData={pagination?.totalDocs}
                            pagination={pagination}
                            setPagination={setPagination}
                            moduleId={15}
                            tableType={"transaction"}
                            redirectUrl={"/lottery/add"}
                            handleSearchChange={debouncedHandleSearchChange}
                        />
                    </div>
                </Card>
            </div>

            {/* Your existing component code */}

            {/* Modal for selecting a new end date */}
            <Modal show={showModal} onHide={handleModalCancel}>
                <Modal.Header>
                    <Modal.Title>Set New End Date</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="newEndDate">
                            <Form.Label>Select New End Date</Form.Label>
                            <Form.Control
                                type="datetime-local"
                                value={newEndDate}
                                onChange={(e) => setNewEndDate(e.target.value)}
                                min={format(new Date(), "yyyy-MM-dd'T'HH:mm")}
                                required={true}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleModalCancel}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleModalConfirm}>
                        Update
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Your existing component code */}

        </React.Fragment>
    );
}
