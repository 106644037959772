

import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Sidebar from "../../layouts/Sidebar";
import Header from "../../layouts/Header";
import useSkinSwitcher from "../../common/skin";
import RoleService from "../../service/api/role.service";
import GameService from "../../service/api/game.service";
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import MerchantService from "../../service/api/merchant.service";
import ReactSelect from "react-select";
import { darkModeStyles2, lightModeStyles2 } from "../../common/reactSelectStyles";
import { useSelector } from "react-redux";
import InfoComponent from "../../common/Info";

const AddNewGame = () => {
  const theme = useSelector((state) => state.ui.skin);
  const { skin, setSkin } = useSkinSwitcher();
  const roleService = RoleService();
  const gameService = GameService();
  const merchantService = MerchantService();
  const navigate = useNavigate();
  const isLoading = useSelector((state) => state.state.loading);

  const [errors, setErrors] = useState({});
  const [imagePreview, setImagePreview] = useState(null);
  const [bgImagePreview, setBGImagePreview] = useState(null);
  const [selectedCategoryType, setSelectedCategoryType] = useState(null);

  const [formData, setFormData] = useState({
    name: "",
    gameDescription: "",
    category: "",
    image: null,
    bgImage: null,
    underMaintenance: false,
    launchId: "",
    iframeId: "",
  });

  const [categoryList, setCategoryList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedMaintenance, setSelectedMaintenance] = useState({ value: false, label: "No" });

  const maintenanceOptions = [
    { value: true, label: "Yes" },
    { value: false, label: "No" }
  ];

  useEffect(() => {
    const initializeData = async () => {
      await Promise.all([
        getCategories()
      ]);
    };
    initializeData();
  }, []);


  const getCategories = async () => {
    const res = await gameService.getCategoryList();
    if (res?.code === 200) {
      const formattedData = res.data.map((item) => ({
        value: item.id,
        label: item.name,
        type: item.categoryType || 'own' // assuming the category has a 'type' field indicating 'own' or 'third-party'
      }));
      setCategoryList(formattedData);
    }
  };

  const validateField = (name, value, categoryType = selectedCategoryType) => {
    switch (name) {
      case "name":
        if (!value.trim()) return "Name is required";
        if (!/^[a-zA-Z0-9\s]*$/.test(value)) return "Name should be alphanumeric";
        if (value.trim().length > 50) return "Name must not exceed 50 characters";
        return "";
      case "category":
        if (!value) return "Category is required";
        return "";
      case "image":
        if (!value) return "Image is required";
        return "";
        case "bgImage":
          if (categoryType === "third-party" && !value) return "Background image is required";
          return "";
      case "launchId":
        if (categoryType === "third-party") {
          if (!value) return "Launch ID is required";
          if (!/^\d+$/.test(value)) return "Launch ID must be numeric";
          if (value.length > 6) return "Launch ID must not exceed 6 digits";
        }
        return "";
      case "iframeId":
        if (categoryType === "third-party") {
          if (!value) return "iFrame ID is required";
          if (!/^\d+$/.test(value)) return "iFrame ID must be numeric";
          if (value.length > 6) return "iFrame ID must not exceed 6 digits";
        }
        return "";
      default:
        return "";
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    setErrors(prev => ({ ...prev, [name]: validateField(name, value) }));
  };

  const handleMaintenanceChange = (selected) => {
    setSelectedMaintenance(selected);
    setFormData(prev => ({ ...prev, underMaintenance: selected.value }));
  };

  const handleCategoryChange = (selected) => {
    const categoryType = selected?.type || 'own';
    setSelectedCategoryType(categoryType);
    setSelectedCategory(selected);
    setFormData(prev => ({
      ...prev,
      category: selected.value,
      // Clear launchId if switching from third-party to own
      // launchId: categoryType === 'third-party' ? prev.launchId : ''
    }));

    // Validate launch ID when category changes
    if (categoryType === 'third-party') {
      setErrors(prev => ({
        ...prev,
        category: "",
        launchId: validateField('launchId', formData.launchId, categoryType)
      }));
    } else {
      setErrors(prev => ({
        ...prev,
        category: "",
        launchId: ""
      }));
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (!["image/jpeg", "image/png"].includes(file.type)) {
        setErrors(prev => ({ ...prev, image: "Only JPG and PNG files are allowed" }));
        return;
      }
      if (file.size > 5 * 1024 * 1024) { // 5MB limit
        setErrors(prev => ({ ...prev, image: "Image size must be less than 5MB" }));
        return;
      }

      setFormData(prev => ({ ...prev, image: file }));
      setErrors(prev => ({ ...prev, image: "" }));

      const reader = new FileReader();
      reader.onload = () => setImagePreview(reader.result);
      reader.readAsDataURL(file);
    }
  };
  const handleBGImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (!["image/jpeg", "image/png"].includes(file.type)) {
        setErrors(prev => ({ ...prev, bgImage: "Only JPG and PNG files are allowed" }));
        return;
      }
      if (file.size > 1 * 1024 * 1024) { // 5MB limit
        setErrors(prev => ({ ...prev, bgImage: "Image size must be less than 1MB" }));
        return;
      }

      setFormData(prev => ({ ...prev, bgImage: file }));
      setErrors(prev => ({ ...prev, bgImage: "" }));

      const reader = new FileReader();
      reader.onload = () => setBGImagePreview(reader.result);
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate all fields
    const validationErrors = {
      name: validateField("name", formData.name),
      category: validateField("category", formData.category),
      image: validateField("image", formData.image),
      bgImage: validateField("bgImage", formData.bgImage),
      launchId: validateField("launchId", formData.launchId),
      iframeId: validateField("iframeId", formData.iframeId),

    };
   console.log(validationErrors,"validationError")
    const hasErrors = Object.values(validationErrors).some(error => error !== "");

    if (hasErrors) {
      setErrors(validationErrors);
      return;
    }

    try {
      const formDataToSend = new FormData();
      formDataToSend.append("name", formData.name.trim());
      formDataToSend.append("category", formData.category);
      formDataToSend.append("underMaintenance", formData.underMaintenance);
      formDataToSend.append("gameDescription", formData.gameDescription.trim());

      if (selectedCategoryType === 'third-party') {
        formDataToSend.append("launchId", formData.launchId);
        formDataToSend.append("iframeId", formData.iframeId);
      }


      // if (formData.merchant.length > 0) {
      //   formDataToSend.append("merchant", JSON.stringify(formData.merchant));
      // }

      if (formData.image) {
        formDataToSend.append("image", formData.image);
      }
      if (formData.bgImage) {
        formDataToSend.append("bgImage", formData.bgImage);
      }

      const res = await gameService.createGame(formDataToSend);

      if (res?.code === 200) {
        toast.success(res.message);
        navigate('/game-management');
      } else {
        toast.error(res?.message || 'Something went wrong');
      }
    } catch (error) {
      toast.error('An error occurred while creating the game');
      console.error('Error creating game:', error);
    }
  };

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <Sidebar />
      <div className="main main-app p-3 p-lg-4">
        <Card>
          <Card.Header>
            <h4 className="card-title w-100">Add New Game</h4>
          </Card.Header>
          <Card.Body>
            {imagePreview && (
              <div className="d-flex justify-content-center mb-4">
                <Col md={6}>
                  <img
                    src={imagePreview}
                    alt="Game preview"
                    className="img-fluid"
                    style={{ maxHeight: '300px', objectFit: 'contain' }}
                  />
                </Col>
              </div>
            )}

            <Form className="row" onSubmit={handleSubmit}>
              <Col md={4} className="mt-3">
                <Form.Group controlId="image">
                  <Form.Label>Banner Image</Form.Label>
                  <Form.Control
                    type="file"
                    accept="image/jpeg, image/png"
                    onChange={handleImageChange}
                    isInvalid={!!errors.image}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.image}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col md={4} className="mt-3">
                <Form.Group controlId="name">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter game name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    isInvalid={!!errors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col md={4} className="mt-3">
                <Form.Group controlId="maintenance">
                  <Form.Label>Under Maintenance</Form.Label>
                  <ReactSelect
                    value={selectedMaintenance}
                    options={maintenanceOptions}
                    styles={theme === 'dark' ? darkModeStyles2 : lightModeStyles2}
                    onChange={handleMaintenanceChange}
                  />
                </Form.Group>
              </Col>

              <Col md={4} className="mt-3">
                <Form.Group controlId="category">
                  <Form.Label>Category</Form.Label>
                  <ReactSelect
                    value={selectedCategory}
                    options={categoryList}
                    styles={theme === 'dark' ? darkModeStyles2 : lightModeStyles2}
                    onChange={handleCategoryChange}
                    isInvalid={!!errors.category}
                  />
                  {errors.category && (
                    <div className="text-danger small mt-1">{errors.category}</div>
                  )}
                </Form.Group>
              </Col>

              {selectedCategoryType === 'third-party' && (
                <Col md={4} className="mt-3">
                  <Form.Group controlId="launchId">
                    <Form.Label>Launch ID</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter launch ID"
                      name="launchId"
                      value={formData.launchId}
                      onChange={handleChange}
                      isInvalid={!!errors.launchId}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.launchId}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              )}

              {selectedCategoryType === 'third-party' && (
                <Col md={4} className="mt-3">
                  <Form.Group controlId="launchId">
                    <Form.Label>iFrame ID</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter iFrame ID"
                      name="iframeId"
                      value={formData.iframeId}
                      onChange={handleChange}
                      isInvalid={!!errors.iframeId}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.iframeId}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              )}
              {selectedCategoryType === 'third-party' && (<Col md={4} className="mt-3">
                <Form.Group>
                  <Form.Label>Game Background Image</Form.Label>
                  <InfoComponent message="The background image is used for enhancing the visual experience of the game. Max file size should be 1MB." />


                  <div className="image-upload-wrapper">

                    <Form.Control
                      type="file"
                      accept="image/jpeg, image/png"
                      onChange={handleBGImageChange}
                      isInvalid={!!errors.bgImage}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.bgImage}
                    </Form.Control.Feedback>
                    {bgImagePreview ? (
                      <img
                        src={bgImagePreview}
                        alt="Preview"
                        className="img-thumbnail"
                        style={{ width: "100px", height: "100px" }}
                      />
                    ) : (
                      <div className="image-placeholder">
                        Background Image Not Available
                      </div>
                    )}

                  </div>
                </Form.Group>
              </Col>)}


              <Col md={8} className="mt-3">
                <Form.Group controlId="gameDescription">
                  <Form.Label>Game Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="gameDescription"
                    placeholder="Enter game description"
                    value={formData.gameDescription}
                    onChange={handleChange}
                    rows={5}
                  />
                </Form.Group>
              </Col>

              <div className="d-flex justify-content-center mt-5">
                <Button
                  variant="primary"
                  type="submit"
                  className="mx-4"
                  disabled={isLoading}
                >
                  {isLoading ? "Please wait..." : "Submit"}
                </Button>
                <Button
                  variant="danger"
                  type="button"
                  onClick={() => navigate("/game-management")}
                >
                  Cancel
                </Button>
              </div>
            </Form>
          </Card.Body>
        </Card>
      </div>
    </React.Fragment>
  );
};

export default AddNewGame;

