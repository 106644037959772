import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Sidebar from "../../layouts/Sidebar";
import Header from "../../layouts/Header";
import useSkinSwitcher from "../../common/skin";
import RoleService from "../../service/api/role.service";
import gameService from "../../service/api/merchant.service";
import { toast } from "react-toastify";
import { Link, useNavigate, useLocation } from "react-router-dom";
import GameService from "../../service/api/game.service";
import { BorderSpinner } from "../../common/Spinner";

const ViewGameDetails = () => {
    const { skin, setSkin } = useSkinSwitcher();
    const gameService = GameService();
    const location = useLocation();
    const { id } = location.state;
    const [imagePreview, setImagePreview] = useState(null);
    const [bgImagePreview, setBgImagePreview] = useState(null);


    const [bannerImage, setBannerImage] = useState(null);
    const [pageLoading, setPageLoading] = useState(true);

    const navigate = useNavigate();
    const [selectedCategoryType, setSelectedCategoryType] = useState(null);

    const [formData, setFormData] = useState({
        name: "",
        gameDescription: "",
        category: "",
        image: null,
        underMaintenance: false,
        launchId: ""
    });

    const [categoryList, setCategoryList] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedMaintenance, setSelectedMaintenance] = useState(null);

    const maintenanceOptions = [
        { value: true, label: "Yes" },
        { value: false, label: "No" }
    ];
    useEffect(() => {
        // getGameDetails();
        getGameData();
    }, []);




    const getGameData = async () => {
        if (!id) {
            navigate('/game-management');
            return;
        }

        const res = await gameService.getGameById(id);
        if (res?.code === 200) {
            const gameData = res.data;
            setFormData(gameData);



            // Set selected category
            if (gameData.category) {

                setSelectedCategoryType(gameData.category.categoryType || 'own');
            }

            // Set image preview if exists
            if (gameData.bannerImage) {
                setImagePreview(gameData.bannerImage ? `${process.env.REACT_APP_IMAGE_URL}/${gameData.bannerImage}` : null);
                setBannerImage(gameData.bannerImage)

                // setImagePreview(gameData.image);
            }

            if (gameData.bgImage) {
                setBgImagePreview(gameData.bgImage ? `${process.env.REACT_APP_IMAGE_URL}/${gameData.bgImage}` : null);
                
            }
            setPageLoading(false);
        } else {
            toast.error(res?.message || 'Failed to fetch game data');
        }
    };



    return (
        <React.Fragment>
            <Header onSkin={setSkin} />
            <Sidebar />
            <div className="main main-app p-3 p-lg-4">
                <div className=" align-items-center mb-4">
                    <Card>
                        <Card.Header>
                            <div className="d-flex justify-content-between ">
                                <h4 className="card-title w-100">View Game</h4>
                                <Button variant="primary" type="button" onClick={() => navigate("/game-management")}>
                                    Back
                                </Button>
                            </div>

                        </Card.Header>
                        <Card.Body>
                            {pageLoading ? <div className="d-flex mt-5 justify-content-center align-content-center">
                                <BorderSpinner />
                            </div> : <>
                                {/* <div className="d-flex justify-content-center">
                                    <Col md={6}>
                                        <img src={bannerImage ? imagePreview : '../images/card-image-placeholder.png'} style={{ maxWidth: '100%' }} />
                                    </Col>
                                </div>
                                {selectedCategoryType === 'third-party' && bgImagePreview && (
                                    <div className="d-flex justify-content-center mt-3">
                                        <Col md={6}>
                                            <h6 className="text-center">Background Image</h6>
                                            <img src={bgImagePreview} alt="Background Preview" style={{ maxWidth: '100%' }} />
                                        </Col>
                                    </div>
                                )} */}
                                <div className="d-flex justify-content-between gap-4 mt-3">
                                    <Col md={6} className="text-center">
                                        <h6 className="text-start">Banner Image</h6>
                                        <img
                                            src={bannerImage ? imagePreview : '../images/card-image-placeholder.png'}
                                            alt="Banner Preview"
                                            style={{ width: "100%", maxHeight: "300px", objectFit: "contain", borderRadius: "8px" }}
                                        />
                                    </Col>
                                    {selectedCategoryType === "third-party"  && (
                                        <Col md={6} className="text-center">
                                            <h6 className="text-start">Background Image</h6>
                                            <img
                                                src={bgImagePreview?bgImagePreview:'../images/card-image-placeholder.png'}
                                                alt="Background Preview"
                                                style={{ width: "100%", maxHeight: "300px", objectFit: "contain", borderRadius: "8px" }}
                                            />
                                        </Col>
                                    )}
                                </div>
                                <Form className="row" >

                                    <Col md={4} className="mt-3">
                                        <Form.Group >
                                            <Form.Label>Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter name"
                                                name="name"
                                                value={formData.name || ""}


                                                disabled={true}
                                            />

                                        </Form.Group>
                                    </Col>

                                    <Col md={4} className="mt-3">
                                        <Form.Group >
                                            <Form.Label>Game Id</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter phone"
                                                name="phone"
                                                value={formData.gameUnique || ""}

                                                disabled={true}
                                            />

                                        </Form.Group>
                                    </Col>
                                    <Col md={4} className="mt-3">
                                        <Form.Group >
                                            <Form.Label>Under Maintenance</Form.Label>
                                            <Form.Control
                                                type="email"
                                                // placeholder="Enter email"
                                                name="email"
                                                value={formData.value && formData.value ? 'Yes' : 'No'}

                                                disabled={true}
                                            />

                                        </Form.Group>
                                    </Col>
                                    {/* <Col md={4} className="mt-3">
                                    <Form.Group >
                                        <Form.Label>Merchant</Form.Label>
                                        <Form.Control
                                            
                                            as="select"
                                            name="merchantName"
                                            value={formData.merchant ? formData.merchant.name : ""}
                                            disabled={true}
                                        >
                                            <option>{formData.merchant ? formData.merchant.name : ""}</option>
                                        </Form.Control>

                                    </Form.Group>
                                </Col> */}
                                    <Col md={4} className="mt-3">
                                        <Form.Group >
                                            <Form.Label>Category</Form.Label>
                                            <Form.Control
                                                as="select"
                                                name="merchantName"
                                                value={formData.category}
                                                disabled={true}
                                            >
                                                <option>{formData.category ? formData?.category?.name : ""}</option>
                                            </Form.Control>

                                        </Form.Group>
                                    </Col>
                                    {selectedCategoryType === 'third-party' && (
                                        <Col md={4} className="mt-3">
                                            <Form.Group controlId="launchId">
                                                <Form.Label>Launch ID</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter launch ID"
                                                    name="launchId"
                                                    value={formData.launchId}
                                                    disabled

                                                />

                                            </Form.Group>
                                        </Col>
                                        )}
                                        {selectedCategoryType === 'third-party' && (
                                        <Col md={4} className="mt-3">
                                            <Form.Group controlId="launchId">
                                                <Form.Label>iFrame ID</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter launch ID"
                                                    name="iframeId"
                                                    value={formData.iframeId}
                                                    disabled

                                                />

                                            </Form.Group>
                                        </Col>
                                    )}
                                    <Col md={4} className="mt-3">
                                        <Form.Group controlId="gameDescription">
                                            <Form.Label>Game Description</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                name="gameDescription"
                                                placeholder="Enter game description"
                                                value={formData.gameDescription}
                                                rows={5} // Adjust the number of rows to control the height of the textarea
                                                disabled={true}
                                            />
                                            {/* <Form.Control.Feedback type="invalid">
                    {errors.gameDescription !== "" && showError && "Game description is required"}
                  </Form.Control.Feedback> */}
                                        </Form.Group>
                                    </Col>


                                </Form>
                            </>}

                        </Card.Body>
                    </Card>
                </div>
            </div>
        </React.Fragment>
    );
};

export default ViewGameDetails;
