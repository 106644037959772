import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Sidebar from "../../layouts/Sidebar";
import Header from "../../layouts/Header";
import useSkinSwitcher from "../../common/skin";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ReactSelect from "react-select";
import { darkModeStyles2, lightModeStyles2 } from "../../common/reactSelectStyles";
import { useSelector } from "react-redux";
import GameService from "../../service/api/game.service";
import GameSettingService from "../../service/api/game-setting.service";

const AddGameCategory = () => {
    const theme = useSelector((state) => state.ui.skin);
    const { skin, setSkin } = useSkinSwitcher();
    const gameService = GameService();
    const gameSettingService = GameSettingService();
    const navigate = useNavigate();
    const isLoading = useSelector((state) => state.state.loading);

    const [errors, setErrors] = useState({});
    const [imagePreview, setImagePreview] = useState(null);
    const [formData, setFormData] = useState({
        name: "",
        undermaintainance: false,
        games: [],
        image: null,
        category: "own",
        operatorId: ""
    });

    const [gameList, setGameList] = useState([]);
    const [selectedResult, setSelectedResult] = useState([]);
    const [underMaintainanceOptions] = useState([
        { value: true, label: "Yes" },
        { value: false, label: "No" },
    ]);
    const [categoryOptions] = useState([
        { value: "owned", label: "Owned" },
        { value: "third-party", label: "Third Party" },
    ]);
    const [selectedCategory, setSelectedCategory] = useState({ value: "owned", label: "Owned" });
    const [underMaintainance, setUnderMaintainance] = useState([]);

    useEffect(() => {
        getGameList();
    }, []);

    const getGameList = async () => {
        const res = await gameSettingService.getGameListDropdown();
        if (res?.code === 200) {
            const data = res.data
                .filter((item) => item.category == null || item.category === "")
                .map((item) => ({ value: item.id, label: item.name }));
            setGameList(data);
        }
    };

    const handleSelectChange = (items) => {
        const valuesArray = items ? items.map((item) => item.value) : [];
        setFormData((prev) => ({
            ...prev,
            games: valuesArray,
        }));
        setSelectedResult(items);
    };

    const handleMaintainanceSelectChange = (items) => {
        setFormData((prev) => ({
            ...prev,
            undermaintainance: items.value,
        }));
        setUnderMaintainance(items);
    };

    const handleCategoryChange = (item) => {
        setFormData((prev) => ({
            ...prev,
            category: item.value,
            operatorId: item.value === "owned" ? "" : prev.operatorId
        }));
        setSelectedCategory(item);
        if (item.value === "owned") {
            setErrors((prev) => ({ ...prev, operatorId: "" }));
        }
    };

    const handleOperatorIdChange = (e) => {
        const value = e.target.value;
        if (value === "" || /^\d{0,4}$/.test(value)) {
            setFormData((prev) => ({
                ...prev,
                operatorId: value
            }));
            setErrors((prev) => ({ ...prev, operatorId: "" }));
        }
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (!["image/jpeg", "image/png"].includes(file.type)) {
                setErrors((prev) => ({
                    ...prev,
                    image: "Only JPG and PNG files are allowed.",
                }));
                return;
            }
            if (file.size > 500 * 1024) {
                setErrors((prev) => ({
                    ...prev,
                    image: "Image size must be less than 100KB.",
                }));
                return;
            }
            setErrors((prev) => ({ ...prev, image: "" }));
            setFormData((prev) => ({ ...prev, image: file }));

            const reader = new FileReader();
            reader.onload = () => setImagePreview(reader.result);
            reader.readAsDataURL(file);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = {};
        if (!formData.name.trim()) {
            validationErrors.name = "Name is required";
        } else if (!/^[a-zA-Z0-9\s]*$/.test(formData.name)) {
            validationErrors.name = "Name should be alphanumeric";
        }
        if (!formData.image) {
            validationErrors.image = "Image is required.";
        }
        if (formData.category === "third-party") {
            if (!formData.operatorId) {
                validationErrors.operatorId = "Operator ID is required";
            } else if (!/^\d{4}$/.test(formData.operatorId)) {
                validationErrors.operatorId = "Operator ID must be exactly 4 digits";
            }
        }

        if (Object.keys(validationErrors).length === 0) {
            const formDataToSend = new FormData();
            formDataToSend.append("undermaintainance", formData.undermaintainance);
            formDataToSend.append("name", formData.name);
            formDataToSend.append("categoryType", formData.category || 'owned');
            if (formData.category === "third-party") {
                formDataToSend.append("operatorId", formData.operatorId);
            }
            formDataToSend.append("games", JSON.stringify(formData.games));
            if (formData.image) {
                formDataToSend.append("image", formData.image);
            }

            const res = await gameService.createCategory(formDataToSend);
            if (res?.code === 200) {
                toast.success(res?.message);
                navigate("/game-management-category");
            } else {
                toast.error(res?.message);
            }
        } else {
            setErrors(validationErrors);
        }
    };

    return (
        <React.Fragment>
            <Header onSkin={setSkin} />
            <Sidebar />
            <div className="main main-app p-3 p-lg-4">
                <Card>
                    <Card.Header>
                        <h4 className="card-title w-100">Add New Category</h4>
                    </Card.Header>
                    <Card.Body>
                        <Form className="row" onSubmit={handleSubmit}>
                            <Col md={4} className="mt-3">
                                <Form.Group controlId="name">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="name"
                                        value={formData.name}
                                        onChange={(e) =>{
                                            setFormData((prev) => ({
                                                ...prev,
                                                name: e.target.value,
                                            }))
                                            setErrors({ ...errors, [e.target.name]: "" })}
                                        }
                                        isInvalid={!!errors.name}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.name}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col md={4} className="mt-3">
                                <Form.Group>
                                    <Form.Label>Category Type</Form.Label>
                                    <ReactSelect
                                        styles={
                                            theme === "dark" ? darkModeStyles2 : lightModeStyles2
                                        }
                                        value={selectedCategory}
                                        options={categoryOptions}
                                        onChange={handleCategoryChange}
                                    />
                                </Form.Group>
                            </Col>
                            {formData.category === "third-party" && (
                                <Col md={4} className="mt-3">
                                    <Form.Group controlId="operatorId">
                                        <Form.Label>Operator ID</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={formData.operatorId}
                                            onChange={handleOperatorIdChange}
                                            isInvalid={!!errors.operatorId}
                                            placeholder="Enter 4-digit operator ID"
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.operatorId}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            )}
                            <Col md={4} className="mt-3">
                                <Form.Group>
                                    <Form.Label>Under Maintenance</Form.Label>
                                    <ReactSelect
                                        styles={
                                            theme === "dark" ? darkModeStyles2 : lightModeStyles2
                                        }
                                        value={underMaintainance}
                                        options={underMaintainanceOptions}
                                        onChange={handleMaintainanceSelectChange}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={8} className="mt-3">
                                <Form.Group>
                                    <Form.Label>Games</Form.Label>
                                    <ReactSelect
                                        styles={
                                            theme === "dark" ? darkModeStyles2 : lightModeStyles2
                                        }
                                        isMulti
                                        value={selectedResult}
                                        options={gameList}
                                        onChange={handleSelectChange}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6} className="mt-3">
                                <Form.Group>
                                    <Form.Label>Image</Form.Label>
                                    <div className="image-upload-wrapper">
                                        {imagePreview ? (
                                            <img
                                                src={imagePreview}
                                                alt="Preview"
                                                className="img-thumbnail"
                                                style={{ width: "100px", height: "100px" }}
                                            />
                                        ) : (
                                            <div className="image-placeholder">
                                                Picture Not Available
                                            </div>
                                        )}
                                        <Form.Control
                                            type="file"
                                            accept="image/jpeg, image/png"
                                            onChange={handleImageChange}
                                            isInvalid={!!errors.image}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.image}
                                        </Form.Control.Feedback>
                                    </div>
                                </Form.Group>
                            </Col>
                            <div className="d-flex justify-content-start mt-5">
                                <Button
                                    disabled={isLoading}
                                    variant="primary"
                                    type="submit"
                                    className="mx-4"
                                >
                                    {isLoading ? "Please Wait.." : "Submit"}
                                </Button>
                                <Button
                                    variant="danger"
                                    type="button"
                                    onClick={() => navigate("/game-management-category")}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </Form>
                    </Card.Body>
                </Card>
            </div>
        </React.Fragment>
    );
};

export default AddGameCategory;











// import React, { useState, useEffect } from "react";
// import Form from "react-bootstrap/Form";
// import Select from "react-select";
// import Button from "react-bootstrap/Button";
// import Col from "react-bootstrap/Col";
// import Card from "react-bootstrap/Card";
// import Sidebar from "../../layouts/Sidebar";
// import Header from "../../layouts/Header";
// import useSkinSwitcher from "../../common/skin";
// import { toast } from "react-toastify";
// import { useNavigate } from "react-router-dom";
// import ReactSelect from "react-select";
// import { darkModeStyles2, lightModeStyles2 } from "../../common/reactSelectStyles";
// import { useSelector } from "react-redux";
// import GameService from "../../service/api/game.service";
// import GameSettingService from "../../service/api/game-setting.service";

// const AddGameCategory = () => {
//     const theme = useSelector((state) => state.ui.skin);
//     const { skin, setSkin } = useSkinSwitcher();
//     const gameService = GameService();
//     const gameSettingService = GameSettingService();
//     const navigate = useNavigate();
//     const isLoading = useSelector((state) => state.state.loading);

//     const [errors, setErrors] = useState({});
//     const [imagePreview, setImagePreview] = useState(null);
//     const [formData, setFormData] = useState({
//         name: "",
//         undermaintainance: false,
//         games: [],
//         image: null,
//         category:'own'
//     });

//     const [gameList, setGameList] = useState([]);
//     const [selectedResult, setSelectedResult] = useState([]);
//     const [underMaintainanceOptions] = useState([
//         { value: true, label: "Yes" },
//         { value: false, label: "No" },
//     ]);
//     const [underMaintainance, setUnderMaintainance] = useState([]);

//     useEffect(() => {
//         getGameList();
//     }, []);

//     const getGameList = async () => {
//         const res = await gameSettingService.getGameListDropdown();
//         if (res?.code === 200) {
//             const data = res.data
//                 .filter((item) => item.category == null || item.category === "")
//                 .map((item) => ({ value: item.id, label: item.name }));
//             setGameList(data);
//         }
//     };

//     const handleSelectChange = (items) => {
//         const valuesArray = items ? items.map((item) => item.value) : [];
//         setFormData((prev) => ({
//             ...prev,
//             games: valuesArray,
//         }));
//         setSelectedResult(items);
//     };

//     const handleMaintainanceSelectChange = (items) => {
//         setFormData((prev) => ({
//             ...prev,
//             undermaintainance: items.value,
//         }));
//         setUnderMaintainance(items);
//     };

//     const handleImageChange = (e) => {
//         const file = e.target.files[0];
//         if (file) {
//             if (!["image/jpeg", "image/png"].includes(file.type)) {
//                 setErrors((prev) => ({
//                     ...prev,
//                     image: "Only JPG and PNG files are allowed.",
//                 }));
//                 return;
//             }
//             if (file.size > 500 * 1024) {
//                 setErrors((prev) => ({
//                     ...prev,
//                     image: "Image size must be less than 100KB.",
//                 }));
//                 return;
//             }
//             setErrors((prev) => ({ ...prev, image: "" }));
//             setFormData((prev) => ({ ...prev, image: file }));

//             const reader = new FileReader();
//             reader.onload = () => setImagePreview(reader.result);
//             reader.readAsDataURL(file);
//         }
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         console.log(formData,"form data")
//         const validationErrors = {};
//         if (!formData.name.trim()) {
//             validationErrors.name = "Name is required";
//         } else if (!/^[a-zA-Z0-9\s]*$/.test(formData.name)) {
//             validationErrors.name = "Name should be alphanumeric";
//         }
//         if (!formData.image) {
//             validationErrors.image = "Image is required.";
//         }
//         if (Object.keys(validationErrors).length === 0) {
//             const formDataToSend = new FormData();
//             formDataToSend.append("undermaintainance", formData.undermaintainance);
//             formDataToSend.append("name", formData.name);

//             formDataToSend.append("games", JSON.stringify(formData.games));
//             if (formData.image) {
//                 formDataToSend.append("image", formData.image);
                
//             }

//             const res = await gameService.createCategory(formDataToSend);
//             if (res?.code === 200) {
//                 toast.success(res?.message);
//                 navigate("/game-management-category");
//             } else {
//                 toast.error(res?.message);
//             }
//         } else {
//             setErrors(validationErrors);
//         }
//     };

//     return (
//         <React.Fragment>
//             <Header onSkin={setSkin} />
//             <Sidebar />
//             <div className="main main-app p-3 p-lg-4">
//                 <Card>
//                     <Card.Header>
//                         <h4 className="card-title w-100">Add New Category</h4>
//                     </Card.Header>
//                     <Card.Body>
//                         <Form className="row" onSubmit={handleSubmit}>
//                             <Col md={4} className="mt-3">
//                                 <Form.Group controlId="name">
//                                     <Form.Label>Name</Form.Label>
//                                     <Form.Control
//                                         type="text"
//                                         name="name"
//                                         value={formData.name}
//                                         onChange={(e) =>{
//                                             setFormData((prev) => ({
//                                                 ...prev,
//                                                 name: e.target.value,
//                                             }))
//                                             setErrors({ ...errors, [e.target.name]: "" })}
//                                         }
//                                         isInvalid={!!errors.name}
//                                     />
//                                     <Form.Control.Feedback type="invalid">
//                                         {errors.name}
//                                     </Form.Control.Feedback>
//                                 </Form.Group>
//                             </Col>
//                             <Col md={4} className="mt-3">
//                                 <Form.Group>
//                                     <Form.Label>Under Maintenance</Form.Label>
//                                     <ReactSelect
//                                         styles={
//                                             theme === "dark" ? darkModeStyles2 : lightModeStyles2
//                                         }
//                                         value={underMaintainance}
//                                         options={underMaintainanceOptions}
//                                         onChange={handleMaintainanceSelectChange}
//                                     />
//                                 </Form.Group>
//                             </Col>
//                             <Col md={8} className="mt-3">
//                                 <Form.Group>
//                                     <Form.Label>Games</Form.Label>
//                                     <ReactSelect
//                                         styles={
//                                             theme === "dark" ? darkModeStyles2 : lightModeStyles2
//                                         }
//                                         isMulti
//                                         value={selectedResult}
//                                         options={gameList}
//                                         onChange={handleSelectChange}
//                                     />
//                                 </Form.Group>
//                             </Col>
//                             <Col md={6} className="mt-3">
//                                 <Form.Group>
//                                     <Form.Label>Image</Form.Label>
//                                     <div className="image-upload-wrapper">
//                                         {imagePreview ? (
//                                             <img
//                                                 src={imagePreview}
//                                                 alt="Preview"
//                                                 className="img-thumbnail"
//                                                 style={{ width: "100px", height: "100px" }}
//                                             />
//                                         ) : (
//                                             <div className="image-placeholder">
//                                                 Picture Not Available
//                                             </div>
//                                         )}
//                                         <Form.Control
//                                             type="file"
//                                             accept="image/jpeg, image/png"
//                                             onChange={handleImageChange}
//                                             isInvalid={!!errors.image}
//                                         />
//                                         <Form.Control.Feedback type="invalid">
//                                             {errors.image}
//                                         </Form.Control.Feedback>
//                                     </div>
//                                 </Form.Group>
//                             </Col>
//                             <div className="d-flex justify-content-center mt-5">
//                                 <Button
//                                     disabled={isLoading}
//                                     variant="primary"
//                                     type="submit"
//                                     className="mx-4"
//                                 >
//                                     {isLoading ? "Please Wait.." : "Submit"}
//                                 </Button>
//                                 <Button
//                                     variant="danger"
//                                     type="button"
//                                     onClick={() => navigate("/game-management-category")}
//                                 >
//                                     Cancel
//                                 </Button>
//                             </div>
//                         </Form>
//                     </Card.Body>
//                 </Card>
//             </div>
//         </React.Fragment>
//     );
// };

// export default AddGameCategory;
