import React, { useEffect, useRef, useState } from "react";
import Header from "../../layouts/Header";
import TableWithPagination from "../../common/table";
import useSkinSwitcher from "../../common/skin";
import AuthService from "../../service/api/auth.service";
import Dropdown from "react-bootstrap/Dropdown";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, Card, Badge, Modal, Form } from "react-bootstrap";
import PageBreadcrumb from "../../common/PageBreadcrum";

import "cryptofont/css/cryptofont.css";
import Sidebar from "../../layouts/Sidebar";
import { toast } from "react-toastify";

import globalSwal from "../../common/swalService";
import formatDateTime from "../../helper/DateFormate";

import { BorderSpinner } from "../../common/Spinner";
// import affiliateService from "../../service/api/exposure.service";
import TableFilter from "../../common/filter";
import ReferralService from "../../service/api/referral.service";
import AffiliateService from "../../service/api/affiliate.service";
import { formatCurrency } from "../../helper/CurrencyFormate";
import capitalizeFirstLetter from "../../helper/Capitalise";

export default function ReferralsList() {
    const affiliateService = AffiliateService();
    // const affiliateService = affiliateService();
    const authService = AuthService();

    const navigate = useNavigate();
    const { skin, setSkin } = useSkinSwitcher();
    const [ipwStatus, setIpwStatus] = useState(false);
    const [isIpwStatusLoaded, setIsIpwStatusLoaded] = useState(false); // Loading state
    const location = useLocation();
    const { id } = location.state;
    const [exposureList, setExposureList] = useState([]);
    const [addTo, setAddTo] = useState('admin');
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isHidden, setIsHidden] = useState(false);
    const [showPicker, setShowPicker] = useState(false);
    const [modalTypeAndId, setModalTypeAndId] = useState({
        type: "",
        id: "",
    });


    const [pagination, setPagination] = useState({
        page: 1,
        searchBy: "",
        limit: 25,
        merchantId: "",
        gameId: "",
    });


    const [columns] = useState([
        "name",
        "username",
        // "merchantId",
        "email",
        "phone",
        "depositAmount",
        "conversionType",
        "commission",
        "commissionRate",
        "commissionType",
        // "appliedTo",
        "createdAt",
        
    ]);

    useEffect(() => {
        authService.getLoginData();
    }, []);

    useEffect(() => {
        getList();
    }, [pagination.page,pagination.limit, pagination.searchBy, pagination.merchantId, pagination.gameId]);










    const getList = async () => {
        try {
            const res = await affiliateService.getReferralsListByAffiliateId(id, {
                page: pagination?.page,
                limit: pagination?.limit,
                searchBy: pagination?.searchBy,
                merchantId: pagination?.merchantId || '',
                gameId: pagination?.gameId || '',
            });
            if (res?.code === 200) {
                setExposureList(res?.data?.docs);
                setPagination((prevPagination) => ({
                    ...prevPagination,
                    pages: res.data.pages,
                    totalDocs: res.data.totalDocs,
                    limit: res.data.limit,
                }));
            } else {
                // console.error(res?.message);
                throw new Error(res?.message || "Failed to fetch IP whitelist.");
            }
        } catch (error) {
            console.error("Error fetching IP whitelist:", error);
            // toast.error("An unexpected error occurred while fetching IP whitelist.");
        } finally {
            setLoading(false);
        }
    };




    const getActionDropdown = (id, moduleId) => {
        // console.log(row,"action");
        return (
            <Dropdown>
                <Dropdown.Toggle as="span" className="custom-dropdown-toggle">
                    <i className="ri-more-2-fill"></i>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item disabled={!authService.checkAccess(moduleId, 'view')} onClick={() => handleView(id)}>View</Dropdown.Item>
                    <Dropdown.Item disabled={!authService.checkAccess(moduleId, 'edit')} onClick={() => handleUpdate(id)}>Edit</Dropdown.Item>
                    <Dropdown.Item disabled={!authService.checkAccess(moduleId, 'delete')} onClick={() => handleDelete(id)}>Delete</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        );
    };

    const handleView = (id) => {
        const state = { id: id };
        navigate(`/user-management/view`, { state });
    };

    const handleUpdate = (id) => {
        const state = { id: id };
        navigate("/user-management/edit", { state });
    };



    const handleDelete = async (id) => {
        try {
            globalSwal.confirm({
                title: "Are you sure?",
                text: "Do you really want to delete this user?",
                onConfirm: async () => {
                    const res = await affiliateService.deleteAffiliateList(id);
                    if (res?.code === 200) {
                        globalSwal.success({
                            title: "Success!",
                            text: "Affiliate user has been deleted successfully.",
                        });
                        getList();
                        toast.success(res?.message);


                        setPagination({ ...pagination, page: 1 });
                    } else {
                        throw new Error(res?.message || "Failed to delete user.");
                    }
                },
            });
        } catch (error) {
            console.error("Error deleting exposure:", error);
            // toast.error("An unexpected error occurred while deleting IP.");
        }
    };

    const useDebounce = (func, delay) => {
        const debounceRef = useRef(null);

        return (...args) => {
            if (debounceRef.current) {
                clearTimeout(debounceRef.current);
            }
            debounceRef.current = setTimeout(() => {
                func(...args);
            }, delay);
        };
    };



    const handleSearchChange = (event) => {
        const value = event.target.value;
        setPagination((prev) => ({ ...prev, searchBy: value, page: 1 }));

    };

    const debouncedMerchantSearchChange = useDebounce(handleSearchChange, 300);

    const getStatusBadge = (status, field) => {
        if (field == "status") {
            if (status === 1) {
                return (
                    <Badge bg={"success"} className="badge badge-success">
                        Active
                    </Badge>
                );
            } else if (status === 0) {
                return (
                    <Badge bg={"danger"} className="badge badge-danger">
                        Inactive
                    </Badge>
                );
            }
        } else {
            if (status) {
                return (
                    <Badge bg={"primary"} className="badge badge-success">
                        Yes
                    </Badge>
                );
            } else if (!status) {
                return (
                    <Badge bg={"warning"} className="badge badge-danger">
                        No
                    </Badge>
                );
            }
        }

    };
    const columnsWithHTML = columns.map((column) => {
        if (column.toLowerCase() === "status") {
            return {
                header: "Status",
                accessor: column,
                Cell: ({ value }) => getStatusBadge(value, "status"),
            };
        }
        else if (column.toLowerCase() === "createdat") {
            return {
                header: "Date",
                accessor: column,
                Cell: ({ value }) => (
                    <span className="text-nowrap">{formatDateTime(value) || "N/A"}</span>
                ),
            };
        } else if (column.toLowerCase() === "phone") {
            return {
                header: "Phone Number",
                accessor: column,
                Cell: ({ value,row }) => (
                    <span className="text-nowrap">{row?.referredUserId?.phone || "N/A"}</span>
                ),
            };
        }else if (column.toLowerCase() === "username") {
            return {
                header: "Username ",
                accessor: column,
                Cell: ({ value,row }) => (
                    <span className="text-nowrap">{row?.referredUserId?.username || "N/A"}</span>
                ),
            };
        } else if (column.toLowerCase() === "merchantid") {
            return {
                header: "Merchant",
                accessor: column,
                Cell: ({ value,row }) => (
                    <span className="text-nowrap">{row?.referredUserId?.merchantId?.name || "N/A"}</span>
                ),
            };
        }
        else if (column.toLowerCase() === "name") {
            return {
                header: "Name",
                accessor: column,
                Cell: ({ value,row }) => (
                    <span className="text-nowrap">{row?.referredUserId?.name || "N/A"}</span>
                ),
            };
        } else if (column.toLowerCase() === "email") {
            return {
                header: "Email",
                accessor: column,
                Cell: ({ value,row }) => (
                    <span className="text-nowrap">{row?.referredUserId?.email || "N/A"}</span>
                ),
            };
        } else if (column.toLowerCase() === "depositamount") {
            return {
                header: "Deposit Amount",
                accessor: column,
                Cell: ({ value }) => (
                    <span className="text-nowrap">{formatCurrency(value || 0, 'INR')}</span>
                ),
            };
        } else if (column.toLowerCase() === "id") {
            return {
                header: "Actions",
                accessor: column,
                Cell: ({ value, row }) => getActionDropdown(value, 20),
            };
        }
        else if (column.toLowerCase() === "commission") {
            return {
                header: "Total Commission",
                accessor: column,
                Cell: ({ value, row }) => <span className="text-nowrap">{formatCurrency(value || 0, 'INR')}</span>,
            };
        }
        else if (column.toLowerCase() === "appliedto") {
            return {
                header: "Applied On",
                accessor: column,
                Cell: ({ value,row }) => <span className="text-nowrap">{value?value==='first'?"First Deposit Only":"All Deposits":'N/A'}</span>,
            };
        }
        else if (column.toLowerCase() === "commissionrate") {
            return {
                header: "Commission Rate",
                accessor: column,
                Cell: ({ value,row }) => <span className="text-nowrap">{value??'N/A'}</span>,
            };
        }
        else if (column.toLowerCase() === "commissiontype") {
            return {
                header: "Commission Type",
                accessor: column,
                Cell: ({ value,row }) => <span className="text-nowrap">{capitalizeFirstLetter(value)}</span>,
            };
        }
        else if (column.toLowerCase() === "conversiontype") {
            return {
                header: "Commission On",
                accessor: column,
                Cell: ({ value,row }) => <span className="text-nowrap">{capitalizeFirstLetter(value)}</span>,
            };
        }
        // conversionType
        else {
            return {
                header: column.charAt(0).toUpperCase() + column.slice(1),
                accessor: column,
                Cell: ({ value }) => (
                    <span className="text-nowrap">{value || "N/A"}</span>
                ),
            };
        }
    });

    return (
        <React.Fragment>
            <Header onSkin={setSkin} />
            <Sidebar />
            <div className="main main-app p-3 p-lg-4">

                <Card>
                    <Card.Header>
                        <div className="d-flex justify-content-between align-items-center">
                            <PageBreadcrumb title="Referrals List" />
                            
                            <div className="d-flex justify-content-between g-2 align-items-center">
                                {/* <Button className="" onClick={() => setIsHidden(!isHidden)}>
                                    Filter
                                </Button> */}
                            <Button
                                variant="primary"
                                type="button"
                                className="ms-2"
                                onClick={() => navigate("/affiliate-list")}
                              >
                                Back
                              </Button>
                            </div>
                        </div>
                        <div className={`${!isHidden ? "d-none" : ""} `}>
                            {/* Search Input */}
                            <TableFilter
                                // handleRangeChange={handleRangeChange}
                                handleSearchChange={handleSearchChange}
                                setPagination={setPagination}
                                setShowPicker={setShowPicker}
                                // state={state}
                                tableType={"affiliate-list"}
                                // transactionType={transactionType}
                                // clearRange={clearRange}
                                // userList={userList}
                                userId={''}
                                status={''}
                                showPicker={showPicker}
                            />
                        </div>
                    </Card.Header>

                    <>

                        {(loading ? <div className="d-flex my-5 justify-content-center align-content-center">

                            <BorderSpinner />
                        </div> : (<TableWithPagination
                            data={exposureList}
                            itemsPerPage={pagination.limit}
                            columns={columnsWithHTML}
                            currentPage={pagination.page}
                            totalData={pagination.totalDocs}
                            pagination={pagination}
                            setPagination={setPagination}
                            addType=''
                            redirectUrl={'/affiliate-management/add'}
                            handleSearchChange={debouncedMerchantSearchChange}
                                moduleId={20}
                                haveAdd={true}
                                haveTableSearch={true}
                        />))}

                    </>
                </Card>
            </div>


        </React.Fragment>
    );
}
