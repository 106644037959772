import React from "react";

import UserList from '../components/user-management/list'
import MerchantList from '../components/merchant-management/list'
import MerchantAdd from '../components/merchant-management/add'
import MerchantEdit from '../components/merchant-management/edit'
import RoleList from '../components/role-management/list'
import StaffList from '../components/staff-management/list'
import AddNewRole from '../components/role-management/add'
import StaffNewRole from '../components/staff-management/add'
import EditNewRole from '../components/role-management/edit'
import ViewNewRole from '../components/role-management/view'
import ViewNewStaff from '../components/staff-management/view'
import EditNewStaff from '../components/staff-management/edit'
import EditNewUser from '../components/user-management/edit'
import GameList from "../components/game-management/list";
import AddNewGame from "../components/game-management/add";
import EditNewGame from "../components/game-management/edit";

// Dashboard

import ProductManagement from "../dashboard/ProductManagement";

import ViewNewUser from "../components/user-management/view";
import ViewNewMerchant from "../components/merchant-management/view";
import TransactionHistory from "../components/transaction-management/transactionHistory";
import ViewWallets from "../components/user-management/viewWallets";
import ViewBankDetails from "../components/user-management/ViewBanks";
import ViewUserWalletDetails from "../components/user-management/viewWalletDetails";
import Crash_Bet from "../components/GameBetHistory/Crash_Bet";
import CrashHistory from "../components/crashHistory/CrashHistory";
import ViewGameDetails from "../components/game-management/view";


// game settings
import GameSettingList from '../components/game-setting/list'
import AddNewGameSetting from "../components/game-setting/add";
import ViewGameSetting from "../components/game-setting/view";
import EditGameSetting from "../components/game-setting/edit";


// currency management

import CurrencyListTable from "../components/currency-management/list";
import AddCurrency from "../components/currency-management/add";
import EditCurrency from "../components/currency-management/edit";
import ViewCurrencyDetails from "../components/currency-management/view";
import PrivacyPolicy from "../components/static-pages/privacy-policy";
import TermsAndConditions from "../components/static-pages/terms-and-conditions";
import AboutUs from "../components/static-pages/about-us";
import BetStatics from "../components/dashboard/bet-statistics";
import EventManagement from "../dashboard/EventManagement";
import Cryptocurrency from "../dashboard/Cryptocurrency";
import TransactionMetrics from "../components/dashboard/transaction-metrics";
import UserMetrics from "../components/dashboard/user-metrics";
import FAQList from "../components/static-pages/faq/list";
import AddNewFAQ from "../components/static-pages/faq/add";
import EditFAQ from "../components/static-pages/faq/edit";
import ViewFAQ from "../components/static-pages/faq/view";
import BannerList from "../components/banner-management/list";
import AddNewBanner from "../components/banner-management/add";
import EditBanner from "../components/banner-management/edit";
import ViewBanner from "../components/banner-management/view";
import GameMetrics from "../components/dashboard/game-statistics";
import CricketHistory from "../components/sports/cricket-sports";
import CricketSportBetList from "../components/sports-record/cricket-sports/list";
import ReferralSetting from "../components/referral-management/referral-setting";
import GameCategoryList from "../components/game-management/category-list";
import EditGameCategory from "../components/game-management/category-edit";
import LotteryList from "../components/lottery-management/list";
import TicketList from "../components/lottery-management/ticket-list";
import AddNewLottery from "../components/lottery-management/add";
import ViewLottery from "../components/lottery-management/view";
import EditLottery from "../components/lottery-management/edit";
import MakeWinner from "../components/lottery-management/makeWinner";
import LotteryStatistics from "../components/dashboard/lottery-statistics";
import AddNewSettings from "../components/platform-settings/add";
import PlatformSettingsList from "../components/platform-settings/list";
import ViewSettings from "../components/platform-settings/view";
import EditSettings from "../components/platform-settings/edit";
import AddGameCategory from "../components/game-management/category-add";
import IPWhitelistList from "../components/ip-whitelisting/list";
import WelcomePage from "../components/welcome/welcomePage";
import ExposureSettingList from "../components/exposure-management/list";
import ViewExposure from "../components/exposure-management/view";
import EditExposure from "../components/exposure-management/edit";
import AddExposure from "../components/exposure-management/add";
import AddTheme from "../components/ThemeManagement/add";
import ThemeList from "../components/ThemeManagement/list";
import EditTheme from "../components/ThemeManagement/edit";
import ViewTheme from "../components/ThemeManagement/view";
import AffiliateStatistics from "../components/dashboard/affiliation/affiliate-statistics";
import AddNewUser from "../components/user-management/add";
import AffiliateList from "../components/affiliate-management/list";
import CommissionSetting from "../components/affiliate-management/commission-settings";
import ReferralsList from "../components/affiliate-management/referralsList";


const modules = [
  "Role And Permission",//1
  "Staffs",//2
  "Users",//3
  "Games",//4
  "Merchants",//5
  "Transactions",//6
  "Game Record",//7
  "Game Settings",//8
  "Dashboard",//9
  "Banners",//10
  "Sports Management",//11
  "Sports Bet",//12
  // "Referral",
  "Games Category",//13
  // "Static Pages",
  "Currency",//14
  "Lottery",//15
  "Platform Settings",//16
  "IP Whitelisting",//17
  
];
const protectedRoutes = [
  { path: "welcome", moduelName: "Welcome", element: <WelcomePage /> },
  // theme management
  { path: "theme-management",moduelName:"Theme Management", element: <ThemeList /> },
  { path: "theme-management/view",moduelName:"Theme Management", element: <ViewTheme /> },
  { path: "theme-management/edit",moduelName:"Theme Management", element: <EditTheme /> },
  { path: "theme-management/add",moduelName:"Theme Management", element: <AddTheme /> },


  // exposure management
  { path: "exposure-management",moduelName:"Exposure Management", element: <ExposureSettingList /> },
  { path: "exposure-management/view",moduelName:"Exposure Management", element: <ViewExposure /> },
  { path: "exposure-management/edit",moduelName:"Exposure Management", element: <EditExposure /> },
  { path: "exposure-management/add",moduelName:"Exposure Management", element: <AddExposure /> },


  // { path: "ip-whitelisting/add", element: <AddNewSettings /> },
  { path: "ip-whitelist/list",moduelName:"IP Whitelisting", element: <IPWhitelistList /> },
  // { path: "platform-settings/edit", element: <EditSettings /> },
  // { path: "platform-settings/view", element: <ViewSettings /> },
  // platform specific routes
  
  { path: "platform-settings/add",moduelName:"Platform Settings", element: <AddNewSettings /> },
  { path: "platform-settings/list",moduelName:"Platform Settings", element: <PlatformSettingsList /> },
  { path: "platform-settings/edit",moduelName:"Platform Settings", element: <EditSettings /> },
  { path: "platform-settings/view",moduelName:"Platform Settings", element: <ViewSettings /> },


  // currency route
  { path: "lottery-list",moduelName:"Lottery", element: <LotteryList /> },
  { path: "ticket-list",moduelName:"Lottery", element: <TicketList /> },
  { path: "lottery/add",moduelName:"Lottery", element: <AddNewLottery /> },
  { path: "lottery/edit",moduelName:"Lottery", element: <EditLottery /> },
  { path: "lottery/view", moduelName:"Lottery", element: <ViewLottery /> },
  { path: "lottery/make-winner",moduelName:"Lottery", element: <MakeWinner /> },



  




  { path: "currency",moduelName:"Currency", element: <CurrencyListTable /> },
  { path: "currency/add",moduelName:"Currency", element: <AddCurrency /> },
 
  { path: "currency/view",moduelName:"Currency", element: <ViewCurrencyDetails /> },
  { path: "currency/edit",moduelName:"Currency", element: <EditCurrency /> },

 // game settings route
  
 { path: "game-setting",moduelName:"Game Settings", element: <GameSettingList /> },
 { path: "game-setting/add",moduelName:"Game Settings", element: <AddNewGameSetting /> },

 { path: "game-setting/view",moduelName:"Game Settings", element: <ViewGameSetting /> },
 { path: "game-setting/edit",moduelName:"Game Settings", element: <EditGameSetting /> },
  // Game_Bet History

   {path:"bet-history", moduleName:"Game Record",element:<Crash_Bet/>},
  { path: "crash-history", moduleName: "Game Record", element: <CrashHistory /> },
   
  { path: "transaction-history",moduelName:"Transactions", element: <TransactionHistory  transactionType={''} type={''}/> },
  { path: "pay-in/transaction",moduelName:"Transactions", element: <TransactionHistory transactionType={'credit'} type={'wallet'} /> },
  { path: "pay-out/transaction",moduelName:"Transactions", element: <TransactionHistory transactionType={'debit'} type={'wallet'}/> },

  // { path: "dashboard", element: <ProductManagement /> },
  { path: "user-management", moduelName: "Users", element: <UserList /> },
  { path: "user-management/add",moduelName:"Users", element: <AddNewUser /> },
  
  { path: "user-management/edit",moduelName:"Users", element: <EditNewUser /> },
  { path: "user-management/view",moduelName:"Users", element: <ViewNewUser /> },
  { path: "user-management/view-wallet",moduelName:"Users", element: <ViewWallets /> },
  { path: "user-management/view-wallet/view",moduelName:"Users", element: <ViewUserWalletDetails /> },
  { path: "user-management/view-bank",moduelName:"Users", element: <ViewBankDetails /> },

  // wallet-management/view

  
  { path: "game-management-category",moduelName:"Games Category", element: <GameCategoryList /> },
  { path: "game-management-category/edit",moduelName:"Games Category", element: < EditGameCategory /> },
  { path: "game-management-category/add",moduelName:"Games Category", element: < AddGameCategory/> },
  
  
  // EditGameCategory
  
  { path: "game-management",moduleName:"Games", element: <GameList /> },
  { path: "game-management/add",moduleName:"Games", element: <AddNewGame /> },
  { path: "game-management/edit",moduleName:"Games", element: <EditNewGame /> },
  { path: "game-management/view",moduleName:"Games", element: <ViewGameDetails /> },
  
  { path: "role-management",moduleName:"Role And Permission", element: <RoleList /> },
  { path: "role-management/add",moduleName:"Role And Permission", element: <AddNewRole /> },
  { path: "role-management/edit",moduleName:"Role And Permission", element: <EditNewRole /> },
  { path: "role-management/view", moduleName: "Role And Permission", element: <ViewNewRole /> },
  
  { path: "staff-management",moduelName:"Staffs", element: <StaffList /> },
  { path: "staff-management/add",moduelName:"Staffs", element: <StaffNewRole /> },
  { path: "staff-management/edit",moduelName:"Staffs", element: <EditNewStaff /> },
  { path: "staff-management/view", moduelName: "Staffs", element: <ViewNewStaff /> },
  

  { path: "merchant-management",moduleName:"Merchants", element: <MerchantList /> },
  { path: "merchant-management/add",moduleName:"Merchants", element: <MerchantAdd /> },
  { path: "merchant-management/edit",moduleName:"Merchants", element: <MerchantEdit /> },
  { path: "merchant-management/view",moduleName:"Merchants", element: <ViewNewMerchant /> },

 
  // { path: "privacy-policy",moduleName:"Privacy Policy", element: <PrivacyPolicy  /> },
  // { path: "terms-and-conditions", element: <TermsAndConditions /> },
  // { path: "about-us", element: <AboutUs /> },
  // { path: "faq", element: <FAQList /> },
  // { path: "faq/add", element: <AddNewFAQ/> },
  // { path: "faq/edit", element: <EditFAQ /> },
  // { path: "faq/view", element: <ViewFAQ /> },

  { path: "banner", moduleName:"Banners", element: <BannerList /> },
  { path: "banner/add",moduleName:"Banners", element: <AddNewBanner/> },
  { path: "banner/edit",moduleName:"Banners", element: <EditBanner /> },
  { path: "banner/view",moduleName:"Banners", element: <ViewBanner /> },

  { path: "bet-statics", moduleName:"Dashboard", element: <BetStatics /> },
  { path: "financial-metrics",moduleName:"Dashboard", element: <TransactionMetrics /> },
  {path:"user-metrics",moduleName:"Dashboard", element: <UserMetrics /> },
  { path: "game-metrics",moduleName:"Dashboard", element: <GameMetrics /> },
  { path: "lottery-metrics", moduleName: "Dashboard", element: <LotteryStatistics /> },
  { path: "affiliate-metrics", moduleName: "Dashboard", element: <AffiliateStatistics /> },

  

  
  { path: 'cricket-sports-history',moduleName:"Sports Management", element: <CricketHistory /> },
  
  // sport bet list

  { path: 'cricket-sports-betList',moduleName:"Sports Bet", element: <CricketSportBetList /> },

  

  // referral
  // { path: 'referral-setting', moduleName: "Referral", element: <ReferralSetting /> },


  // affiliate management
  { path: 'affiliate-list', moduleName: "Affiliate Management", element: <AffiliateList /> },
  { path: 'referrals-list', moduleName: "Affiliate Management", element: <ReferralsList /> },

  
  {path:'commission-settings',moduleName:'Affiliate Magagement',element:<CommissionSetting/>}

  

  

]

export default protectedRoutes;