import React, { useEffect, useRef, useState } from 'react'
import TableWithPagination from '../../common/table'
import { DateRange, DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import Header from '../../layouts/Header'
import Sidebar from '../../layouts/Sidebar'
import { Card, Badge, Button } from 'react-bootstrap'
import UserService from '../../service/api/user.service '
import AuthService from '../../service/api/auth.service'
import { useLocation, useNavigate } from 'react-router-dom'
import useSkinSwitcher from '../../common/skin'
import Dropdown from "react-bootstrap/Dropdown";
import capitalizeFirstLetter from '../../helper/Capitalise'
import { addDays, format } from 'date-fns';
import './card.css'
import ReactSelect from 'react-select';
import { isNull } from 'lodash';
import TableFilter from '../../common/filter';
import PageBreadcrumb from '../../common/PageBreadcrum';

const ViewWallets = () => {


    const userService = UserService();
    const authService = AuthService();
    const navigate = useNavigate();
    const { skin, setSkin } = useSkinSwitcher();
    const [transactionList, setTransactionList] = useState([]);
    const location = useLocation();
    const { id } = location.state;
    const [fromDate, setFromDate] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const handleDatesChange = (ranges) => {
        setStartDate(ranges.startDate);
        setEndDate(ranges.endDate);
    };

    const options = {
        startDate: startDate,
        endDate: endDate,
        alwaysShowCalendars: true, // Optional: always show both calendars
    };
    const [toDate, setToDate] = useState('');
    const [state, setState] = useState([
        {
            startDate: null,
            // endDate: new Date(),
            endDate: null,
            key: 'selection'
        }
    ]);
    const clearRange = () => {
        setState([{ startDate: null, endDate: null, key: 'selection' }]);
        setShowPicker(false)
    };

    const [pagination, setPagination] = useState({
        page: 1,
        searchBy: '',
        status: '',
        user: '',
        transactionType: '',
        limit: 10
    });

    // "userId",
    // "createdAt",
    // "transactionId",
    // 'currencyId',
    // 'amount',
    // "transactionType",
    // 'status'

    const [columns, setColumns] = useState(
        [

            "userId",
            "createdAt",
            "transactionId",
            "transactionType",
            "type",
            "rrn",
            "openingBalance",
            "amount",
            "closingBalance",
            "status",

        ]
    );

    const [accountBalance, setAccountBalance] = useState(null);

    const getUserWallet = async () => {
        console.log(state, "date formate::;;;;;")
        try {
            const res = await userService.getAdminUserWalletDetails({
                page: pagination?.page,
                limit: pagination?.limit,
                searchBy: pagination?.searchBy?.trim(),
                status: pagination?.status ? pagination.status : '',
                user: pagination?.user ? pagination.user : '',
                transactionType: pagination?.transactionType ? pagination?.transactionType : '',
                fromDate: pagination?.fromDate ? pagination?.fromDate : '',
                toDate: pagination?.toDate ? pagination?.toDate : '',
            }, id);
            console.log(res, "======hello========")
            if (res?.code === 200) {
                setTransactionList(res?.data?.transactions?.docs);
                setPagination((prevPagination) => ({
                    ...prevPagination,
                    pages: res.data.transactions.pages,
                    totalDocs: res.data.transactions.totalDocs,
                    limit: res.data.transactions.limit
                }));
                setAccountBalance(res?.data)
                console.log(res, "======userAdmin wallet details====");
            }
            else {
                console.log(res, "failed to data get")
            }
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {

        authService.getLoginData()
    }, [])


    useEffect(() => {
        getUserWallet();

        // setPagination((preValue) => ({
        //     ...preValue,
        //     page: 1
        // }))

    }, [pagination.searchBy,pagination?.page, pagination.toDate, pagination.status, state, pagination.transactionType]);



    // Define HTML for status badges
    // Define HTML for status badges
    const getStatusBadge = (status) => {
        if (status === 1) {
            return (
                <Badge bg={"success"} className="badge badge-success">
                    Sucess
                </Badge>
            );
        } else if (status === 2) {
            return (
                <Badge bg={"pink"} className="badge badge-danger">
                    Failed
                </Badge>
            );
        } else if (status === 3) {
            return (
                <Badge bg={"info"} className="badge badge-info">
                    Initiated
                </Badge>
            );
        }
        else if (status === 0) {
            return (
                <Badge bg={"warning"} className="badge badge-warning">
                    Pending
                </Badge>
            );
        }
    };

    // Define HTML for action column
    const getActionDropdown = (id) => {
        console.log("id", id);
        return (
            <Dropdown>
                <Dropdown.Toggle as="span" className="custom-dropdown-toggle">
                    <i className="ri-more-2-fill"></i>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item onClick={() => handleView(id)}>View</Dropdown.Item>

                </Dropdown.Menu>
            </Dropdown>
        );
    };


    const handleView = (id) => {
        const state = { id: id };
        navigate(`/user-management/view-wallet/view`, { state });

    };

    const formatDateTime = (createdAt) => {
        const date = new Date(createdAt);

        const day = String(date.getDate()).padStart(2, '0');
        const month = date.toLocaleString('default', { month: 'short' });
        const year = date.getFullYear();
        const dateString = `${day}-${month}-${year}`;

        const timeString = date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true });

        // return { date: dateString, time: timeString };
        return `${dateString}/${timeString}`
    };
    // const handleStatusUpdate = async (id) => {
    //   // const state = {id: id}
    //   // navigate(`/role-management/edit`, {state})
    //   const role = staffList.filter((item) => item.id === id);
    //   const res = await userService.updateUser(role[0].id, {...role[0], 
    //     status: role[0].status == 1 ? 0 : 1,
    //   });
    //   if (res?.code == 200) {
    //     toast.success(res?.message);
    //     getList();
    //   } else {
    //     toast.error(res?.message);
    //   }
    // };



    // Map the columns to include HTML for status badges
    const columnsWithHTML = columns.map((column) => {
        if (column.toLowerCase() === "status") {
            return {
                header: "Status",
                accessor: column,
                Cell: ({ value }) => getStatusBadge(value), // Render status badge based on the value
            };
        } else if (column.toLowerCase() === "type") {
            return {
              header: "Type",
              accessor: column,
              Cell: ({ value }) => {
                console.log("value", value, column);
                return capitalizeFirstLetter(value) || "N/A";
              }, // Render action dropdown based on the value
            };
          }
        
        else if (column.toLowerCase() === "createdat") {
            return {
                header: "Transaction Date",
                accessor: column,
                Cell: ({ value }) => {
                    console.log("datavalue", value, column);
                    return formatDateTime(value) || "N/A";
                }, // Render action dropdown based on the value
            };
        } else if (column.toLowerCase() === "amount") {
            return {
                header: "Amount",
                accessor: column,
                Cell: ({ value,row }) => {
                    return (
                        <>
                          {value ? (
                            <>
                              {" "}
                              <span className="small pe-1">
                                {row?.currencyId?.currencySymbols}
                              </span>
                              {Number(value)?.toFixed(2)}
                            </>
                          ) : (
                            "0.00"
                          )}
                        </>
                      );
                }, // Render action dropdown based on the value
            };
        }
        else if (column.toLowerCase() === "transactiontype") {
            return {
                header: "Transaction Type",
                accessor: column,
                Cell: ({ value }) => {
                    // console.log("datavalue", value, column);
                    return capitalizeFirstLetter(value) || "N/A";
                }, // Render action dropdown based on the value
            };
        }
        else if (column.toLowerCase() === "rrn") {
            return {
              header: "RRN",
              accessor: column,
              Cell: ({ value }) => {
                console.log("value", value, column);
                return capitalizeFirstLetter(value) || "N/A";
              }, // Render action dropdown based on the value
            };
        } 
        else if (column.toLowerCase() === "closingbalance") {
            return {
              header: "Closing Balance",
              accessor: column,
              Cell: ({ value, row }) => {
                return (
                  <>
                    {<> <span className="small pe-1">{row?.currencyId?.currencySymbols}</span>{value?.toFixed(2) || row.openingBalance?.toFixed(2) || '0.00'}</>}
      
                    {/* {value && row.status == 1? (<> <span className="small pe-1">{row?.currencyId?.currencySymbols}</span>{value?.toFixed(2)}</>) : (<> <span className="small pe-1">{row?.currencyId?.currencySymbols}</span>{row.openingBalance?.toFixed(2)}</>)} */}
                  </>
                )
                // value?.toFixed(2) || '--' // Render action dropdown based on the value}
              },
            };
        }
        else if (column.toLowerCase() === "openingbalance") {
            return {
              header: "Opening Balance",
              accessor: column,
              Cell: ({ value, row }) => {
                return (
                  <>
                    {<> <span className="small pe-1">{row?.currencyId?.currencySymbols}</span>{value?.toFixed(2) || '0.00'}</>}
                  </>
                )
                // value?.toFixed(2) || '--' // Render action dropdown based on the value}
              },
            };
          }
          
        else if (column.toLowerCase() === "currencyid") {
            return {
                header: "Currency",
                accessor: column,
                Cell: ({ value }) => {
                    console.log("currenyvalue", value, column);
                    return value?.currencySymbols || "N/A";
                }, // Render action dropdown based on the value
            };
        }
        else if (column.toLowerCase() === "transactionid") {
            return {
                header: "Transaction Id",
                accessor: column,
                Cell: ({ value }) => {
                    return value || "N/A"
                }
            };
        }
        else if (column.toLowerCase() === "userid") {
            return {
                header: "User",
                accessor: column,
                Cell: ({ value }) => {

                    return value?.name || "N/A";
                }, // Render action dropdown based on the value
            };
        }

        // else {
        //     return column;
        // }
    });


    const useDebounce = (func, delay) => {
        const debounceRef = useRef(null);

        return (...args) => {
            if (debounceRef.current) {
                clearTimeout(debounceRef.current);
            }
            debounceRef.current = setTimeout(() => {
                func(...args);
            }, delay);
        };
    };

    const handleSearchChange = (event) => {
        const value = event.target.value.trim();
        // if (value.length > 3 || value.length === 0) {
        // }
        setPagination((prev) => ({ ...prev, searchBy: value }));
    };

    const debouncedHandleSearchChange = useDebounce(handleSearchChange, 300);






    const [showPicker, setShowPicker] = useState(false);

    const handleRangeChange = (ranges) => {
        console.log(ranges, 'date formate::;;;;;')
        setState([ranges.selection]);
        // setShowPicker(false); // Hide the picker after selecting the date range
    };
    const formatDate = (date) => {
        if (date === null) return;
        return format(date, 'dd-MM-yyyy');
    };
    const [isHidden, setIsHidden] = useState(false);
    const [userList, setUserList] = useState(null);
    useEffect(() => {
        const getList = async () => {
            try {
                const res = await userService.getUserList();
                if (res?.code === 200) {
                    console.log(res, "use list==========")
                    const formattedUserList = res.data.map((user) => ({
                        label: user.name,
                        value: user.id,
                    }));
                    setUserList(formattedUserList);
                }

            } catch (error) {
                console.log(error);
            }
        }
        getList();
    }, [])

    return (
        <React.Fragment>
            <Header onSkin={setSkin} />
            <Sidebar />
            <div className="main main-app p-3 p-lg-4">
                <Card>

                    <div className=" align-items-center">
                        <Card.Header>
                            {/* <div className='d-flex  flex-row justify-content-between'>
                              
                                <PageBreadcrumb title="User Wallet Overview" />

                            </div> */}
                            {/* <div className="container">
                                <div className="row " style={{ width: "inherit" }}>
                                    <div className="col-12 col-sm-6 col-md-4 col-lg-2 balance-card card text-center m-3">

                                        <div className="card-body p-2">
                                            <h5 className="card-title">Balance</h5>
                                            <p className="card-text" id="balance-amount">{accountBalance?.balance?.length > 0 && accountBalance.balance[0]?.balance?.toFixed(2) || '0.00'}</p>
                                        </div>

                                    </div>
                                    <div className="col-12 col-sm-6 col-md-4 col-lg-2 balance-card card text-center m-3">

                                        <div className="card-body p-2">
                                            <h5 className="card-title">Pending Transactions</h5>
                                            <p className="card-text" id="balance-amount">{accountBalance?.pendingTransactionsCount > 0 && accountBalance?.pendingTransactionsCount || '0'}</p>

                                        </div>

                                    </div>
                                    <div className="col-12 col-sm-6 col-md-4 col-lg-2 balance-card card text-center m-3">

                                        <div className="card-body p-2">
                                            <h5 className="card-title">Credit Transactions</h5>
                                            <p className="card-text" id="balance-amount">{accountBalance?.creditTransactionsCount > 0 && accountBalance?.creditTransactionsCount || '0'}</p>

                                        </div>

                                    </div>
                                    <div className="col-12 col-sm-6 col-md-4 col-lg-2 balance-card card text-center m-3">

                                        <div className="card-body p-2">
                                            <h5 className="card-title">Debit Transactions</h5>
                                            <p className="card-text" id="balance-amount">{accountBalance?.debitTransactionsCount > 0 && accountBalance?.debitTransactionsCount || '0'}</p>

                                        </div>

                                    </div>
                                    <div className="col-12 col-sm-6 col-md-4 col-lg-2 balance-card card text-center m-3">

                                        <div className="card-body p-2">
                                            <h5 className="card-title">Total Transactions</h5>
                                            <p className="card-text" id="balance-amount">{accountBalance?.transactions && accountBalance?.transactions?.totalDocs || '0'}</p>


                                        </div>
                                    </div>
                                </div>
                            </div> */}


                            {/* <hr /> */}

                            <div className="d-flex justify-content-between align-items-center">

                                <PageBreadcrumb title="User Wallet History" />
                                <div>
                                    <Button className="" onClick={() => setIsHidden(!isHidden)}>
                                        Filter
                                    </Button>
                                </div>
                            </div>
                            {/* <h1>Transactions</h1> */}



                            {/* </div> */}
                            <div className={`wallet ${!isHidden ? 'd-none' : ''} `}>
                                {/* Search Input */}
                                <TableFilter
                                    handleRangeChange={handleRangeChange}
                                    handleSearchChange={handleSearchChange}
                                    setPagination={setPagination}
                                    setShowPicker={setShowPicker}
                                    state={state}
                                    clearRange={clearRange}
                                    userList={userList}
                                    showPicker={showPicker}
                                    tableType={'wallet'}

                                />
                            </div>
                        </Card.Header>
                        <TableWithPagination
                            tableType={'wallet'}
                            data={[...transactionList]}
                            itemsPerPage={pagination?.limit}
                            columns={columnsWithHTML}
                            currentPage={pagination?.page}
                            totalPages1={pagination?.totalPages}
                            totalData={pagination?.totalDocs}
                            pagination={pagination}
                            setPagination={setPagination}
                            moduleId={3}
                            haveAdd={false}
                            haveTableSearch={false}
                        />
                    </div>
                </Card>
            </div>
        </React.Fragment>
    )
}

export default ViewWallets