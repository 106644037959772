import React, { useEffect, useRef, useState } from "react";
import Header from "../../layouts/Header";
import TableWithPagination from "../../common/table";
import useSkinSwitcher from "../../common/skin";
import AuthService from "../../service/api/auth.service";
import Dropdown from "react-bootstrap/Dropdown";
import { Link, useNavigate } from "react-router-dom";
import { Button, Card, Badge, Modal, Form } from "react-bootstrap";
import PageBreadcrumb from "../../common/PageBreadcrum";

import "cryptofont/css/cryptofont.css";
import Sidebar from "../../layouts/Sidebar";
import { toast } from "react-toastify";

import globalSwal from "../../common/swalService";
import PlatformService from "../../service/api/platform-settings.service";
import formatDateTime from "../../helper/DateFormate";
import IPWhitelistingService from "../../service/api/ip-whitelisting.service";
import AddIpWhitelistModal from "./add";
import { BorderSpinner } from "../../common/Spinner";

export default function IPWhitelistList() {
  const platFormService = PlatformService();
  const whitelistService = IPWhitelistingService();
  const authService = AuthService();

  const navigate = useNavigate();
  const { skin, setSkin } = useSkinSwitcher();
  const [ipWhitelist, setIpWhitelist] = useState({
    admin: [],
    merchant: []
  });
  const [addTo, setAddTo] = useState('admin');
  const [show, setShow] = useState(false);
  const [merchantTabActive, setMerchantTabActive] = useState(false);
  const [adminTabActive, setAdminTabActive] = useState(false);
  const [loading, setLoading] = useState({ admin: true, merchant: true });
  const [modalTypeAndId, setModalTypeAndId] = useState({
    type: "",
    id: "",
  });


  const [pagination, setPagination] = useState({
    page: 1,
    searchBy: "",
    limit: 25,
  });

  const [paginationAdmin, setPaginationAdmin] = useState({
    page: 1,
    searchBy: "",
    limit: 25,
  });
  const [columns] = useState([
    "createdAt",
    "merchantId",
    "ip",
    "identifier",
    "id",
  ]);

  useEffect(() => {
    authService.getLoginData();
    getAdminToggle();
    getMerchantToggle();
  }, []);

  useEffect(() => {
    if (merchantTabActive) {
      getList();
    }
  }, [pagination.page,pagination.limit, pagination.searchBy, merchantTabActive]);

  useEffect(() => {
    if (adminTabActive) {
      getAdminList();
    }
  }, [paginationAdmin.page,pagination.limit, paginationAdmin.searchBy, adminTabActive]);




  const updateIpWhitelist = (type, data) => {
    setIpWhitelist((prevState) => ({
      ...prevState,
      [type]: data,
    }));
    setLoading((prevLoading) => ({
      ...prevLoading,
      [type]: false,
    }))
  };

  const getAdminList = async () => {
    try {
      const res = await whitelistService.getAdminIPList({
        page: paginationAdmin?.page,
        limit: paginationAdmin?.limit,
        searchBy: paginationAdmin?.searchBy,
      });
      if (res?.code === 200) {
        updateIpWhitelist("admin", res?.data?.docs);

        setPaginationAdmin((prevPagination) => ({
          ...prevPagination,
          pages: res.data.pages,
          totalDocs: res.data.totalDocs,
          limit: res.data.limit,
        }));
      } else {
        // console.error(res?.message);
        throw new Error(res?.message || "Failed to fetch IP whitelist.");
      }
    } catch (error) {
      console.error("Error fetching IP whitelist:", error);
      // toast.error("An unexpected error occurred while fetching IP whitelist.");
    }
  };
  const getList = async () => {
    try {
      const res = await whitelistService.getIPList({
        page: pagination?.page,
        limit: pagination?.limit,
        searchBy: pagination?.searchBy,
      });
      if (res?.code === 200) {
        updateIpWhitelist("merchant", res?.data?.docs);
        setPagination((prevPagination) => ({
          ...prevPagination,
          pages: res.data.pages,
          totalDocs: res.data.totalDocs,
          limit: res.data.limit,
        }));
      } else {
        // console.error(res?.message);
        throw new Error(res?.message || "Failed to fetch IP whitelist.");
      }
    } catch (error) {
      console.error("Error fetching IP whitelist:", error);
      // toast.error("An unexpected error occurred while fetching IP whitelist.");
    }
  };

  const AdminMerchantIPToggle = async (type,isOn) => {
    try {
      const res = await whitelistService.ipToggle({
        "forPortal": type,
        "isIpWhitelistCheckEnabled": isOn?"true":"false",
      });
      if (res?.code === 200) {
       console.log("IP whitelist",res);
      } else {
        // console.error(res?.message);
        throw new Error(res?.message || "Failed to fetch IP whitelist.");
      }
    } catch (error) {
      console.error("Error fetching IP whitelist:", error);
      // toast.error("An unexpected error occurred while fetching IP whitelist.");
    }
  };

  const getAdminToggle = async () => {
    try {
      const res = await whitelistService.toggleAdmin();
      if (res?.code === 200) {
        setAdminTabActive(res?.data?.isIpWhitelistCheckEnabled)
       console.log("IP admin",res);
      } else {
        // console.error(res?.message);
        throw new Error(res?.message || "Failed to fetch IP whitelist.");
      }
    } catch (error) {
      console.error("Error fetching IP whitelist:", error);
      // toast.error("An unexpected error occurred while fetching IP whitelist.");
    }
  };

  const getMerchantToggle = async () => {
    try {
      const res = await whitelistService.toggleMerchant();
      if (res?.code === 200) {
        setMerchantTabActive(res?.data?.isIpWhitelistCheckEnabled)
       console.log("IP admin",res);
      } else {
        // console.error(res?.message);
        throw new Error(res?.message || "Failed to fetch IP whitelist.");
      }
    } catch (error) {
      console.error("Error fetching IP whitelist:", error);
      // toast.error("An unexpected error occurred while fetching IP whitelist.");
    }
  };


  const getActionDropdown = (id, type,moduleId) => {
    // console.log(row,"action");
    return (
      <Dropdown>
        <Dropdown.Toggle as="span" className="custom-dropdown-toggle">
          <i className="ri-more-2-fill"></i>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item disabled={!authService.checkAccess(moduleId,'view')} onClick={() => handleView(id, type)}>View</Dropdown.Item>
          <Dropdown.Item disabled={!authService.checkAccess(moduleId,'edit')} onClick={() => handleUpdate(id, type)}>Edit</Dropdown.Item>
          <Dropdown.Item disabled={!authService.checkAccess(moduleId,'delete')} onClick={() => handleDelete(id, type)}>Delete</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const handleUpdate = (id, tableType) => {
    setModalTypeAndId((prevValue) => ({
      ...prevValue,
      type: "edit",
      id: id,
    }));
    setShow(true);
    setAddTo(tableType);

  };

  const handleView = (id, tableType) => {
    setModalTypeAndId((prevValue) => ({
      ...prevValue,
      type: "view",
      id: id,
    }));
    setShow(true);
    setAddTo(tableType);
  };

  const handleDelete = async (id, type) => {
    try {
      globalSwal.confirm({
        title: "Are you sure?",
        text: "Do you really want to delete this IP?",
        onConfirm: async () => {
          const res = await whitelistService.deleteIP(id);
          if (res?.code === 200) {
            globalSwal.success({
              title: "Success!",
              text: "IP has been deleted successfully.",
            });
            toast.success(res?.message);
            if (type === 'admin') {
              getAdminList();
            }
            else {
              getList();
            }

            setPagination({ ...pagination, page: 1 });
          } else {
            throw new Error(res?.message || "Failed to delete IP.");
          }
        },
      });
    } catch (error) {
      console.error("Error deleting IP:", error);
      // toast.error("An unexpected error occurred while deleting IP.");
    }
  };

  const useDebounce = (func, delay) => {
    const debounceRef = useRef(null);

    return (...args) => {
      if (debounceRef.current) {
        clearTimeout(debounceRef.current);
      }
      debounceRef.current = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const handleAdminSearchChange = (event) => {
    const value = event.target.value;
    setPaginationAdmin((prev) => ({ ...prev, searchBy: value, page: 1 }));


  };

  const handleMerchantSearchChange = (event) => {
    const value = event.target.value;
    setPagination((prev) => ({ ...prev, searchBy: value, page: 1 }));

  };

  const debouncedAdminSearchChange = useDebounce(handleAdminSearchChange, 1000);
  const debouncedMerchantSearchChange = useDebounce(handleMerchantSearchChange, 1000);

  const columnsWithHTML = columns.map((column) => {
    if (column.toLowerCase() === "createdat") {
      return {
        header: "Date",
        accessor: column,
        Cell: ({ value }) => (
          <span className="text-nowrap">{formatDateTime(value) || "N/A"}</span>
        ),
      };
    } else if (column.toLowerCase() === "merchantid") {
      return {
        header: "Merchant",
        accessor: column,
        Cell: ({ value }) => (
          <span className="text-nowrap">{value?.name || "N/A"}</span>
        ),
      };
    } else if (column.toLowerCase() === "ip") {
      return {
        header: "IP Address",
        accessor: column,
        Cell: ({ value }) => (
          <span className="text-nowrap">{value || "N/A"}</span>
        ),
      };
    } else if (column.toLowerCase() === "identifier") {
      return {
        header: "Identifier",
        accessor: column,
        Cell: ({ value }) => (
          <span className="text-nowrap">{value || "N/A"}</span>
        ),
      };
    } else if (column.toLowerCase() === "id") {
      return {
        header: "Actions",
        accessor: column,
        Cell: ({ value, row }) => getActionDropdown(value, row.type,17),
      };
    } else {
      return {
        header: column.charAt(0).toUpperCase() + column.slice(1),
        accessor: column,
        Cell: ({ value }) => (
          <span className="text-nowrap">{value || "N/A"}</span>
        ),
      };
    }
  });

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <Sidebar />
      <div className="main main-app p-3 p-lg-4">
        <Card>
          <Card.Header>
            <PageBreadcrumb title="IP Whitelist List" />
          </Card.Header>
          <Card.Body>

            <div className="row ">
              {/* Admin IP Whitelist */}

              <div className="col-md-6 mb-3">
                <Card>
                  <Card.Header>
                    <div className="d-flex justify-content-between align-items-center">
                      <span>Admin IP Whitelist</span>
                      <Form.Switch
                        id="toggle-switch"
                        value={adminTabActive}
                        checked={adminTabActive}
                        disabled={!authService.checkAccess(17,'edit')}
                        onClick={() => {
                          console.log("Admin IP Whitelist", !adminTabActive); 
                          AdminMerchantIPToggle('admin', !adminTabActive);
                        }}
                        // onClick={()=>AdminMerchantIPToggle('admin',!adminTabActive)}
                        onChange={(e) => setAdminTabActive(e.target.checked)}
                      />
                    </div>
                  </Card.Header>
                  <>

                    {adminTabActive && (loading.admin ? <div className="d-flex my-5 justify-content-center align-content-center">

                      <BorderSpinner />
                    </div> : (<TableWithPagination
                      data={ipWhitelist.admin}
                      itemsPerPage={paginationAdmin.limit}
                      columns={columnsWithHTML.filter((col) => col.accessor.toLowerCase() !== "merchantid")}
                      currentPage={paginationAdmin.page}
                      totalData={paginationAdmin.totalDocs}
                      pagination={paginationAdmin}
                      setPagination={setPaginationAdmin}
                      addType='modal'
                      redirectUrl={() => {
                        setShow(true);
                        setAddTo('admin');
                      }}
                      handleSearchChange={debouncedAdminSearchChange}
                      moduleId={17}
                    />))

                    }
                  </>
                </Card>
              </div>

              {/* Merchant IP Whitelist */}
              <div className="col-md-6">
                <Card>
                  <Card.Header>
                    <div className="d-flex justify-content-between align-items-center">
                      <span>Merchant IP Whitelist</span>
                      <Form.Switch
                        id="toggle-switch"
                        value={merchantTabActive}
                        checked={merchantTabActive}
                        disabled={!authService.checkAccess(17,'edit')}
                        onClick={() => {
                          console.log("Admin IP Whitelist", !merchantTabActive); 
                          AdminMerchantIPToggle('merchant', !merchantTabActive);
                        }}
                        onChange={(e) => setMerchantTabActive(e.target.checked)}
                      />
                    </div>
                  </Card.Header>
                  <>
                    {merchantTabActive && (loading.merchant ? <div className="d-flex my-5 justify-content-center align-content-center">

                      <BorderSpinner />
                    </div> : (<TableWithPagination
                      data={ipWhitelist.merchant}
                      itemsPerPage={pagination.limit}
                      columns={columnsWithHTML}
                      currentPage={pagination.page}
                      totalData={pagination.totalDocs}
                      pagination={pagination}
                      setPagination={setPagination}
                      addType='modal'
                      redirectUrl={() => {
                        setShow(true);
                        setAddTo('merchant');
                      }}
                      handleSearchChange={debouncedMerchantSearchChange}
                      moduleId={17}
                    />))}
                  </>
                </Card>
              </div>


            </div>
          </Card.Body>
        </Card>
      </div>

      <AddIpWhitelistModal setModalTypeAndId={setModalTypeAndId} modalTypeAndId={modalTypeAndId} show={show} addTo={addTo} onClose={() => {
        setShow(false);
        if (addTo === 'merchant') {
          getList();
        }
        else if (addTo === 'admin') {
          getAdminList();
        }
        setModalTypeAndId({ type: '', id: '' });

      }} />
    </React.Fragment>
  );
}
