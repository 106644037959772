import React, { useState } from "react";
import { Form, Button, Col, Card } from "react-bootstrap";
import { AsyncPaginate } from "react-select-async-paginate";
import { VscEye, VscEyeClosed } from "react-icons/vsc";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../layouts/Sidebar";
import Header from "../../layouts/Header";
import useSkinSwitcher from "../../common/skin";
import MerchantService from "../../service/api/merchant.service";
import { darkModeStyles, darkModeStyles2, lightModeStyles, lightModeStyles2 } from "../../common/reactSelectStyles";
import { useSelector } from "react-redux";
import UserService from "../../service/api/user.service ";
import InfoComponent from "../lottery-management/Info";

const emailRegex = new RegExp(/^([a-zA-Z0-9._-]+)@([a-zA-Z0-9.-]+)\.([a-zA-Z]{2,})$/);

const AddNewUser = () => {
  const { skin, setSkin } = useSkinSwitcher();
  const theme = useSelector((state) => state.ui.skin);

  const merchantService = MerchantService();
  const userService = UserService();
  const navigate = useNavigate();
  const [showError, setShowError] = useState(false);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedMerchant, setSelectedMerchant] = useState(null);

  const [formData, setFormData] = useState({
    merchantId: "",
    name: "",
    password: "",
    confirmPassword: "",
    email: "",
    phone: "",
    role: "",
    type: "user",
    deposit: [{
      commissionType: "percentage",
      commissionRate: 0,
      appliedTo: "first" // New field to specify deposit commission type

    }, {
      commissionType: "percentage",
      commissionRate: 0,
      appliedTo: "all" // New field to specify deposit commission type

    }],
    registration: {
      commissionType: "flat",
      commissionRate: 0,
    }
  });

  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
  });

  const togglePasswordVisibility = (field) => {
    setShowPassword(prev => ({ ...prev, [field]: !prev[field] }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    setErrors(prev => ({ ...prev, [name]: "" }));
  };

  const handleRegistraionChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      registration: {
        ...prev.registration,
        [name]: value
      }
    }));
    setErrors(prev => ({ ...prev, [`registration.${name}`]: "" }));
  };


  const handleDepositChange = (e, index) => {
    const { name, value } = e.target;

    setFormData((prev) => {
      const updatedDeposits = [...prev.deposit];
      updatedDeposits[index] = { ...updatedDeposits[index], [name]: value };
      return { ...prev, deposit: updatedDeposits };
    });

    setErrors((prev) => ({
      ...prev,
      [`deposit.${index}.${name}`]: ""
    }));
  };

  const loadOptions = async (search, loadedOptions, { page }) => {
    try {
      const response = await merchantService.getMerchants({
        page,
        limit: 25,
        searchBy: search,
      });

      if (response.code === 200) {
        return {
          options: response.data?.docs.map(merchant => ({
            value: merchant.id,
            label: merchant.name,
          })),
          hasMore: response.data.hasNextPage,
          additional: { page: page + 1 },
        };
      }
      throw new Error(response.message);
    } catch (error) {
      console.error("Error fetching merchants:", error);
      return {
        options: [],
        hasMore: false,
      };
    }
  };

  const validateForm = () => {
    const validationErrors = {};

    // Name validation
    if (!formData.name?.trim()) {
      validationErrors.name = "Name is required";
    } else if (!/^(?!\d+$)[a-zA-Z0-9\s]+$/.test(formData.name)) {
      validationErrors.name = "Name should be alphanumeric";
    } else if (formData.name?.length > 50) {
      validationErrors.name = "Name must not contain more than 50 characters";
    }

    // Phone validation
    if (!formData.phone?.trim()) {
      validationErrors.phone = "Phone number is required";
    } else if (!/^\d{6,15}$/.test(formData.phone?.trim())) {
      validationErrors.phone = "Phone number should be in between 6 to 15 digits";
    } else if (!/^[2-9]/.test(formData.phone?.trim())) {
      validationErrors.phone = "Please enter a valid phone number";
    }

    // Email validation
    if (!formData.email?.trim()) {
      validationErrors.email = "Email is required";
    } else if (!emailRegex.test(formData.email)) {
      validationErrors.email = "Invalid email address";
    }

    // Password validation
    if (!formData.password?.trim()) {
      validationErrors.password = "Password is required";
    } else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/.test(formData.password)) {
      validationErrors.password = "Password must be at least 8 characters long and contain at least one lowercase letter, one uppercase letter, one number, and one special character";
    }

    // Confirm password validation
    if (!formData.confirmPassword?.trim()) {
      validationErrors.confirmPassword = "Confirm password is required";
    } else if (formData.confirmPassword !== formData.password) {
      validationErrors.confirmPassword = "Passwords do not match";
    }

    if (!formData.merchantId) {
      validationErrors.merchantId = "Merchant is required";
    }
    // Role validation
    if (!formData.role) {
      validationErrors.role = "Role is required";
    }

    // Commission validation for affiliate role
    if (formData.role === 'affiliate') {
      if (!formData.registration.commissionType) {
        validationErrors['registration.commissionType'] = "Commission type is required";
      }
      if (formData.registration.commissionType === 'percentage') {
        if (formData.registration.commissionRate === '') {
          validationErrors['registration.commissionRate'] = "Commission rate is required";
        } else if (formData.registration.commissionRate < 0) {
          validationErrors['registration.commissionRate'] = "Commission rate must be greater than or equal to zero";
        }
      }
      else {
        if (formData.registration.commissionRate === '') {
          validationErrors['registration.commissionRate'] = "Commission amount is required";
        } else if (formData?.registration?.commissionRate < 0 || formData?.registration.commissionRate > 1000000) {
          validationErrors[`registration.commissionRate`] = "Commission amount must be between 0 and 1000000";
        }

      }
    }
    if (formData.role === 'affiliate') {
      formData.deposit.forEach((depositItem, index) => {
        if (!depositItem.commissionType) {
          validationErrors[`deposit.${index}.commissionType`] = "Commission type is required";
        }

        if (depositItem.commissionType === 'percentage') {
          if (depositItem.commissionRate === '') {
            validationErrors[`deposit.${index}.commissionRate`] = "Commission rate is required";
          } else if (depositItem.commissionRate < 0 || depositItem.commissionRate > 100) {
            validationErrors[`deposit.${index}.commissionRate`] = "Commission rate must be between 0 and 100";
          }
        } else {
          if (depositItem.commissionRate === '') {
            validationErrors[`deposit.${index}.commissionRate`] = "Commission rate is required";
          } else if (depositItem.commissionRate < 0 || depositItem.commissionRate > 1000000) {
            validationErrors[`deposit.${index}.commissionRate`] = "Commission rate must be between 0 and 1000000";
          }
        }
      });
    }
    return validationErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setIsSubmitting(true);
      const validationErrors = validateForm();

      if (Object.keys(validationErrors).length === 0) {
        // const formDataToSend = new FormData();
        const formDataToSend = {};

        // Prepare form data
        Object.entries(formData).forEach(([key, value]) => {
          if (key !== 'confirmPassword') {

            if (key === 'deposit' && formData.role === 'affiliate') {
              // formDataToSend.append(key, JSON.stringify(value));
              formDataToSend[key] = value;

            } else if (key !== 'deposit') {
              // formDataToSend.append(key, value);
              formDataToSend[key] = value;
            }
          }
        });

        const res = await userService.createUser(formDataToSend);

        if (res?.code === 200) {
          toast.success(res?.message);
          navigate("/user-management");
        } else {
          toast.error(res?.message);
        }
      } else {
        setShowError(true);
        setErrors(validationErrors);
      }
    } catch (error) {
      console.error(error);
      // toast.error("An error occurred while creating the merchant");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <Sidebar />
      <div className="main main-app p-3 p-lg-4">
        <div className="align-items-center mb-4">
          <Card>
            <Card.Header>
              <h4 className="card-title w-100">Add User</h4>
            </Card.Header>
            <Card.Body>
              <Form className="row" onSubmit={handleSubmit}>
                <Col md={4} className="mt-3">
                  <Form.Group controlId="name">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      isInvalid={showError && !!errors.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col md={4} className="mt-3">
                  <Form.Group controlId="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      isInvalid={showError && !!errors.email}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col md={4} className="mt-3">
                  <Form.Group controlId="phone">
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter phone number"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      isInvalid={showError && !!errors.phone}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.phone}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col md={4} className="mt-3">
                  <Form.Group>
                    <Form.Label>Select Merchant</Form.Label>
                    <AsyncPaginate
                      value={selectedMerchant}
                      loadOptions={loadOptions}
                      onChange={(selectedOption) => {
                        setSelectedMerchant(selectedOption);
                        handleChange({
                          target: {
                            name: "merchantId",
                            value: selectedOption ? selectedOption.value : ""
                          }
                        });
                      }}
                      additional={{ page: 1 }}
                      styles={theme === "dark" ? darkModeStyles2 : lightModeStyles2}
                      placeholder="Select a merchant"
                      isClearable
                    />
                    {errors.merchantId && (
                      <Form.Text className="text-danger">{errors.merchantId}</Form.Text>
                    )}
                  </Form.Group>
                </Col>

                <Col md={4} className="mt-3">
                  <Form.Group controlId="password">
                    <Form.Label>Password</Form.Label>
                    <div style={{ position: "relative" }} className="position-relative">
                      <Form.Control
                        type={showPassword.password ? "text" : "password"}
                        placeholder="Enter password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        isInvalid={showError && !!errors.password}
                      />

                      <span
                        onClick={() => togglePasswordVisibility("password")}
                        className=""

                        style={{
                          position: "absolute",
                          top: "18px",
                          right: "10px",
                          transform: "translateY(-50%)",
                          cursor: "pointer",
                          color: "#6c757d",
                          zIndex: 10000
                        }}
                      >
                        {showPassword.password ? <VscEyeClosed size={20} /> : <VscEye size={20} />}
                      </span>
                      <Form.Control.Feedback type="invalid">
                        {errors.password}
                      </Form.Control.Feedback>
                    </div>
                  </Form.Group>
                </Col>

                <Col md={4} className="mt-3">
                  <Form.Group controlId="confirmPassword">
                    <Form.Label>Confirm Password</Form.Label>
                    <div style={{ position: 'relative' }} className="position-relative">
                      <Form.Control
                        type={showPassword.confirmPassword ? "text" : "password"}
                        placeholder="Confirm password"
                        name="confirmPassword"
                        value={formData.confirmPassword}
                        onChange={handleChange}
                        isInvalid={showError && !!errors.confirmPassword}
                      />
                      <span
                        onClick={() => togglePasswordVisibility("confirmPassword")}
                        style={{
                          position: "absolute",
                          top: "18px",
                          right: "10px",
                          transform: "translateY(-50%)",
                          cursor: "pointer",
                          color: "#6c757d",
                          zIndex: 10000
                        }}
                      >
                        {showPassword.confirmPassword ? <VscEyeClosed size={20} /> : <VscEye size={20} />}
                      </span>
                      <Form.Control.Feedback type="invalid">
                        {errors.confirmPassword}
                      </Form.Control.Feedback>
                    </div>

                  </Form.Group>
                </Col>
                <Col md={4} className="mt-3">
                  <Form.Group controlId="role">
                    <Form.Label>Role Type</Form.Label>
                    <Form.Select
                      name="role"
                      onChange={handleChange}
                      value={formData.role}
                      isInvalid={showError && !!errors.role}
                    >
                      <option value="">Select Type</option>
                      <option value="affiliate">Affiliate</option>
                      <option value="user">User</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {errors.role}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={4} className="mt-3">
                  <Form.Group controlId="role">
                    <Form.Label>User Type</Form.Label>
                    <Form.Select
                      name="type"
                      onChange={handleChange}
                      value={formData.type}
                      isInvalid={showError && !!errors.type}
                    >
                      {/* <option value="">Select Type</option> */}
                      <option value="user">User</option>
                      <option value="demo">Demo</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {errors.type}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                {/* {formData.role === 'affiliate' && (
                  <>
                    <Col md={4} className="mt-3">
                      <Form.Group controlId="commissionType">
                        <Form.Label>Commission Type (Registration)</Form.Label>
                        <InfoComponent message={`Commission is applied on the user's registration.`} />

                        <Form.Select
                          name="commissionType"
                          value={formData.registration.commissionType}
                          onChange={handleRegistraionChange}
                          disabled
                          isInvalid={showError && !!errors['registration.commissionType']}
                        >
                          <option value="percentage">Percentage</option>
                          <option value="flat">Flat</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          {errors['registration.commissionType']}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col md={4} className="mt-3">
                      <Form.Group controlId="commissionRate">
                        <Form.Label>
                          Commission Rate {formData.registration.commissionType === 'percentage' ? '(%)' : ''}
                        </Form.Label>
                        <Form.Control
                          type="number"
                          placeholder={`Enter commission ${formData.registration.commissionType === 'percentage' ? 'percentage' : 'amount'}`}
                          name="commissionRate"
                          value={formData.registration.commissionRate}
                          onChange={handleRegistraionChange}
                          min="0"
                          max={formData.registration.commissionType === 'percentage' ? "100" : "1000000"}
                          step={formData.registration.commissionType === 'percentage' ? "0.1" : "1"}
                          isInvalid={showError && !!errors['registration.commissionRate']}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors['registration.commissionRate']}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    
                  </>
                )} */}
                {formData.role === 'affiliate' && (
                  <>
                    <Col md={4} className="mt-3">
                      <Form.Group controlId="commissionRate">
                        <Form.Label>
                          Commission On Registration (₹)
                        </Form.Label>
                        <Form.Control
                          type="number"
                          placeholder={`Enter commission ${formData.registration.commissionType === 'percentage' ? 'percentage' : 'amount'}`}
                          name="commissionRate"
                          value={formData.registration.commissionRate}
                          onChange={handleRegistraionChange}
                          min="0"
                          max={formData.registration.commissionType === 'percentage' ? "100" : "1000000"}
                          step={formData.registration.commissionType === 'percentage' ? "0.1" : "1"}
                          isInvalid={showError && !!errors['registration.commissionRate']}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors['registration.commissionRate']}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    {formData.deposit.map((item, index) => (
                      <Col md={4} className="mt-3" key={index}>
                        <Form.Group controlId={`commissionType-${index}`}>
                          <Form.Label>Commission Type ({item.appliedTo === 'first' ? 'First Deposit' : 'All Deposits'})
                            <InfoComponent message={`${item.appliedTo === 'first' ? `Commission is applied on the user's first deposit only.` : `Commission is applied on the user's all deposit except first deposit.`} `} />

                          </Form.Label>
                          <Form.Select
                            name="commissionType"
                            disabled
                            value={item.commissionType}
                            onChange={(e) => handleDepositChange(e, index)}
                            isInvalid={showError && !!errors[`deposit.${index}.commissionType`]}
                          >
                            <option value="percentage">Percentage</option>
                            <option value="flat">Flat</option>
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            {errors[`deposit.${index}.commissionType`]}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId={`commissionRate-${index}`} className="mt-3">
                          <Form.Label>
                            Commission Rate {item.commissionType === 'percentage' ? '(%)' : ''}
                          </Form.Label>
                          <Form.Control
                            type="number"
                            placeholder={`Enter commission ${item.commissionType === 'percentage' ? 'percentage' : 'amount'}`}
                            name="commissionRate"
                            value={item.commissionRate}
                            onChange={(e) => handleDepositChange(e, index)}
                            min="0"
                            max={item.commissionType === 'percentage' ? "100" : "1000000"}
                            step={item.commissionType === 'percentage' ? "0.1" : "1"}
                            isInvalid={showError && !!errors[`deposit.${index}.commissionRate`]}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors[`deposit.${index}.commissionRate`]}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    ))}


                  </>
                )}


                <div className="d-flex justify-content-center mt-5">
                  <Button
                    disabled={isSubmitting}
                    variant="primary"
                    type="submit"
                    className="mx-4"
                  >
                    {isSubmitting ? 'Please wait...' : 'Submit'}
                  </Button>
                  <Button
                    disabled={isSubmitting}
                    variant="danger"
                    type="button"
                    onClick={() => navigate("/user-management")}
                  >
                    Cancel
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddNewUser;