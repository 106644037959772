
import createAxiosInstance from "../api.instance";
import { useDispatch } from "react-redux";
import { endApiRequest, startApiRequest } from "../../Redux/Action/loader.action";


const ThemeService = () => {
  const bearerToken = localStorage.getItem("token"); // Retrieve the bearer token from localStorage
  const dispatch = useDispatch();
  const axiosInstance = createAxiosInstance(bearerToken);
  const addTheme = async (requestData) => {
    dispatch(startApiRequest());
    return await axiosInstance
      .post(`/admin/theme`, requestData, { headers: {} })
      .then((response) => {
        dispatch(endApiRequest());
        // console.log("created =============", response);
        return response.data; // Return response data
      })
      .catch((error) => {
        dispatch(endApiRequest());
        console.error("Creation failed:", error);
        //  return error.response.data; // Throw error to be caught by the caller
      });
  };
  const getTheme = async ({ page, limit, searchBy,merchantId }) => {
    dispatch(startApiRequest());
    return await axiosInstance
      // /admin/game-setting?page=1&limit=10&searchBy=&merchantId=${merchantId}
      .get(`/admin/theme?page=${page}&limit=${limit}&searchBy=${searchBy}`, { headers: {} })
      .then((response) => {
        dispatch(endApiRequest());
        // console.log(" =============", response);
        return response.data; // Return response data
      })
      .catch((error) => {
        dispatch(endApiRequest());
        console.error("Failed to fetch:", error);
        //  return error.response.data; // Throw error to be caught by the caller
      });
  };
  const updateTheme = async (id, requestData) => {
    return await axiosInstance
      // admin/game-setting/667d505a16490b9f26f75ded
      .patch(`/admin/theme/${id}`, requestData, { headers: {} })
      .then((response) => {
        console.log("updated =============", response);
        return response.data; // Return response data
      })
      .catch((error) => {
        console.error("Updation failed:", error);
        //  return error.response.data; // Throw error to be caught by the caller
      });
  };

  // /admin/game/list/dropdown
  const deleteTheme = async (id) => {
    return await axiosInstance
      .delete(`/admin/theme/${id}`, { headers: {} })
      .then((response) => {
        console.log("Registration =============", response);
        return response.data; // Return response data
      })
      .catch((error) => {
        console.error("Registration failed:", error);
        //  return error.response.data; // Throw error to be caught by the caller
      });
  };

  
  const getThemeById = async (id) => {
    return await axiosInstance
      // admin/game-setting/667d505a16490b9f26f75ded
      .get(`/admin/theme/${id}`, { headers: {} })
      .then((response) => {
        // console.log("F =============", response);
        return response.data; // Return response data
      })
      .catch((error) => {
        console.error("Failed to fetch:", error);
        //  return error.response.data; // Throw error to be caught by the caller
      });
  };


  return {
    addTheme,
    updateTheme,
    getTheme,
      getThemeById,
      deleteTheme
    // deleteGame
  };
};

export default ThemeService;
