import React, { useEffect, useState, useCallback } from "react";
import Header from "../../layouts/Header";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import TableWithPagination from "../../common/table";
import useSkinSwitcher from "../../common/skin";
import RoleService from "../../service/api/role.service";
import AuthService from "../../service/api/auth.service";
import useSocketService from "../../service/api/socket.service";
import Dropdown from "react-bootstrap/Dropdown";
import { Link, useNavigate } from "react-router-dom";
import "./role.css";
import Footer from "../../layouts/Footer";
// import { Link } from "react-router-dom";
import { Button, Card, Row, Col, Nav, ListGroup, Badge } from "react-bootstrap";
import PageBreadcrumb from "../../common/PageBreadcrum";
import Sidebar from "../../layouts/Sidebar";
import { toast } from "react-toastify";
import globalSwal from "../../common/swalService";
import _ from "lodash";

export default function RoleList() {
  const roleService = RoleService();
  const authService = AuthService();
  const { emit, on, off } = useSocketService();
  const navigate = useNavigate();
  const { skin, setSkin } = useSkinSwitcher();
  const [roleList, setRoleList] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 25,
    pages: "",
    searchBy: "",
  });
  const [columns, setColumns] = useState(["name", "status", "id"]);
  const [roleOption, setRoleOption] = useState([{
    label: 'Active', value: 1,
    label: 'InActive', value: 0,

  }]);
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "#141926", // Background color
      color: "#fff", // Text color
      borderColor: state.isFocused ? "#fff" : "#444", // Border color
      boxShadow: state.isFocused ? "0 0 0 1px #fff" : "none", // Box shadow
      "&:hover": {
        borderColor: "#141926", // Border color on hover
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#555"
        : state.isFocused
          ? "#666"
          : "#141926", // Option background color
      color: "#fff", // Option text color
      "&:hover": {
        backgroundColor: "#555", // Option background color on hover
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#333", // Menu background color
      color: "#fff", // Menu text color
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#fff", // Single value text color
    }),
  };
  useEffect(() => {
    console.log('========Call ROLE_LIST=======')
    let user = JSON.parse(localStorage.getItem("user"));
    emit("isOnline", { userId: user.id, userName: user.email });
    getList();
    // Listen for 'isOnlineStatus' event once after 'init' event is emitted
    const handleIsOnlineStatus = (data) => {
      console.log("Received isOnlineStatus:", data);
      // Unsubscribe from 'isOnlineStatus' event after receiving it once
      off("isOnlineStatus", handleIsOnlineStatus);
    };
    const handleroleUpdate = (response) => {
      console.log("Received yourRoleUpdated:", response);
      toast.warn(response.msg);
      let userDetail = JSON.parse(localStorage.getItem("user"));
      if (userDetail) {
        userDetail = { ...userDetail, role: response.data };
      }
      localStorage.setItem("user", JSON.stringify(userDetail));
      // Unsubscribe from 'isOnlineStatus' event after receiving it once
      off("yourRoleUpdated", handleIsOnlineStatus);
    };
    on("isOnlineStatus", handleIsOnlineStatus);
    on("yourRoleUpdated", handleroleUpdate);

    // Return a cleanup function to unsubscribe from the event when component unmounts
    // return () => {
    //   off('isOnlineStatus', handleIsOnlineStatus);
    // };
  }, []);

  const getList = useCallback(async () => {
    try {
      const res = await roleService.getRole({
        page: pagination.page,
        limit: pagination.limit,
        searchBy: pagination?.searchBy,
      });
      if (res?.code === 200) {
        console.log(res, "Response from role management");
        setRoleList(res?.data?.docs);
        setPagination((prevPagination) => ({
          ...prevPagination,
          pages: res.data.pages,
          totalDocs: res.data.totalDocs,
        }));
      } else {
        console.error(res?.message);
        // toast.error(res?.message);
      }
    } catch (error) {
      console.error("Error fetching data", error);
      // console.error(res?.message);
      // toast.error(res?.message);
    }
  }, [pagination.page, pagination.limit, pagination.searchBy]);

  useEffect(() => {
    getList();
    console.log("searchBy", pagination);
  }, [pagination.page,pagination.limit, pagination.searchBy, getList]);

  // Define HTML for status badges
  const getStatusBadge = (status) => {
    if (status === 1) {
      return (
        <Badge bg={"success"} className="badge badge-success">
          Active
        </Badge>
      );
    } else if (status === 0) {
      return (
        <Badge bg={"pink"} className="badge badge-danger">
          Inactive
        </Badge>
      );
    }
  };

  // Define HTML for action column
  const getActionDropdown = (id, status,moduleId) => {
    console.log(id, status, "from getActionDropdown")
    return (
      <Dropdown>
        <Dropdown.Toggle as="span" className="custom-dropdown-toggle">
          <i className="ri-more-2-fill"></i>
        </Dropdown.Toggle>

        <Dropdown.Menu style={{}} >
          {authService.checkAccess(1, "edit") && (
            <Dropdown.Item onClick={() => handleUpdate("view", id)}>
              View
            </Dropdown.Item>
          )}
          {authService.checkAccess(1, "edit") && (
            <Dropdown.Item onClick={() => handleUpdate("edit", id)}>
              Edit
            </Dropdown.Item>
          )}
          {authService.checkAccess(1, "edit") && (
            <Dropdown.Item onClick={() => handleStatusUpdate(id)}>
              {status === 1 ? "Deactivate" : "Activate"}
            </Dropdown.Item>
          )}
          {authService.checkAccess(1, "edit") && (
            <Dropdown.Item onClick={() => handleDelete(id)}>
              Delete
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const handleUpdate = (type, id) => {
    const state = { id: id };
    if (type == "edit") {
      navigate(`/role-management/edit`, { state });
    } else {
      navigate(`/role-management/view`, { state });
    }
  };
  const handleStatusUpdate = async (id) => {
    // const state = {id: id}
    // navigate(`/role-management/edit`, {state})
    const role = roleList.filter((item) => item.id === id);

    globalSwal.confirm({
      title: "Are you sure?",
      text: "Do you really want to change the current status?",
      onConfirm: async () => {


        const res = await roleService.updateRoleStatus(id, {
          status: role[0].status == 1 ? 0 : 1,
        });
        if (res?.code === 200) {
          globalSwal.success({
            title: "Success!",
            text: "Status has been changed successfully.",
          });
          toast.success(res?.message || "Status updated successfully.");
          getList();
        } else {
          toast.error(res?.message || "Failed to update status.");
        }

      },
    });


  };

  const handleDelete = async (id) => {
    // const state = {id: id}
    // navigate(`/role-management/edit`, {state})
    const role = roleList.filter((item) => item.id === id);
   
    globalSwal.confirm({
      title: "Are you sure?",
      text: "Do you really want to delete?",
      onConfirm: async () => {


        const res = await roleService.deleteRole(id);
        if (res?.code === 200) {
          globalSwal.success({
            title: "Success!",
            text: "Role has been deleted successfully.",
          });
          toast.success(res?.message);
          getList();
          setPagination({ ...pagination, page: 1 })
        } else {
          toast.error(res?.message || "Failed to delete.");
        }

      },
    });
  };


  const debouncedSearch = useCallback(_.debounce((value) => {
      setPagination((prevPagination) => ({
        ...prevPagination,
        searchBy: value,
      }));
    }, 1000), []);
    const handleSearchChange = (event) => {
      // console.log("searchBy==")
      const value = event.target.value.trim();
      // if (value.length > 3 || value.length === 0) {
      debouncedSearch(value);
      // }
    };
  // const handleSearchChange = (event) => {
  //   const value = event.target.value.trim();
  //   // if (value.length > 3 || value.length === 0) {
  //   setPagination((prevPagination) => ({
  //     ...prevPagination,
  //     searchBy: value,
  //   }));
  //   // }
  // };

  // Map the columns to include HTML for status badges
  const columnsWithHTML = columns.map((column) => {
    if (column.toLowerCase() === "status") {
      return {
        header: "Status",
        accessor: column,
        Cell: ({ value }) => getStatusBadge(value), // Render status badge based on the value
      };
    } else if (column.toLowerCase() === "id") {
      return {
        header: " ",
        accessor: column,
        Cell: ({ value, row }) => getActionDropdown(value, row?.status,1), // Render action dropdown based on the value
      };
    }
    else if (column.toLowerCase() === "name") {
      return {
        header: "Name",
        accessor: column,
        Cell: ({ value }) => {
          // console.log("value-transaction", value, column);
          return value || "N/A";
        },
      };
    }
    else {
      return column;
    }
  });

  const handleRoleChange = (selectedOption) => {
    setPagination({
      ...pagination,
      role: selectedOption.value
    })
  }

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <Sidebar />
      <div className="main main-app p-3 p-lg-4">
        <Card className="">
          <div className=" align-items-center">
            <Card.Header>
              {/* <h1>Role Management</h1> */}
              <PageBreadcrumb title="Roles" />

            </Card.Header>
            <TableWithPagination
              data={[...roleList]}
              itemsPerPage={pagination?.limit}
              columns={columnsWithHTML}
              currentPage={pagination?.page}
              totalPages1={pagination?.totalPages}
              totalData={pagination?.totalDocs}
              pagination={pagination}
              setPagination={setPagination}
              handleSearchChange={handleSearchChange}
              moduleId={1}
              redirectUrl={"/role-management/add"}
            />
          </div>
        </Card>


      </div>
    </React.Fragment>
  );
}
