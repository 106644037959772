import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import userAvatar from "../assets/img/img1.jpg";
import {
    dashboardMenu,
    applicationsMenu,
    pagesMenu,
    uiElementsMenu
} from "../data/Menu";
import Logo from "../common/logo";
import './Sidebar.css'

const checkAccess = (moduleId, type) => {
    try {
        const userData = localStorage.getItem("user");
        if (!userData) return true;
        
        const user = JSON.parse(userData);
        const data = user.role;
        const loginDetails = user;
        
        if (data && loginDetails.type !== 'superadmin') {
            for (let i = 0; i < data.resource.length; i++) {
                if (data.resource[i].moduleId == moduleId) {
                    return data.resource[i].permissions.includes(type) ? true : false;
                }
            }
        } else {
            return true;
        }
    } catch (error) {
        console.error("Error checking access:", error);
        return true;
    }
}

const sidebarShow = (e) => {
    document.querySelector("body").classList.toggle("sidebar-show");
}
  
export default class Sidebar extends Component {
    constructor(props) {
        super(props);
        this._scrollBarRef = null;
        this._isMounted = false;
    }
    
    toggleFooterMenu = (e) => {
        e.preventDefault();
        let parent = e.target.closest(".sidebar");
        if (parent) {
            parent.classList.toggle("footer-menu-show");
        }
    }

    // Safe method to update scroll
    safeUpdateScroll = () => {
        // Only update if component is mounted and ref exists
        if (this._isMounted && this._scrollBarRef && typeof this._scrollBarRef.updateScroll === 'function') {
            try {
                // Add a small delay to ensure DOM updates
                setTimeout(() => {
                    if (this._isMounted && this._scrollBarRef) {
                        this._scrollBarRef.updateScroll();
                    }
                }, 150);
            } catch (error) {
                console.error("Error updating scroll:", error);
            }
        }
    }

    componentDidMount() {
        this._isMounted = true;
        // Use the safe method instead
        this.safeUpdateScroll();
    }
    
    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        return (
            <div className="sidebar" style={{ opacity: 1 }}>
                <div className="sidebar-header">
                    <Logo />
                </div>
                <div className="sidebar-body-container">
                    <PerfectScrollbar 
                        className="sidebar-body" 
                        ref={ref => {
                            this._scrollBarRef = ref;
                            // When ref is updated, call safeUpdateScroll
                            if (ref && this._isMounted) {
                                this.safeUpdateScroll();
                            }
                        }}
                        options={{
                            suppressScrollX: true,
                            wheelPropagation: false
                        }}
                    >
                        <SidebarMenu 
                            checkAccess={checkAccess} 
                            onUpdateSize={this.safeUpdateScroll}
                        />
                    </PerfectScrollbar>
                </div>
            </div>
        )
    }
}

class SidebarMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openSubmenu: this.getStoredSubmenuState(), // Retrieve stored state
        };
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        // Add a small delay to ensure the component is fully mounted
        setTimeout(() => {
            if (this._isMounted && this.props.onUpdateSize) {
                this.props.onUpdateSize();
            }
        }, 300);
    }
    
    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(prevProps, prevState) {
        // Update scroll when submenu state changes
        if (this._isMounted && prevState.openSubmenu !== this.state.openSubmenu && this.props.onUpdateSize) {
            // Add a small delay to ensure DOM updates
            setTimeout(() => {
                if (this._isMounted && this.props.onUpdateSize) {
                    this.props.onUpdateSize();
                }
            }, 150);
        }
    }

    getStoredSubmenuState = () => {
        try {
            const storedState = localStorage.getItem("openSubmenu");
            return storedState ? JSON.parse(storedState) : null;
        } catch (error) {
            console.error("Error retrieving stored submenu state:", error);
            return null;
        }
    }

    storeSubmenuState = (key) => {
        try {
            localStorage.setItem("openSubmenu", JSON.stringify(key));
        } catch (error) {
            console.error("Error storing submenu state:", error);
        }
    }

    toggleSubMenu = (e, key) => {
        e.preventDefault();
        this.setState(prevState => {
            const newOpenSubmenu = prevState.openSubmenu === key ? null : key;
            this.storeSubmenuState(newOpenSubmenu);
            return { openSubmenu: newOpenSubmenu };
        }, () => {
            // Call update size after state has been updated
            if (this._isMounted && this.props.onUpdateSize) {
                this.props.onUpdateSize();
            }
        });
    }

    handleNavClick = () => {
        sidebarShow();
        if (this._isMounted && this.props.onUpdateSize) {
            setTimeout(() => {
                if (this._isMounted && this.props.onUpdateSize) {
                    this.props.onUpdateSize();
                }
            }, 100);
        }
    }

    populateMenu = (item) => {
        const { checkAccess } = this.props;

        const menu = item.map((m, key) => {
            const hidden = checkAccess(m.moduleId, 'list');
            let sm;
            
            if (m.submenu) {
                sm = m.submenu.map((sm, subKey) => {
                    return (
                        hidden && 
                        <NavLink 
                            onClick={this.handleNavClick} 
                            to={sm.link} 
                            className="nav-sub-link" 
                            key={subKey}
                        >
                            {sm.label}
                        </NavLink>
                    );
                });
            }

            const isSubmenuOpen = this.state.openSubmenu === key;

            return (
                <li key={key} className={`nav-item ${isSubmenuOpen ? 'show' : ''}`}>
                    {(!sm) ? (
                        hidden && 
                        <NavLink 
                            onClick={this.handleNavClick} 
                            to={m.link} 
                            className="nav-link"
                        >
                            <i className={m.icon}></i> <span>{m.label}</span>
                        </NavLink>
                    ) : (
                        hidden && 
                        <div 
                            onClick={(e) => this.toggleSubMenu(e, key)} 
                            className="nav-link has-sub"
                        >
                            <i className={m.icon}></i> <span>{m.label}</span>
                        </div>
                    )}
                    {m.submenu && <nav className="nav nav-sub">{sm}</nav>}
                </li>
            );
        });

        return (
            <ul className="nav nav-sidebar">
                {menu}
            </ul>
        );
    }

    render() {
        return (
            <React.Fragment>
                <div className="nav-group show">
                    {this.populateMenu(dashboardMenu)}
                </div>
            </React.Fragment>
        );
    }
}

window.addEventListener("click", function (e) {
    // Close sidebar footer menu when clicked outside of it
    let tar = e.target;
    let sidebar = document.querySelector(".sidebar");
    if (!tar.closest(".sidebar-footer") && sidebar) {
        sidebar.classList.remove("footer-menu-show");
    }

    // Hide sidebar offset when clicked outside of sidebar
    if (!tar.closest(".sidebar") && !tar.closest(".menu-link")) {
        document.querySelector("body").classList.remove("sidebar-show");
    }
});

window.addEventListener("load", function () {
    let skinMode = localStorage.getItem("sidebar-skin");
    let HTMLTag = document.querySelector("html");
    if (skinMode) {
        HTMLTag.setAttribute("data-sidebar", skinMode);
    }
});




// import React, { Component } from "react";
// import { Link, NavLink } from "react-router-dom";
// import PerfectScrollbar from "react-perfect-scrollbar";
// import userAvatar from "../assets/img/img1.jpg";
// import {
//     dashboardMenu,
//     applicationsMenu,
//     pagesMenu,
//     uiElementsMenu
// } from "../data/Menu";
// import Logo from "../common/logo";
// import './Sidebar.css'

// const checkAccess = (moduleId, type) => {
//     // console.log("Checking access",moduleId,type)
//     var data = JSON.parse(localStorage.getItem("user")).role;
//     var loginDetails = JSON.parse(localStorage.getItem("user"));
//     if (data && loginDetails.type !== 'superadmin') {
//         console.log("data access",data)
//         for (let i = 0; i < data.resource.length; i++) {
//             if (data.resource[i].moduleId == moduleId) {
//                 return data.resource[i].permissions.includes(type) ? true : false;
//             }
//         }
//     } else {
//         return true;
//     }
// }

// const sidebarShow = (e) => {
//     document.querySelector("body").classList.toggle("sidebar-show");
// }
  
// export default class Sidebar extends Component {
//     toggleFooterMenu = (e) => {
//         e.preventDefault();
//         let parent = e.target.closest(".sidebar");
//         parent.classList.toggle("footer-menu-show");
//     }

//     componentDidMount() {
//         // Ensure the scrollbar is set up properly
//         if (this._scrollBarRef) {
//             this._scrollBarRef?.updateScroll();
//         }
//     }

//     render() {
//         return (
//             <div className="sidebar" style={{ opacity: 1 }}>
//                 <div className="sidebar-header">
//                     <Logo />
//                 </div>
//                 <div className="sidebar-body-container">
//                     <PerfectScrollbar 
//                         className="sidebar-body" 
//                         ref={ref => this._scrollBarRef = ref}
//                         options={{
//                             suppressScrollX: true,
//                             wheelPropagation: false
//                         }}
//                     >
//                         <SidebarMenu 
//                             checkAccess={checkAccess} 
//                             onUpdateSize={() => this._scrollBarRef?.updateScroll()}
//                             // onUpdateSize={() => {
//                             //     if (this._scrollBarRef) {
//                             //         // Add a small delay to ensure DOM updates
//                             //         setTimeout(() => {
//                             //             this._scrollBarRef.updateScroll();
//                             //         }, 100);
//                             //     }
//                             // }} 
//                         />
//                     </PerfectScrollbar>
//                 </div>
//             </div>
//         )
//     }
// }

// class SidebarMenu extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             openSubmenu: this.getStoredSubmenuState(), // Retrieve stored state
//         };
//     }

//     componentDidMount() {
//         // Update scroll when component mounts
//         if (this.props.onUpdateSize) {
//             this.props?.onUpdateSize();
//         }
//     }

//     componentDidUpdate(prevProps, prevState) {
//         // Update scroll when submenu state changes
//         if (prevState.openSubmenu !== this.state.openSubmenu && this.props.onUpdateSize) {
//             this.props.onUpdateSize();
//         }
//     }

//     getStoredSubmenuState = () => {
//         const storedState = localStorage.getItem("openSubmenu");
//         return storedState ? JSON.parse(storedState) : null;
//     }

//     storeSubmenuState = (key) => {
//         localStorage.setItem("openSubmenu", JSON.stringify(key));
//     }

//     toggleSubMenu = (e, key) => {
//         e.preventDefault();
//         this.setState(prevState => {
//             const newOpenSubmenu = prevState.openSubmenu === key ? null : key;
//             this.storeSubmenuState(newOpenSubmenu);
//             return { openSubmenu: newOpenSubmenu };
//         }, () => {
//             // Call update size after state has been updated
//             if (this.props.onUpdateSize) {
//                 this.props.onUpdateSize();
//             }
//         });
//     }

//     populateMenu = (item) => {
//         const { checkAccess } = this.props;

//         const menu = item.map((m, key) => {
//             const hidden = checkAccess(m.moduleId, 'list');
//             let sm;
            
//             if (m.submenu) {
//                 sm = m.submenu.map((sm, subKey) => {
//                     return (
//                         hidden && 
//                         <NavLink 
//                             onClick={() => {
//                                 sidebarShow();
//                                 if (this.props.onUpdateSize) {
//                                     this.props.onUpdateSize();
//                                 }
//                             }} 
//                             to={sm.link} 
//                             className="nav-sub-link" 
//                             key={subKey}
//                         >
//                             {sm.label}
//                         </NavLink>
//                     );
//                 });
//             }

//             const isSubmenuOpen = this.state.openSubmenu === key;

//             return (
//                 <li key={key} className={`nav-item ${isSubmenuOpen ? 'show' : ''}`}>
//                     {(!sm) ? (
//                         hidden && 
//                         <NavLink 
//                             onClick={() => {
//                                 sidebarShow();
//                                 if (this.props.onUpdateSize) {
//                                     this.props.onUpdateSize();
//                                 }
//                             }} 
//                             to={m.link} 
//                             className="nav-link"
//                         >
//                             <i className={m.icon}></i> <span>{m.label}</span>
//                         </NavLink>
//                     ) : (
//                         hidden && 
//                         <div 
//                             onClick={(e) => this.toggleSubMenu(e, key)} 
//                             className="nav-link has-sub"
//                         >
//                             <i className={m.icon}></i> <span>{m.label}</span>
//                         </div>
//                     )}
//                     {m.submenu && <nav className="nav nav-sub">{sm}</nav>}
//                 </li>
//             );
//         });

//         return (
//             <ul className="nav nav-sidebar">
//                 {menu}
//             </ul>
//         );
//     }

//     render() {
//         return (
//             <React.Fragment>
//                 <div className="nav-group show">
//                     {this.populateMenu(dashboardMenu)}
//                 </div>
//             </React.Fragment>
//         );
//     }
// }

// window.addEventListener("click", function (e) {
//     // Close sidebar footer menu when clicked outside of it
//     let tar = e.target;
//     let sidebar = document.querySelector(".sidebar");
//     if (!tar.closest(".sidebar-footer") && sidebar) {
//         sidebar.classList.remove("footer-menu-show");
//     }

//     // Hide sidebar offset when clicked outside of sidebar
//     if (!tar.closest(".sidebar") && !tar.closest(".menu-link")) {
//         document.querySelector("body").classList.remove("sidebar-show");
//     }
// });

// window.addEventListener("load", function () {
//     let skinMode = localStorage.getItem("sidebar-skin");
//     let HTMLTag = document.querySelector("html");
//     if (skinMode) {
//         HTMLTag.setAttribute("data-sidebar", skinMode);
//     }
// });






















// import React, { Component } from "react";
// import { Link, NavLink } from "react-router-dom";
// import PerfectScrollbar from "react-perfect-scrollbar";
// import userAvatar from "../assets/img/img1.jpg";
// import {
//     dashboardMenu,
//     applicationsMenu,
//     pagesMenu,
//     uiElementsMenu
// } from "../data/Menu";
// import Logo from "../common/logo";
// import './Sidebar.css'
// const checkAccess = (moduleId, type) => {
//     // console.log("Checking access",moduleId,type)
//     var data = JSON.parse(localStorage.getItem("user")).role;
//     var loginDetails = JSON.parse(localStorage.getItem("user"));
//     if (data && loginDetails.type !== 'superadmin') {
//         console.log("data access",data)
//         for (let i = 0; i < data.resource.length; i++) {
//             if (data.resource[i].moduleId == moduleId) {
//                 return data.resource[i].permissions.includes(type) ? true : false;
//             }
//         }
//     } else {
//         return true;
//     }
// }
// const sidebarShow = (e) => {
//     document.querySelector("body").classList.toggle("sidebar-show");
//   }
  
// export default class Sidebar extends Component {
//     toggleFooterMenu = (e) => {
//         e.preventDefault();
//         let parent = e.target.closest(".sidebar");
//         parent.classList.toggle("footer-menu-show");
//     }

//     render() {
//         return (
//             <div className="sidebar" style={{ opacity: 1 }}>
//                 <div className="sidebar-header">
//                     <Logo />
//                 </div>
//                 <div className="sidebar-body-container">

//                 <PerfectScrollbar className="sidebar-body" ref={ref => this._scrollBarRef = ref}>
//                     <SidebarMenu checkAccess={checkAccess}  options={{
//                             suppressScrollX: true,
//                             wheelPropagation: false
//                         }} onUpdateSize={() => this._scrollBarRef.updateScroll()} />
//                 </PerfectScrollbar>
//                 </div>
               
//             </div>
//         )
//     }
// }

// class SidebarMenu extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             openSubmenu: this.getStoredSubmenuState(), // Retrieve stored state
//         };
//     }

//     getStoredSubmenuState = () => {
//         const storedState = localStorage.getItem("openSubmenu");
//         return storedState ? JSON.parse(storedState) : null;
//     }

//     storeSubmenuState = (key) => {
//         localStorage.setItem("openSubmenu", JSON.stringify(key));
//     }

//     toggleSubMenu = (e, key) => {
//         e.preventDefault();
//         this.setState(prevState => {
//             const newOpenSubmenu = prevState.openSubmenu === key ? null : key;
//             this.storeSubmenuState(newOpenSubmenu);
//             return { openSubmenu: newOpenSubmenu };
//         }, this.props.onUpdateSize);
//     }

//     populateMenu = (item) => {
//         const { checkAccess } = this.props;
//         // console.log(item,"hidddennnnnnnnnnnnn")

//         const menu = item.map((m, key) => {
//             const hidden = checkAccess(m.moduleId, 'list');
//             let sm;
//             console.log(m,"hidddennnnnnnnnnnnn")
//             if (m.submenu) {
//                 sm = m.submenu.map((sm, subKey) => {
//                     return (
//                         hidden && <NavLink onClick={()=>sidebarShow()} to={sm.link} className="nav-sub-link" key={subKey}>{sm.label}</NavLink>
//                     );
//                 });
//             }

//             const isSubmenuOpen = this.state.openSubmenu === key;

//             return (
//                 <li key={key} className={`nav-item ${isSubmenuOpen ? 'show' : ''}`}>
//                     {(!sm) ? (
//                         hidden && <NavLink onClick={()=>sidebarShow()} to={m.link} className="nav-link"><i className={m.icon}></i> <span>{m.label}</span></NavLink>
//                     ) : (
//                         hidden && <div onClick={(e) => this.toggleSubMenu(e, key)} className="nav-link has-sub"><i className={m.icon}></i> <span>{m.label}</span></div>
//                     )}
//                     {m.submenu && <nav className="nav nav-sub">{sm}</nav>}
//                 </li>
//             );
//         });

//         return (
//             <ul className="nav nav-sidebar">
//                 {menu}
//             </ul>
//         );
//     }

//     render() {
//         return (
//             <React.Fragment>
//                 <div className="nav-group show">
//                     {this.populateMenu(dashboardMenu)}
//                 </div>
               
//             </React.Fragment>
//         );
//     }
// }

// window.addEventListener("click", function (e) {
//     // Close sidebar footer menu when clicked outside of it
//     let tar = e.target;
//     let sidebar = document.querySelector(".sidebar");
//     if (!tar.closest(".sidebar-footer") && sidebar) {
//         sidebar.classList.remove("footer-menu-show");
//     }

//     // Hide sidebar offset when clicked outside of sidebar
//     if (!tar.closest(".sidebar") && !tar.closest(".menu-link")) {
//         document.querySelector("body").classList.remove("sidebar-show");
//     }
// });

// window.addEventListener("load", function () {
//     let skinMode = localStorage.getItem("sidebar-skin");

//     let HTMLTag = document.querySelector("html");

//     if (skinMode) {
//         HTMLTag.setAttribute("data-sidebar", skinMode);
//     }
// });
