import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import Footer from "../../layouts/Footer";
import Header from "../../layouts/Header";
import Sidebar from "../../layouts/Sidebar";
import DashboardService from "../../service/api/dashboard.service";
import DateFilter from "./date-filter";
import { endOfMonth, format, startOfMonth } from "date-fns";
import cronParser from 'cron-parser';
import AdminCronSettings from "./AdminCronSetting";
import useSkinSwitcher from "../../common/skin";
import { useSelector } from "react-redux";
import './dateFilter.css'
import { NumericFormat } from "react-number-format";
import { Loader } from "../../common/Spinner";
import MerchantFilter from "./merchant-filter";
import moment from "moment";

export default function BetStatistics() {
    const dashboardService = DashboardService();
    const [statistics, setStatistics] = useState(null);
    const today = new Date();
    const initialStartDate = startOfMonth(today);
    const initialEndDate = endOfMonth(today);
    const skin = useSelector((state) => state.ui.skin);
    const [merchantId, setMerchantId] = useState('')

    // Using useRef to persist the dateRange between renders
    // const [dateRange, setDateRange] = useState({
    //     startDate: format(initialStartDate, 'yyyy-MM-dd'),
    //     endDate: format(initialEndDate, 'yyyy-MM-dd')
    // });
    const [dateRange, setDateRange] = useState({
        startDate: format(today, 'yyyy-MM-dd'),
        endDate: format(today, 'yyyy-MM-dd')
    })

    const [cronExpression, setCronExpression] = useState(localStorage.getItem('cronExpression') || '');
    const timeoutId = useRef(null);

    useEffect(() => {
        localStorage.setItem('cronExpression', cronExpression);
        scheduleFetch();
        getStatistics();
        return () => {
            if (timeoutId.current) {
                clearTimeout(timeoutId.current);
            }
        };
    }, [cronExpression, dateRange, merchantId]);

    // useEffect(() => {
    //     getStatistics();
    //     console.log("Date range updated:", dateRangeRef.current);
    // }, []);  // Notice that dateRangeRef.current is not in dependency array

    const scheduleFetch = () => {
        try {
            const adjustedCron = cronExpression.split(' ').slice(1, 6).join(' ');
            const interval = cronParser.parseExpression(adjustedCron);
            const now = new Date();
            const nextExecution = interval.next().toDate();
            const delay = nextExecution - now;

            if (delay > 0) {
                const id = setTimeout(() => {
                    getStatistics();
                    scheduleFetch();
                }, delay);


                timeoutId.current = id;
            }
        } catch (error) {
            console.error(error);
        }
    };

    const getStatistics = async () => {
        try {
            const { startDate, endDate } = dateRange;
            console.log(startDate, "start date");

            if (!startDate || !endDate) {
                console.error("Invalid dateRange");
                return;
            }
            const payload = {
                ...dateRange,
                merchantId: merchantId
            }
            const res = await dashboardService.getBetStatistics(payload);
            setStatistics(res?.data);
        } catch (error) {
            console.error("Error fetching bet statistics", error);
        }
    };

    const handleDateChange = (range) => {
        setDateRange(range);
    };


    const axisColor = skin == "dark" ? 'rgba(255, 255, 255, 0.6)' : 'rgb(224, 224, 224)';
    const labelColor = skin == "dark" ? 'rgba(255, 255, 255, 0.6)' : '#41505f';


    const betOverTimeData = statistics?.betsOverTime?.map((item) => {

        const timestamp = new Date(item._id).getTime();; // Convert dateTime to timestamp
        //    const timestamp= moment(item._id).format("MMMM Do YYYY, h:mm:ss a")
        const value = Number(item.count); // Ensure count is a number
        // if (isNaN(timestamp) || isNaN(value)) {
        //     console.warn("Invalid data point:", { dateTime: item._id, count: item.count });
        // }
        return {
            x: timestamp,
            y: value,
        };
    }).filter(point => !isNaN(point.x) && !isNaN(point.y)) || [];


    const betsOverTimeData = {
        series: [{
            name: "Bets Placed",
            data: betOverTimeData
        }],
        options: {
            chart: {
                height: 350,
                type: 'line',
                toolbar: {
                    autoSelected: 'zoom',
                    show: true, // Ensure the toolbar is enabled
                    tools: {
                        download: true, // Enable the download tool
                    },
                    export: {
                        csv: {
                            filename: 'Bets Over Month', // Custom file name for CSV export
                            headerCategory: 'Date & Time', // Custom header name for category (X-axis)
                            columnHeaders: ['Date & Time', 'Bet Placed'], // Explicitly set headers for each column
                            dateFormatter(timestamp) { // Custom date format for CSV export
                                return new Date(timestamp).toLocaleString();
                            }
                        },
                        svg: {
                            filename: 'Bets Over Month', // Custom file name for SVG export
                            width: 800, // Custom width
                            height: 600, // Custom height
                            backgroundColor: '#f0f0f0', // Custom background color
                        },
                        png: {
                            filename: 'Bets Over Month', // Custom file name for PNG export
                            width: 800, // Custom width
                            height: 600, // Custom height
                            backgroundColor: '#000000', // Custom background color
                        }
                    },
                },
            },
            stroke: {
                curve: 'smooth'
            },

            xaxis: {
                // categories: statistics?.betsOverTime?.map((item) => item._id) || [],
                axisBorder: {
                    show: true,
                    color: axisColor,
                    height: 2,
                },
                axisTicks: {
                    show: true,
                    color: axisColor,
                },
                labels: {
                    formatter: function (value) {
                        // Assuming `value` is a timestamp or date string
                        if (!value) return '';
                        const date = new Date(value);
                        // return moment(value).format("MMMM Do YYYY")
                        return date.toLocaleDateString()
                    },
                    style: {
                        colors: labelColor,
                    }
                }
            },
            yaxis: {
                axisBorder: {
                    show: true,
                    color: axisColor,
                },
                axisTicks: {
                    show: true,
                    color: axisColor,
                },
                labels: {
                    style: {
                        colors: labelColor,
                        fontSize: '12px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 400
                    }
                }
            },
            tooltip: {
                theme: skin == "dark" ? "dark" : "light",
                y: {
                    formatter: function (val) {
                        return val.toFixed(0);
                    },

                },
                x: {
                    formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
                        if (!value) return '';
                        const date = new Date(value);
                        
                        return date.toLocaleDateString();
                        
                    }
                },
            },

            grid: {
                show: true,
                borderColor: axisColor,
                strokeDashArray: 0,
                xaxis: {
                    lines: {
                        show: false
                    }
                },
                yaxis: {
                    lines: {
                        show: false
                    }
                },
                padding: {
                    left: 13,
                    right: 10,
                    top: 0,
                    bottom: 0
                }
            }
        }
    };

    const avgBetSizeData = {
        series: [{
            name: "Avg Bet Size",
            data: statistics?.averageBetSizeOverTime?.map((item) => item.avgBets.toFixed(2)) || []
        }],
        options: {
            chart: {
                height: 350,
                type: 'bar',
                toolbar: {
                    autoSelected: 'zoom',
                    show: true, // Ensure the toolbar is enabled
                    tools: {
                        download: true, // Enable the download tool
                    },
                    export: {
                        csv: {
                            filename: 'Monthly Average Bet Per Game', // Custom file name for CSV export
                            headerCategory: 'Games', // Custom header name for category (X-axis)
                            columnHeaders: ['Games', 'Avg Bet Size'], // Explicitly set headers for each column
                            dateFormatter(timestamp) { // Custom date format for CSV export
                                return new Date(timestamp).toLocaleString();
                            }
                        },
                        svg: {
                            filename: 'Monthly Average Bet Per Game', // Custom file name for SVG export
                            width: 800, // Custom width
                            height: 600, // Custom height
                            backgroundColor: '#000000', // Custom background color
                        },
                        png: {
                            filename: 'Monthly Average Bet Per Game', // Custom file name for PNG export
                            width: 800, // Custom width
                            height: 600, // Custom height
                            backgroundColor: '#000000', // Custom background color
                        }
                    },
                },
            },

            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '55%',
                    endingShape: 'rounded'
                },
            },
            dataLabels: {
                enabled: false
            },

            tooltip: {
                theme: skin == "dark" ? "dark" : "light",
            },
            xaxis: {
                categories: statistics?.averageBetSizeOverTime?.map((item) => item.gameName) || [],
                axisBorder: {
                    show: true,
                    color: axisColor,
                    height: 2,
                },
                axisTicks: {
                    show: true,
                    color: axisColor,
                },
                labels: {
                    style: {
                        colors: labelColor,
                    }
                }
            },
            yaxis: {
                axisBorder: {
                    show: true,
                    color: axisColor,
                },
                axisTicks: {
                    show: true,
                    color: axisColor,
                },
                labels: {
                    style: {
                        colors: labelColor,
                        fontSize: '12px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 400
                    }
                }
            },
            grid: {
                show: true,
                borderColor: axisColor,
                strokeDashArray: 0,
                xaxis: {
                    lines: {
                        show: false
                    }
                },
                yaxis: {
                    lines: {
                        show: false
                    }
                },
                padding: {
                    left: 13,
                    right: 10,
                    top: 0,
                    bottom: 0
                }
            },
        }
    };



    const betOverHour = statistics?.betsOverHours?.map((item) => {

        const timestamp = new Date(item._id).getTime();; // Convert dateTime to timestamp
        //    const timestamp= moment(item._id).format("MMMM Do YYYY, h:mm:ss a")
        const value = Number(item.count); // Ensure count is a number
        // if (isNaN(timestamp) || isNaN(value)) {
        //     console.warn("Invalid data point:", { dateTime: item._id, count: item.count });
        // }
        return {
            x: timestamp,
            y: value,
        };
    }).filter(point => !isNaN(point.x) && !isNaN(point.y)) || [];
    // Check if the dataset is empty
    const isDataEmpty = !betOverHour || betOverHour.length === 0;

    // Set current date in the desired format if data is empty
    const currentDate = new Date().toISOString();
    const betsOverHoursData = {
        series: [{
            name: 'Hourly Total Bets',
            data: betOverHour,
        }
        ],

        colors: ['#506fd9', '#85b6ff'],
        chart: {
            type: 'area',
            stacked: false,
            height: 350,
            zoom: {
                type: 'x',
                enabled: true,
                autoScaleYaxis: true
            },
            toolbar: {
                autoSelected: 'zoom',
                show: true, // Ensure the toolbar is enabled
                tools: {
                    download: true, // Enable the download tool
                },
                export: {
                    csv: {
                        filename: 'Hourly Total Bets', // Custom file name
                        headerCategory: 'Date & Time', // Custom header name for category (X-axis)
                        columnHeaders: ['Date & Time', 'Total Bets'], // Explicitly set headers for each column
                        dateFormatter(timestamp) { // Custom date format for CSV export
                            return new Date(timestamp).toLocaleString();
                        }
                    },
                    svg: {
                        filename: 'Hourly Total Bets', // Custom file name for SVG export
                        width: 800, // Custom width
                        height: 600, // Custom height
                        backgroundColor: '#000000', // Custom background color
                    },
                    png: {
                        filename: 'Hourly Total Bets', // Custom file name for PNG export
                        width: 800, // Custom width
                        height: 600, // Custom height
                        backgroundColor: '#000000', // Custom background color
                    }
                },
            }
        },
        dataLabels: {
            enabled: false
        },
        markers: {
            size: 0,
        },
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                inverseColors: false,
                opacityFrom: 0.5,
                opacityTo: 0,
                stops: [0, 90, 100]
            },
        },
        grid: {
            show: true, // Enable grid to allow border customization
            borderColor: axisColor, // Set the color for axis border lines
            strokeDashArray: 0, // Solid border lines
            xaxis: {
                lines: {
                    show: false // Hide vertical grid lines
                }
            },
            yaxis: {
                lines: {
                    show: false // Hide horizontal grid lines
                }
            },
            padding: {
                left: 13,
                right: 10,
                top: 0,
                bottom: 0
            }
        },
        yaxis: {
            labels: {
                formatter: function (val) {
                    return val.toFixed(0);
                },
                style: {
                    colors: labelColor,  // Conditional color for dark/light theme
                    fontSize: '12px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 400
                }
            },
            title: {
                text: 'Bets',
                style: {
                    color: labelColor, // Conditional color for
                }
            },
            axisBorder: {
                show: true,  // Show the axis line on the y-axis
                color: axisColor,  // Conditional color for dark/light theme
            },
            axisTicks: {
                show: true,  // Show the ticks on the y-axis
                color: axisColor,  // Conditional color for dark/light theme
            }
        },
        xaxis: {
            type: 'datetime',
            categories: isDataEmpty ? [currentDate] : '',
            axisBorder: {
                show: true,
                color: axisColor,  // Conditional color for dark/light theme
                height: 2,
            },
            axisTicks: {
                show: true,
                color: axisColor,  // Conditional color for dark/light theme
            },
            labels: {
                formatter: function (value) {
                    // Assuming `value` is a timestamp or date string
                    if (!value) return '';
                    const date = new Date(value);
                    // return moment(value).format("MMMM Do YYYY, h:mm:ss a")
                    return date.toLocaleString();
                },
                style: {
                    colors: labelColor,
                }
            }
            // labels: {
            //     style: {
            //         colors: labelColor,  // Conditional color for dark/light theme
            //     }
            // }
        },
        tooltip: {
            theme: skin == "dark" ? "dark" : "light", // Set to 'dark' or 'light' according to your theme
            y: {
                formatter: function (val) {
                    return val.toFixed(0);
                },

            },
            x: {
                formatter: function (value) {
                    // Assuming `value` is a timestamp or date string
                    if (!value) return '';
                    const date = new Date(value);
                    // return moment(value).format("MMMM Do YYYY, h:mm:ss a")
                    return date.toLocaleString();
                },
            },
            style: {
                fontSize: '12px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                // backgroundColor: tooltipBackgroundColor,  // Use the appropriate color
                // color: tooltipTextColor // Conditional color for dark/light theme
            }
        }
        ,
        legend: {
            show: true,

            labels: {
                colors: labelColor,  // Conditional color for dark/light theme
                useSeriesColors: false  // If you want to use the same color as the series
            },
            markers: {
                width: 12,
                height: 12,
                strokeWidth: 0,
                radius: 12,
                offsetX: 0,
                offsetY: 0
            },
            itemMargin: {
                horizontal: 5,
                vertical: 0
            },
            onItemClick: {
                toggleDataSeries: true
            },
            onItemHover: {
                highlightDataSeries: true
            }
        },
    };



    return (
        <React.Fragment>
            <Header />
            <Sidebar />
            <div className="main main-app p-3 p-lg-3">
                <div className="d-md-flex align-items-center justify-content-between mb-2">
                    <div>
                        <ol className="breadcrumb fs-sm mb-1">
                            <li className="breadcrumb-item"><Link to="#">Dashboard</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Bet Statistics</li>
                        </ol>
                        <h4 className="main-title mt-2 mb-0">Bet Statistics</h4>
                    </div>
                    <div className="d-flex flex-wrap gap-2 mt-3 mt-md-0">
                        <MerchantFilter setMerchantId={setMerchantId} merchantId={merchantId} />
                        <DateFilter onDateChange={handleDateChange} />
                        <AdminCronSettings onSave={(expression) => setCronExpression(expression)} />
                    </div>
                </div>

                {statistics ? <>
                    <Row className={`mb-4 ${skin == "dark" && 'customTable'}`}>
                        <Col lg={2} md={6} sm={12} className="mb-2">
                            <Card>
                                <Card.Body>
                                    <h6>Total Bets Placed</h6>
                                    <h4>
                                        {/* {statistics?.totalBetsPlaced} */}
                                        <NumericFormat
                                            value={statistics?.totalBetsPlaced}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            // prefix={'₹'}
                                            allowedDecimalSeparators={['%']}
                                            allowNegative
                                            decimalScale={2}
                                            thousandsGroupStyle="lakh"
                                        />
                                    </h4>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={2} md={6} sm={12} className="mb-2">
                            <Card>
                                <Card.Body>
                                    <h6>Amount of Bets</h6>
                                    <h4>
                                        {/* ₹{Number(statistics?.amountOfBets)?.toFixed(2)} */}
                                        <NumericFormat
                                            value={statistics?.amountOfBets}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            prefix={'₹'}
                                            allowedDecimalSeparators={['%']}
                                            allowNegative
                                            decimalScale={2}
                                            thousandsGroupStyle="lakh"
                                        />
                                    </h4>
                                </Card.Body>
                            </Card>
                        </Col>
                        {/* <Col lg={3} md={6} sm={12} className="mb-3">
                        <Card>
                            <Card.Body>
                                <h6>Bonus Used</h6>
                                <h4>{Number(statistics?.bonusUsed)?.toFixed(2) || 'Not Found'}</h4>
                            </Card.Body>
                        </Card>
                    </Col> */}
                        <Col lg={2} md={6} sm={12} className="mb-2">
                            <Card>
                                <Card.Body>
                                    <h6>Avereage Bet Size</h6>
                                    <h4>
                                        {/* {Number(statistics?.averageBetSize)?.toFixed(2)} */}
                                        <NumericFormat
                                            value={statistics?.averageBetSize}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            // prefix={'₹'}
                                            allowedDecimalSeparators={['%']}
                                            allowNegative
                                            decimalScale={2}
                                            thousandsGroupStyle="lakh"
                                        />

                                    </h4>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={2} md={6} sm={12} className="mb-2">
                            <Card>
                                <Card.Body>
                                    <h6>Total Bets Won</h6>
                                    <h4>
                                        {/* {statistics?.totalBetsWon} */}
                                        <NumericFormat
                                            value={statistics?.totalBetsWon}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            // prefix={'₹'}
                                            allowedDecimalSeparators={['%']}
                                            allowNegative
                                            decimalScale={2}
                                            thousandsGroupStyle="lakh"
                                        />

                                    </h4>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={2} md={6} sm={12} className="mb-2">
                            <Card>
                                <Card.Body>
                                    <h6>Total Amount Won</h6>
                                    <h4>
                                        {/* ₹{Number(statistics?.totalAmountWon)?.toFixed(2)} */}

                                        <NumericFormat
                                            value={statistics?.totalAmountWon}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            prefix={'₹'}
                                            allowedDecimalSeparators={['%']}
                                            allowNegative
                                            decimalScale={2}
                                            thousandsGroupStyle="lakh"
                                        />
                                    </h4>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={2} md={6} sm={12} className="mb-2">
                            <Card>
                                <Card.Body>
                                    <h6>Net Bets Amount</h6>
                                    <h4>
                                        {/* ₹{Number(statistics?.netBetAmount)?.toFixed(2)} */}
                                        <NumericFormat
                                            value={statistics?.netBetAmount}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            prefix={'₹'}
                                            allowedDecimalSeparators={['%']}
                                            allowNegative
                                            decimalScale={2}
                                            thousandsGroupStyle="lakh"
                                        />

                                    </h4>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>


                    <Row className={`mb-4 ${skin == "dark" && 'customTable'}`}>
                        <Col className="mb-3">
                            <Card>
                                <Card.Body>
                                    <Card.Title>
                                        Hourly Total Bets
                                    </Card.Title>
                                    <ReactApexChart options={betsOverHoursData} series={betsOverHoursData.series} type="area" height={350} />
                                    {/* <ReactApexChart options={transactionOverTimeData?.options} series={transactionOverTimeData?.series} type="pie" height={350} /> */}
                                </Card.Body>
                            </Card>
                        </Col>

                    </Row>

                    <Row className="mb-4">
                        <Col lg={6} md={12} className="mb-2">
                            <Card>
                                <Card.Header>
                                    <Card.Title style={{ color: labelColor }}>
                                        Bets Over Month
                                    </Card.Title>
                                </Card.Header>
                                <Card.Body>

                                    <ReactApexChart options={betsOverTimeData?.options} series={betsOverTimeData?.series} type="line" height={350} />
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={6} md={12} className="mb-2">
                            <Card>
                                <Card.Header>
                                    <Card.Title style={{ color: labelColor }}>
                                        Monthly Average Bets Per Game
                                    </Card.Title>
                                </Card.Header>
                                <Card.Body>

                                    <ReactApexChart options={avgBetSizeData?.options} series={avgBetSizeData?.series} type="bar" height={350} />
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </> : <>
                    <div className="d-flex justify-content-center align-content-center " style={{ height: '60vh' }} >
                        <Loader />
                    </div>
                </>}

                {/* <Footer /> */}
            </div>
        </React.Fragment>
    );
}
