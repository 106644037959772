import React, { useEffect, useState, useRef } from "react";
import { Card, Row, Col, Badge, Dropdown, Table, Pagination, Form } from 'react-bootstrap';
import useSkinSwitcher from "./skin";
import ReactPaginate from "react-paginate";
import AuthService from "../service/api/auth.service";
import { Link, useNavigate } from "react-router-dom";

import capitalizeFirstLetter from '../helper/Capitalise'
import './table.css'
import { useSelector } from "react-redux";
import { BorderSpinner } from "./Spinner";
const TableWithPagination = ({
  haveAdd = true,
  haveTableSearch = true,
  addType = '',
  tableType,
  data,
  itemsPerPage,
  columns,
  currentPage,
  totalPages1,
  totalData,
  pagination,
  setPagination,
  handleSearchChange,
  moduleId,
  redirectUrl
}) => {
  const isLoading = useSelector((state) => state.state.loading);
  const [currentPage1, setCurrentPage] = useState(currentPage);
  const [currentData, setCurrentData] = useState([...data]);
  const [pageSize, setPageSIze] = useState(itemsPerPage);
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortBy, setSortBy] = useState(null);
  const { skin, setSkin } = useSkinSwitcher();
  const [totalPages, setTotalPages] = useState(totalPages1);

  const theme = useSelector((state) => state.ui.skin);
  const authService = AuthService();
  const navigate = useNavigate();

  console.log(theme, "Mytheme")
  useEffect(() => {
    // pageSize.current = itemsPerPage;
    // currentPage1.current = currentPage;
    setPageSIze(itemsPerPage)
    setCurrentPage(currentPage)
  }, [itemsPerPage, currentPage]);

  useEffect(() => {
    const startIndex = (currentPage1 - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    console.log("rawData", totalPages1);


    const rawData = data.slice(startIndex, endIndex);
    // setCurrentData(data);
    setTotalPages(Math.ceil(totalData / pageSize));
  }, [data, sortBy, sortOrder, currentPage1, pageSize]);
  console.log("rawData", currentData);

  const handlePageChange = (page) => {
    console.log(page, "currentPage==========", totalPages)
    setCurrentPage(page);
    // currentPage1 = page;
    setPagination({ ...pagination, page })
  };

  const PageSizeSelector = ({ pageSize }) => (
    <Form.Group controlId="pageSize" className="d-flex gap-1 align-items-center">
      <Form.Label className="text-nowrap">Show rows: </Form.Label>
      <Form.Control
        as="select"
        value={pageSize}
        onChange={(e) => {
          setPageSIze(parseInt(e.target.value));
          setPagination((preValue) => ({
            ...preValue,
            limit: parseInt(e.target.value),
            page: 1
          }));
          console.log("=====>select", parseInt(e.target.value));
        }}
      >

        <option value="25">25</option>
        <option value="50">50</option>
        <option value="75">75</option>
        <option value="100">100</option>
        <option value="200">200</option>

      </Form.Control>
    </Form.Group>
  );

  const handleSort = (column) => {
    const accessor = column.accessor || column;
    if (sortBy === accessor) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(accessor);
      setSortOrder("asc");
    }
  };
  const handlePageClick = (event) => {
    setPagination((preValue) => ({
      ...preValue,
      page: event.selected + 1
    }));
  }
  console.log("isLoading CurrentData", isLoading, currentData);
  const moduleIdListNotHaveAdd = [6, 10, 7, 11, 20]
  const moduleIdListNotHaveSearch = [6, 7, 9, 18, 20]

  return (
    // <Row>
    <>


      <Card.Body >
        <div className="d-flex mb-3 gap-2 align-items-center justify-content-between">
          {haveTableSearch && <div className="form-search  me-auto">
            <input
              type='search'
              // value={pagination.searchBy}
              defaultValue={pagination.searchBy}
              className="form-control"
              placeholder={`${moduleId == 11 ? 'Search by match Id' : 'Search'}`}
              onChange={handleSearchChange}
            />
            <i className="ri-search-line"></i>
            {/* ticketList */}
          </div>}
          <div>

          </div>
          {/* (moduleIdListNotHaveAdd.includes(moduleId) || (moduleId == 15 && (tableType && tableType === 'ticketList'))) */}
          {haveAdd && authService.checkAccess(moduleId, "add") && <button
            type="button"
            className="btn btn-primary"
            onClick={addType === 'modal' ? redirectUrl : () => navigate(redirectUrl)}
          >
            Add
          </button>}
        </div>
        <div className="table-responsive-sm" style={{ overflow: 'auto', minHeight: "300px" }} >

          <Table className={`table-bordered  table-centered ${theme === 'dark' ? 'customTable' : ''} mb-0`}>
            <thead>
              <tr>
                <th>#</th>
                {columns.map((column, index) => (
                  <th className="text-nowrap" key={index} onClick={() => handleSort(column)}>
                    {tableType && (tableType === 'transaction' || tableType == 'wallet') ? (column.header || column)
                      : capitalizeFirstLetter(column.header || column)}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  <td colSpan={columns.length + 1} className="text-center">
                    <BorderSpinner />
                  </td>
                </tr>
              ) : data.length !== 0 ? (
                data.map((item, index) => (
                  <tr key={index}>
                    <td>{(currentPage1 - 1) * pageSize + index + 1}</td>
                    {columns.map((column, columnIndex) => (
                      <td className="text-nowrap" key={columnIndex}>
                        {typeof column === "object" && column.Cell ? (
                          column.Cell({
                            value: item[column.accessor],
                            row: item // Ensure 'row' is passed correctly to Cell function
                          })
                        ) : item[column.accessor] ? (
                          typeof item[column.accessor] === "string" ?
                            capitalizeFirstLetter(item[column.accessor]) :
                            item[column.accessor]
                        ) : (
                          "N/A" // Default value when item[column.accessor] is undefined
                        )}
                      </td>
                    ))}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={columns.length + 1} className="text-center">
                    No Record Found
                  </td>
                </tr>
              )}
            </tbody>

          </Table>

        </div>

      </Card.Body>
      {/* <Card.Footer>
        <div className="d-lg-flex gap-3 justify-content-between align-items-center text-center mt-2">

          <PageSizeSelector pageSize={pageSize} />

          <ReactPaginate
            containerClassName={"pagination pagination-rounded d-inline-flex ms-auto align-item-center mb-0"}
            pageClassName={"page-item"}
            breakClassName={'page-item'}
            breakLinkClassName={'page-link'}
            pageLinkClassName={'page-link'}
            previousClassName={'page-item'}
            previousLinkClassName={'page-link'}
            nextClassName={'page-item'}
            nextLinkClassName={'page-link'}
            activeClassName={'active'}
            breakLabel="..."
            nextLabel="Next"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={totalPages}
            previousLabel="Previous"
            renderOnZeroPageCount={null}
            forcePage={currentPage1 - 1}
          />
        </div>
      </Card.Footer> */}
      <Card.Footer>
        <div className="d-flex flex-column flex-lg-row flex-wrap gap-3 justify-content-between align-items-start  align-items-lg-center text-center mt-2">

          {/* Page Size Selector - Centers on Mobile, Aligns Left on Large Screens */}
          <div className="w-lg-auto text-center">
            <PageSizeSelector pageSize={pageSize} />
          </div>

          {/* Pagination - Centers on Mobile, Aligns Right on Large Screens */}


          <div className="w-lg-auto d-flex justify-content-center justify-content-lg-end overflow-auto">
            <ReactPaginate
              containerClassName="pagination pagination-rounded d-inline-flex ms-auto align-items-center my-1 mb-0 flex-wrap"
              pageClassName="page-item"
              breakClassName="page-item"
              breakLinkClassName="page-link"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              activeClassName="active"
              breakLabel="..."
              nextLabel="Next"
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={totalPages}
              previousLabel="Previous"
              renderOnZeroPageCount={null}
              forcePage={currentPage1 - 1}
            />
          </div>

        </div>
      </Card.Footer>

    </>

  );
};

export default TableWithPagination;
