import React, { useState } from "react";
import { Modal, Button, Container, Row, Col, Card, Form } from "react-bootstrap";

const ColorPreview = ({
    backgroundColor = '#ffffff',
    textColor = '#000000',
    primaryColor = '#007bff',
    secondaryColor = '#6c757d',
    accentColor = '#17a2b8',
    buttonTextColor = '#ffffff',
    buttonHoverColor = '#0056b3',
    linkColor = '#007bff',
    borderColor = '#ced4da',
    errorColor = '#dc3545',
    successColor = '#28a745',
    warningColor = '#ffc107'
}) => {
    const [showModal, setShowModal] = useState(false);
    const [activeSection, setActiveSection] = useState('colors');

    const colorSections = [
        { 
            name: 'colors', 
            title: 'Color Palette',
            content: () => (
                <Container>
                    <Row>
                        {[
                            { name: 'Primary', color: primaryColor },
                            { name: 'Secondary', color: secondaryColor },
                            { name: 'Accent', color: accentColor },
                            { name: 'Error', color: errorColor },
                            { name: 'Success', color: successColor },
                            { name: 'Warning', color: warningColor }
                        ].map((colorInfo) => (
                            <Col key={colorInfo.name} md={4} className="mb-3">
                                <Card>
                                    <Card.Body className="text-center">
                                        <div 
                                            style={{
                                                backgroundColor: colorInfo.color,
                                                height: '100px',
                                                borderRadius: '8px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                color: 'white',
                                                fontWeight: 'bold'
                                            }}
                                        >
                                            {colorInfo.name}
                                        </div>
                                        <Card.Text className="mt-2 text-center">
                                            {colorInfo.color}
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </Container>
            )
        },
        { 
            name: 'components', 
            title: 'Component Showcase',
            content: () => (
                <Container>
                    <Row>
                        <Col md={6}>
                            <Card className="mb-3">
                                <Card.Header>Button Styles</Card.Header>
                                <Card.Body>
                                    <Button 
                                        style={{ 
                                            backgroundColor: primaryColor, 
                                            borderColor: primaryColor 
                                        }}
                                        className="me-2"
                                    >
                                        Primary Button
                                    </Button>
                                    <Button 
                                        style={{ 
                                            backgroundColor: secondaryColor, 
                                            borderColor: secondaryColor 
                                        }}
                                    >
                                        Secondary Button
                                    </Button>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={6}>
                            <Card className="mb-3">
                                <Card.Header>Link & Text Styles</Card.Header>
                                <Card.Body>
                                    <p style={{ color: textColor }}>
                                        Default Text in {textColor}
                                    </p>
                                    <a 
                                        href="/" 
                                        style={{ 
                                            color: linkColor, 
                                            textDecoration: 'underline' 
                                        }}
                                    >
                                        Link in {linkColor}
                                    </a>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Card>
                                <Card.Header>Status Messages</Card.Header>
                                <Card.Body>
                                    <div 
                                        style={{ 
                                            backgroundColor: errorColor, 
                                            color: 'white', 
                                            padding: '10px', 
                                            marginBottom: '10px' 
                                        }}
                                    >
                                        Error Message
                                    </div>
                                    <div 
                                        style={{ 
                                            backgroundColor: successColor, 
                                            color: 'white', 
                                            padding: '10px', 
                                            marginBottom: '10px' 
                                        }}
                                    >
                                        Success Message
                                    </div>
                                    <div 
                                        style={{ 
                                            backgroundColor: warningColor, 
                                            color: 'white', 
                                            padding: '10px' 
                                        }}
                                    >
                                        Warning Message
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            )
        }
    ];

    return (
        <>
            <Button variant="info" onClick={() => setShowModal(true)}>
                Preview Theme
            </Button>

            <Modal 
                show={showModal} 
                onHide={() => setShowModal(false)} 
                size="xl"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Theme Visualization</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ backgroundColor, color: textColor }}>
                    <div className="mb-3">
                        {colorSections.map((section) => (
                            <Button
                                key={section.name}
                                variant={activeSection === section.name ? 'primary' : 'outline-secondary'}
                                onClick={() => setActiveSection(section.name)}
                                className="me-2"
                            >
                                {section.title}
                            </Button>
                        ))}
                    </div>

                    {colorSections.find(section => section.name === activeSection)?.content()}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ColorPreview;