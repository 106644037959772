import React, { useState, useEffect } from "react";
import Sidebar from "../../layouts/Sidebar";
import Header from "../../layouts/Header";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  Table,
  Pagination,
} from "react-bootstrap";
import useSkinSwitcher from "../../common/skin";
import RoleService from "../../service/api/role.service";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";

const AddNewRole = () => {
  const roleService = RoleService();
  const navigate = useNavigate();
  // const theme = useSelector((state) => state.ui.skin);

  const { skin, setSkin } = useSkinSwitcher();
  const modules = [
    "Role And Permission",//1
    "Staffs",//2
    "Users",//3
    "Games",//4
    "Merchants",//5
    "Transactions",//6
    "Game Record",//7
    "Game Settings",//8
    "Dashboard",//9
    "Banners",//10
    "Sports Management",//11
    "Sports Bet",//12
    // "Referral",
    "Games Category",//13
    // "Static Pages",
    "Currency",//14
    "Lottery",//15
    "Platform Settings",//16
    "IP Whitelisting",//17
    "Exposure Management",//18
    "Theme Management",//19
    "Affiliate Management" //20

  ];
  // const  resource =

  const [roleForm, setRoleForm] = useState({
    name: "",
  });
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [nameError, setNameError] = useState("");
  const [resource, setResource] = useState([]); // Initialize resource state with your data

  // useEffect(() => {
  //   let updatedresource = modules.map((moduleName, moduleId) => ({
  //     moduleName,
  //     moduleId: (moduleId + 1).toString(),
  //     selectedAll: false,
  //     list: false,
  //     add: false,
  //     edit: false,
  //     view: false,
  //     delete: false,

  //   }));
  //   setResource(updatedresource);
  // }, []);
  // When setting up your initial modules data, define the available actions for each
  useEffect(() => {
    let updatedresource = modules.map((moduleName, moduleId) => {
      // Define default available actions configuration
      let availableActions = {
        list: true,  // List is almost always available
        add: true,   // Most modules have add capability
        edit: true,  // Most modules have edit capability
        view: true,  // Most modules have view capability
        delete: true // Most modules have delete capability
      };

      // Override available actions based on module name
      switch (moduleName) {
        case "Dashboard":
          // Example: Dashboard might only support view, not add/edit/delete
          availableActions = { list: false, add: false, edit: false, view: true, delete: false };
          break;
        case "Games Category":
          // Example: Settings might only support list, view and edit
          availableActions = { list: true, add: true, edit: true, view: false, delete: false };
          break;
          case "Transactions":
            // Example: Settings might only support list, view and edit
            availableActions = { list: true, add: false, edit: false, view: false, delete: false };
          break;
          case "Game Record":
            // Example: Settings might only support list, view and edit
            availableActions = { list: true, add: false, edit: false, view: false, delete: false };
          break;
          case "Banners":
            // Example: Settings might only support list, view and edit
            availableActions = { list: true, add: false, edit: true, view: true, delete: false };
          break;
          case "Sports Management":
            // Example: Settings might only support list, view and edit
            availableActions = { list: true, add: false, edit: true, view: false, delete: false };
          break;
          case "Sports Bet":
            // Example: Settings might only support list, view and edit
            availableActions = { list: true, add: false, edit: false, view: false, delete: false };
          break;
          case "Affiliate Management":
            // Example: Settings might only support list, view and edit
            availableActions = { list: true, add: false, edit: true, view: true, delete: true };
            break;
        // Add cases for other modules with specific action limitations

        default:
          // Keep default (all actions available)
          break;
      }

      return {
        moduleName,
        moduleId: (moduleId + 1).toString(),
        selectedAll: false,
        // Initialize all action values as false
        list: false,
        add: false,
        edit: false,
        view: false,
        delete: false,
        // Include info about which actions are available
        availableActions: availableActions
      };
    });

    setResource(updatedresource);
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setRoleForm({ ...roleForm, [name]: value });
    const regex = /^[A-Za-z\s]{1,50}$/;
    if (value?.trim() === '') {
      setNameError("Role Name is required.");
    }
    else if (value?.trim()?.length > 50) {
      setNameError("Role Name must not contain more than 50 characters")
    }
    else if (!regex.test(value?.trim())) {

      setNameError("Role must contain only alphabetic characters and spaces upto 50 characters.");
    } else {
      setNameError("");
    }
  };

  const handleCheckboxChange = (index, field) => {
    const updatedResource = [...resource];
    console.log("updatedResource", updatedResource);
    updatedResource[index][field] = !updatedResource[index][field];
    // Only toggle actions that are available for this module
    const availableActions = updatedResource[index].availableActions;
    if (
      (!availableActions.list || updatedResource[index].list) &&
      (!availableActions.add || updatedResource[index].add) &&
      (!availableActions.edit || updatedResource[index].edit) &&
      (!availableActions.view || updatedResource[index].view) &&
      (!availableActions.delete || updatedResource[index].delete)
      // updatedResource[index].list &&
      // updatedResource[index].add &&
      // updatedResource[index].edit &&
      // updatedResource[index].view
    ) {
      updatedResource[index].selectedAll = true;
    } else {
      updatedResource[index].selectedAll = false;
    }

    if (!updatedResource[index].list ) {
      updatedResource[index].add = false
      updatedResource[index].edit = false
      updatedResource[index].delete = false
      updatedResource[index].view = false
    }
    setResource(updatedResource);
  };

  // const handleSelectAll = (index) => {
  //   const updatedResource = [...resource];
  //   const selectedAll = updatedResource[index].selectedAll;
  //   updatedResource[index].selectedAll = !selectedAll;
  //   updatedResource[index].list = !selectedAll;
  //   updatedResource[index].add = !selectedAll;
  //   updatedResource[index].edit = !selectedAll;
  //   updatedResource[index].view = !selectedAll;
  //   updatedResource[index].delete = !selectedAll;
  //   setResource(updatedResource);
  // };
  const handleSelectAll = (index) => {
    const updatedResource = [...resource];
    const selectedAll = updatedResource[index].selectedAll;
    updatedResource[index].selectedAll = !selectedAll;

    // Only toggle actions that are available for this module
    const availableActions = updatedResource[index].availableActions;
    if (availableActions.list) updatedResource[index].list = !selectedAll;
    if (availableActions.add) updatedResource[index].add = !selectedAll;
    if (availableActions.edit) updatedResource[index].edit = !selectedAll;
    if (availableActions.view) updatedResource[index].view = !selectedAll;
    if (availableActions.delete) updatedResource[index].delete = !selectedAll;

    setResource(updatedResource);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Hello")
    // Handle form submission
    const regex = /^[A-Za-z\s]{1,50}$/;
    if (roleForm.name.trim() === "") {
      setShowError(true);
      setNameError("Role Name is required.");
      return;
    }
    else if (roleForm.name?.trim().length > 50) {
      setNameError("Role Name must not contain more than 50 characters");
      setShowError(true);
      return;
    }
    else if (!regex.test(roleForm.name) || roleForm.name.trim() === "") {
      setShowError(true);
      setNameError("Role must contain only alphabetic characters and spaces upto 50 characters.");
      return;
    } else {
      setShowError(false)
      setNameError("");
    }
    const updatedResource = resource.map((permission) => {
      const { moduleName, moduleId, ...rest } = permission;
      const allowedPermissions = Object.entries(rest)
        .filter(([key, value]) => value && key!=='availableActions')
        .map(([key]) => key);

      return {
        moduleName,
        moduleId,
        permissions: allowedPermissions,
      };
    });
    const hasPermission = updatedResource.every(
      (item) => item.permissions.length === 0
    );
    if (hasPermission) {
      toast.error("Please select atleast one permission.");

      // Mark for check
      return;
    }
    let updatedForm = {
      name: roleForm.name.trim(),
      resource: [...updatedResource]
    }
    const res = await roleService.createRole({
      ...updatedForm,
    });
    console.log("resss===", res);
    if (res?.code == 200) {
      toast.success(res?.message);
      navigate("/role-management");
    } else {
      toast.error(res?.message);
    }
  };

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <Sidebar />
      <div className="main main-app p-3 p-lg-4">
        <div className=" align-items-center mb-4">
          <Container className="mt-4">
            <Row>
              <Col md={12}>
                <Card>
                  <Card.Header>
                    <h4 className="card-title w-100">Add New Role</h4>
                  </Card.Header>
                  <Card.Body>
                    <Form onSubmit={handleSubmit}>
                      <Form.Group as={Row} controlId="name">
                        <Form.Label column sm={2}>
                          Role
                        </Form.Label>
                        <Col sm={10}>
                          <Form.Control
                            type="text"
                            name="name"
                            value={roleForm.name}
                            onChange={handleInputChange}
                            placeholder="Enter Name"
                            isInvalid={showError && nameError}
                          />
                          <Form.Control.Feedback type="invalid">
                            {nameError}
                          </Form.Control.Feedback>
                          {/* <Form.Text className="text-danger small">
                            {roleForm.name.trim() === "" &&
                              showError &&
                              "Role is required"}
                          </Form.Text> */}
                        </Col>
                      </Form.Group>
                      <div className="table-responsive border rounded mt-1">
                        <Table bordered className={`${skin === 'dark' ? 'customTable' : ''} `}>
                          <thead>
                            <tr>
                              <th>Module Name</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {resource.map((item, index) => (
                              <tr key={index}>
                                <td>{item.moduleName}</td>
                                <td className="d-flex justify-content-between">
                                  <div className="custom-control custom-checkbox">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      id={`selectall-customCheck-${index}`}
                                      checked={item.selectedAll}
                                      onChange={() => handleSelectAll(index)}
                                    />
                                    {" "}
                                    <label
                                      className="custom-control-label"
                                      htmlFor={`selectall-customCheck-${index}`}
                                    >
                                      {" "} Select All
                                    </label>
                                  </div>
                                  {item?.availableActions?.list &&<div className="custom-control custom-checkbox">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      id={`list-customCheck-${index}`}
                                      checked={item.list}
                                      onChange={() =>
                                        handleCheckboxChange(index, "list")
                                      }
                                    />
                                    {" "}
                                    <label
                                      className="custom-control-label"
                                      htmlFor={`list-customCheck-${index}`}
                                    >
                                      {" "}List
                                    </label>
                                  </div>}
                                  {item?.availableActions?.add &&<div className="custom-control custom-checkbox">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      id={`add-customCheck-${index}`}
                                      checked={item.add}
                                      disabled={!item.list}
                                      onChange={() =>
                                        handleCheckboxChange(index, "add")
                                      }
                                    />
                                    {" "}
                                    <label
                                      className="custom-control-label"
                                      htmlFor={`add-customCheck-${index}`}
                                    >
                                      {" "}Add
                                    </label>
                                  </div>}
                                  {item?.availableActions?.edit &&<div className="custom-control custom-checkbox">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      id={`edit-customCheck-${index}`}
                                      checked={item.edit}
                                      disabled={!item.list}
                                      onChange={() =>
                                        handleCheckboxChange(index, "edit")
                                      }
                                    />
                                    {" "}
                                    <label
                                      className="custom-control-label"
                                      htmlFor={`edit-customCheck-${index}`}
                                    >
                                      {" "} Edit
                                    </label>
                                  </div>}
                                  {item?.availableActions?.view &&<div className="custom-control custom-checkbox">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      id={`view-customCheck-${index}`}
                                      checked={item.view}
                                      disabled={!item.list || !item.selectAll}
                                      onChange={() =>
                                        handleCheckboxChange(index, "view")
                                      }
                                    />
                                    {" "}
                                    <label
                                      className="custom-control-label"
                                      htmlFor={`view-customCheck-${index}`}
                                    >
                                      {" "}View
                                    </label>
                                  </div>}
                                  {/* Render other checkboxes */}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                      <div className="text-center my-2">
                        <Button
                          type="submit"
                          className="btn btn-primary mx-2"
                          onClick={handleSubmit}
                        >
                          Submit
                        </Button>
                        <Button
                          variant="danger"
                          onClick={() => navigate("/role-management")}
                        >
                          Cancel
                        </Button>
                      </div>
                    </Form>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddNewRole;
