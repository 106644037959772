import React, { useState, useEffect } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { AsyncPaginate } from "react-select-async-paginate";
import { useSelector } from "react-redux";
import { darkModeStyles, lightModeStyles } from "../../common/reactSelectStyles";
import CurrencyService from "../../service/api/currency.service";
import MerchantService from "../../service/api/merchant.service";
import { toast } from "react-toastify";
import { formatCurrency } from "../../helper/CurrencyFormate";

const WithdrawAmountModal = ({ show, onClose, merchantId }) => {
  const theme = useSelector((state) => state.ui.skin);
  const currencyService = CurrencyService();
  const merchantService = MerchantService();

  const [selectedCurrency, setSelectedCurrency] = useState([]);
  const [formData, setFormData] = useState({
    currencyId: "",
    amount: "",
  });
  const [balancesTableData, setBalancesTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [validationErrors, setValidationErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedBalance, setSelectedBalance] = useState(0);

  useEffect(() => {
    if (!show) {
      // Reset the form when the modal closes
      setFormData({ currencyId: "", amount: "" });
      setValidationErrors({});
      setSelectedCurrency([]);
      setSelectedBalance(0);
    }

    else if (merchantId) {
      getMerchantDetail(merchantId);
    }
  }, [show, merchantId]);

  const getMerchantDetail = async (id) => {
    try {
      const res = await merchantService.getMerchantById(id);
      if (res?.code === 200) {
        const groupedData = res.data.balances.reduce((acc, item) => {
          const { currencyCodes, name, currencySymbols } = item.currencyId;
          if (!acc[currencyCodes]) {
            acc[currencyCodes] = {
              currency: {
                name,
                code: currencyCodes,
                symbol: currencySymbols,
              },
              balances: {
                deposit: 0,
                bonus: 0,
                winnings: 0,
              },
            };
          }
          if (item.type === 0) acc[currencyCodes].balances.deposit = item.balance;
          if (item.type === 2) acc[currencyCodes].balances.bonus = item.balance;
          if (item.type === 1) acc[currencyCodes].balances.winnings = item.balance;
          return acc;
        }, {});
        setBalancesTableData(Object.values(groupedData));
      } else {
        throw new Error(res.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const loadOptions = async (search, loadedOptions, { page }) => {
    try {
      const response = await currencyService.getCurrencyList({
        page,
        limit: 25,
        searchBy: search,
      });
      if (response.code === 200) {
        return {
          options: response.data?.docs.map((currency) => ({
            value: currency.id,
            label: `${currency.currencyCodes}`,
          })),
          hasMore: response.data.hasNextPage,
          additional: { page: page + 1 },
        };
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      console.error("Error fetching currency list:", error);
      return { options: [], hasMore: false };
    }
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.currencyId) errors.currencyId = "Please select a currency.";
    if (!formData.amount || isNaN(formData.amount) || Number(formData.amount) <= 0)
      errors.amount = "Please enter a valid amount greater than 0.";
    else if (Number(formData.amount) > selectedBalance)
      errors.amount = "Amount cannot exceed available balance.";
    return errors;
  };

  const handleInputChange = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
    setValidationErrors((prev) => ({ ...prev, [field]: "" }));
  };

  const handleSubmit = async () => {
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }
    setIsSubmitting(true);
    try {
      const res = await merchantService.WithdrawAmountInMerchantWallet({
        currencyId: formData.currencyId,
        amount: Number(formData.amount),
        merchantId,
      });
      if (res.code === 200) {
        toast.success(res.message);
        onClose();
        setFormData({ currencyId: "", amount: "" });
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      console.error("Error adding amount:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCurrencyChange = (selectedOption) => {
    setSelectedCurrency(selectedOption);
    handleInputChange("currencyId", selectedOption ? selectedOption.value : "");
    const balance = balancesTableData.find(
      (data) => data.currency.code === selectedOption?.label
    )?.balances.deposit;
    setSelectedBalance(balance || 0);
  };

//   const formatCurrency = (amount, currencyCode) =>
//     new Intl.NumberFormat("en-US", {
//       style: "currency",
//       currency: currencyCode,
//     }).format(amount);
console.log("selectedCurrency",selectedCurrency)
  return (
    <Modal show={show} onHide={onClose} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>Withdraw Amount from Merchant</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Select Currency</Form.Label>
            <AsyncPaginate
              value={selectedCurrency}
              loadOptions={loadOptions}
              onChange={handleCurrencyChange}
              additional={{ page: 1 }}
              styles={theme === "dark" ? darkModeStyles : lightModeStyles}
              placeholder="Select a currency"
              isClearable
            />
            {validationErrors.currencyId && (
              <Form.Text className="text-danger">{validationErrors.currencyId}</Form.Text>
            )}
          </Form.Group>
          {selectedCurrency && selectedCurrency?.length != 0 && (
            <div className="mb-3">
              <strong>Available Balance: </strong>
              {formatCurrency(selectedBalance, selectedCurrency.label || "INR")}
            </div>
          )}
          <Form.Group className="mb-3">
            <Form.Label>Enter Amount</Form.Label>
            <Form.Control
              type="number"
              min={0}
              placeholder="Enter amount"
              value={formData.amount}
              onChange={(e) => handleInputChange("amount", e.target.value)}
              isInvalid={!!validationErrors.amount}
            />
            <Form.Control.Feedback type="invalid">
              {validationErrors.amount}
            </Form.Control.Feedback>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose} disabled={isSubmitting}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit} disabled={isSubmitting}>
          {isSubmitting ? "Please Wait..." : "Withdraw Amount"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default WithdrawAmountModal;
