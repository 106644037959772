import React from "react";
import { Card } from "react-bootstrap";
import formatDateTime from "../../helper/DateFormate";
import { formatCurrency } from "../../helper/CurrencyFormate";

const userDetail = ({ userData }) => {
    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <div className="sub-service-title">
                        <h3><span>Personal Details</span></h3>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="subservice-row">
                        <span className="service-title">Username:</span>
                        <span className="service-view"> {userData?.userDetail?.username?.toUpperCase()} </span>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="subservice-row">
                        <span className="service-title">Email:</span>
                        <span className="service-view"> {userData?.userDetail?.email} </span>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="subservice-row">
                        <span className="service-title">Phone Number:</span>
                        <span className="service-view"> {userData?.userDetail?.phone} </span>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="subservice-row">
                        <span className="service-title">Role</span>
                        <span className="service-view"> {userData?.userDetail?.role === 'affiliate' ? 'Affiliate' : 'User'} </span>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="subservice-row">
                        <span className="service-title">User Type</span>
                        <span className="service-view"> {userData?.userDetail?.type === 'demo' ? 'Demo' : 'User'} </span>
                    </div>
                </div>
                {/* {userData?.userDetail?.role==='affiliate' && <div className="col-md-6">
                    <div className="subservice-row">
                        <span className="service-title">Commission Type</span>
                        <span className="service-view"> {userData?.userDetail?.deposit.commissionType==='flat' ? 'Flat' : 'Percentage'} </span>
                    </div>
                </div>}
                {userData?.userDetail?.role==='affiliate' && <div className="col-md-6">
                    <div className="subservice-row">
                        <span className="service-title">Commission Rate</span>
                        <span className="service-view"> {userData?.userDetail?.deposit.commissionType==='flat' ? formatCurrency(userData.userDetail?.deposit.commissionRate, 'INR'):userData?.userDetail?.deposit.commissionRate +'%'} </span>
                    </div>
                </div>}
                {userData?.userDetail?.role==='affiliate' && <div className="col-md-6">
                    <div className="subservice-row">
                        <span className="service-title">Commission Applied On</span>
                        <span className="service-view"> {userData?.userDetail?.deposit.appliedTo==='first' ? "First Deposit Only":"All Deposits"} </span>
                    </div>
                </div>} */}
                <div className="col-md-6">
                    <div className="subservice-row">
                        <span className="service-title">Email Verified:</span>
                        <span className="service-view"> {userData?.userDetail?.isVerifyEmailOtp ? 'Yes' : 'No'} </span>
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="subservice-row">
                        <span className="service-title">Bet Allowed:</span>
                        <span className="service-view"> {userData?.userDetail?.isBettingAllowed ? 'Yes' : 'No'} </span>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="subservice-row">
                        <span className="service-title">Withdrawal Allowed:</span>
                        <span className="service-view"> {userData?.userDetail?.isWithdrawalAllowed ? 'Yes' : 'No'} </span>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="subservice-row">
                        <span className="service-title">Login Allowed:</span>
                        <span className="service-view"> {userData?.userDetail?.isLoginAllowed ? 'Yes' : 'No'} </span>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="subservice-row">
                        <span className="service-title">Phone Verified:</span>
                        <span className="service-view"> {userData?.userDetail?.phoneVerified ? 'Yes' : 'No'} </span>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="subservice-row">
                        <span className="service-title">Registered Date:</span>
                        <span className="service-view"> {formatDateTime(userData?.userDetail?.createdAt)} </span>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="subservice-row">
                        <span className="service-title">Registered Location:</span>
                        <span className="service-view">
                            {userData?.userDetail?.resgisterCity && userData?.userDetail?.resgisterState && userData?.userDetail?.resgisterCountry
                                ? `${userData.userDetail.resgisterCity}, ${userData.userDetail.resgisterState} (${userData.userDetail.resgisterCountry})`
                                : "N/A"}
                        </span>

                        {/* <span className="service-view"> {userData?.userDetail?.resgisterCity || "N/A"}, {userData?.userDetail?.resgisterState || ''} {userData?.userDetail?.resgisterCountry ? `( ${userData?.userDetail?.resgisterCountry} )` : ''} </span> */}
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="subservice-row">
                        <span className="service-title">User ID:</span>
                        <span className="service-view"> {userData?.userDetail?.id} </span>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="subservice-row">
                        <span className="service-title">Registered IP:</span>
                        <span className="service-view"> {userData?.userDetail?.resgisterIP || 'N/A'} </span>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="subservice-row">
                        <span className="service-title">Registered Device Fingerprint:</span>
                        <span className="service-view"> {userData?.userDetail?.fingerprint || 'N/A'} </span>
                    </div>
                </div>
                {userData?.userDetail?.role === 'affiliate' && (
                    <div className="col-md-12">

                        <div className="my-3">
                            <h5 className="text-primary">
                                {"Commission On Registration"}
                            </h5>

                            <div  className="row">
                                <div className="col-md-6">
                                    <div className="subservice-row">
                                        <span className="service-title">Commission Type</span>
                                        <span className="service-view">
                                            Flat
                                        </span>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="subservice-row">
                                        <span className="service-title">Commission Amount</span>
                                        <span className="service-view">

                                            {formatCurrency(userData?.userDetail?.registration?.commissionRate, 'INR')
                                            }
                                        </span>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                )}
                {userData?.userDetail?.role === 'affiliate' && (
                    <div className="col-md-12">
                        {['first', 'all'].map((appliedTo) => {
                            const deposits = userData?.userDetail?.deposit?.filter(d => d.appliedTo === appliedTo);
                            if (!deposits.length) return null;

                            return (
                                <div key={appliedTo} className="my-3">
                                    <h5 className="text-primary">
                                        {appliedTo === 'first' ? "First Deposit Only" : "All Deposits"}
                                    </h5>
                                    {deposits.map((deposit, index) => (
                                        <div key={index} className="row">
                                            <div className="col-md-6">
                                                <div className="subservice-row">
                                                    <span className="service-title">Commission Type</span>
                                                    <span className="service-view">
                                                        {deposit.commissionType === 'flat' ? 'Flat' : 'Percentage'}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="subservice-row">
                                                    <span className="service-title">Commission Rate</span>
                                                    <span className="service-view">
                                                        {deposit.commissionType === 'flat'
                                                            ? formatCurrency(deposit.commissionRate, 'INR')
                                                            : `${deposit.commissionRate}%`}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            );
                        })}
                    </div>
                )}

                <div className="col-md-6">
                    <div className="subservice-row">
                        <span className="service-title">Referred By:</span>
                        <span className="service-view"> {userData?.userDetail?.affiliateId?.username || 'N/A'} </span>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="subservice-row">
                        <span className="service-title">Referred Users:</span>
                        <span className="service-view">
                            {userData?.totalReferrals ?? 0}
                        </span>
                    </div>
                </div>
            </div>
        </>
    );
};

export default userDetail;
